import { rejectedMeetingData } from '../../database/utils/templateMeetingData';
import { MeetingData } from '../../shared/types/types';

class MeetingsCacheClass {
  meetings: Map<string, MeetingData>;

  constructor() {
    this.meetings = new Map();
  }

  add = (eventId: string, meeting: MeetingData) => {
    if (eventId.length === 0) return;
    if (meeting.resolvedState !== 'resolved') return;
    this.meetings.set(eventId, meeting);
  }

  get = (eventId: string): MeetingData => this.meetings.get(eventId) ?? rejectedMeetingData

  has = (eventId: string): boolean => this.meetings.has(eventId)
}

export default MeetingsCacheClass;
