import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../shared/colours';
import BorderRadiusContainer from '../all-pages/BorderRadiusContainer';
import { HorizontalLine } from '../meeting/HorizontalLine';
import ScratchpadExplanation from './ScratchpadExplanation';
import ProseMirrorComponentV2 from '../../../src/shared/components/prosemirror/index';
import ScratchpadTitle from './ScratchpadTitle';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${COLORS.gray1};
  height: 100%;
  border-radius: 24px 24px 0 0;
`;

const TopInfoPadding = styled.div`
  padding: 20px 20px 16px 20px;
`;

interface Props {
  notePath: string,
}

const ScratchpadTemplate = ({ notePath }: Props) => {
  useEffect(() => {
    ConsoleImproved.log('notepath', notePath);
  }, [notePath]);

  return (
    <BorderRadiusContainer>
      <Container>
        <TopInfoPadding>
          <ScratchpadTitle title="Scratchpad" />
          <ScratchpadExplanation />
        </TopInfoPadding>
        <HorizontalLine />
        <ProseMirrorComponentV2
          path={notePath}
          meetingSection="scratchpad"
          displayNotes
        />
      </Container>
    </BorderRadiusContainer>
  );
};

export default ScratchpadTemplate;
