import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import stopScrollbarPropagation from '../../../utils/ui';
import { gray2, gray3 } from '../../colours';
import { header200, uiText } from '../../typography';

export const DescriptionInput = styled.textarea`
  background: ${gray2};
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 80px;
  margin: 8px 0 24px 0;
  border: 2px solid ${gray3};
  box-sizing: border-box;
  padding: 8px 8px 8px 8px;
  resize: none;
  ${uiText};

  ::placeholder{
    margin: 10px 0px 10px 8px;
    margin-left: 8px;
  }

  :focus{
    outline: none;
  }
`;

const TaskDescriptionSubHeader = styled.span`
  ${header200};
`;

interface Props {
  description: string,
  // eslint-disable-next-line no-unused-vars
  handleDescriptionChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
}

const TaskDescriptionComponent = ({ description, handleDescriptionChange }: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(document.createElement('textarea'));
  useEffect(() => {
    stopScrollbarPropagation(textAreaRef);
  }, []);

  return (
    <>
      <TaskDescriptionSubHeader>
        Description
      </TaskDescriptionSubHeader>
      <DescriptionInput
        ref={textAreaRef}
        className="descriptionTextArea"
        value={description}
        placeholder="Type task details here..."
        onChange={handleDescriptionChange}
      />
    </>
  );
};

export default TaskDescriptionComponent;
