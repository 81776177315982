/* eslint-disable import/prefer-default-export */

export const COLLECTIONS = {
  USERS: 'users',
  MEETINGS: 'meetings',
  TAGS: 'tags',
  TASKS: 'tasks',
};

export const MEETING_PATH = {
  data: {
    title: 'data.title',
    description: 'data.description',
    attendees: 'data.attendees',
  },
  date: {
    start: {
      date: 'date.start.date',
      timestamp: 'date.start.timestamp',
    },
    /** Not so nice hack to also be able to have `date.start` path */
    startOriginal: 'date.start',
    end: {
      date: 'date.end.date',
      timestamp: 'date.end.timestamp',
    },
    /** Not so nice hack to also be able to have `date.end` path */
    endOriginal: 'date.end',
  },
  googleData: {
    ids: {
      eventId: 'googleData.ids.eventId',
      recurringEventId: 'googleData.ids.recurringEventId',
      dataEventId: 'googleData.ids.dataEventId',
      calendarId: 'googleData.ids.calendarId',
      meetId: 'googleData.ids.meetId',
      htmlLink: 'googleData.ids.htmlLink',
    },
    attendees: 'googleData.attendees',
    content: {
      summary: 'googleData.content.summary',
      organizer: {
        /**
         * `calendarId` of the event.
         *
         * The creator can be different from the calendarId, if the creator selects
         * a different calendar than his primary calendar.
         */
        email: 'googleData.content.organizer.email',
      },
      creator: {
        email: 'googleData.content.creator.email',
      },
    },
    conferenceData: 'googleData.conferenceData',
  },
};

export const USER_PATH = {
  data: {
    photoUrl: 'data.photoUrl',
    firstName: 'data.firstName',
    lastName: 'data.lastName',
    email: 'data.email',
    name: 'data.name',
    receivedWelcomeEmail: 'data.receivedWelcomeEmail',
    newFeaturesViewed: 'data.newFeaturesViewed',
  },
  permissions: {
    google: {
      calendars: 'permissions.google.calendars',
    },
    tags: 'permissions.tags',
  },
  settings: {
    clockFormat: 'settings.clockFormat',
    disabledDomains: 'settings.disabledDomains',
  },
  billing: {
    stripeCustomerIdSet: 'billing.stripeCustomerIdSet',
    stripeCustomerId: 'billing.stripeCustomerId',
    status: 'billing.status',
    activePlans: 'billing.activePlans',
    created: 'billing.created',
    /** 'billing.trialStart' */
    trialStartPath: 'billing.trialStart',
    trialStart: {
      timestamp: 'billing.trialStart.timestamp',
      date: 'billing.trialStart.date',
    },
    /** 'billing.trialEnd' */
    trialEndPath: 'billing.trialEnd',
    trialEnd: {
      timestamp: 'billing.trialEnd.timestamp',
      date: 'billing.trialEnd.date',
    },
    meetingsUsed: 'billing.meetingsUsed',
    subscription_PATH: 'billing.subscription',
    isPaidTrialStarted: 'billing.isPaidTrialStarted',
    subscription: {
      status: 'billing.subscription.status',
    },
  },
  meta: {
    privateId: 'meta.privateId',
  },
  integrations: {
    slack: 'integrations.slack',
  },
};
