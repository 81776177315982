import React, { useContext, useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useIntercom } from 'react-use-intercom';
import { UserDataContext } from '../../App';
import UserAPI from '../../database/User/UserAPI';
import StripeAPI from '../../external/Stripe/StripeAPI';
import { StripeConstants } from '../../external/Stripe/StripeConstants';
import { StripeCheckoutSessionOrigin } from '../../external/Stripe/StripeTypes';
import StripeUtils from '../../external/Stripe/StripeUtils';
import ROUTES from '../../routes';
import JustHeaderContainer from '../../shared/atomic_design/templates/JustHeaderContainer';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import useGetQueryParam from '../../utils/hook/useGetParams';
import { toastDanger } from '../../utils/notifications';
import PaymentFailedModal from '../all-pages/Billing/TrialEndedModal/PaymentFailed/PaymentFailedModalOrg';
import Mixpanel from '../../utils/analytics/Mixpanel';
import StripeSubscriptionUtils from '../../external/Stripe/StripeSubscriptionUtils';
import SlackBoltAPI from '../../external/SlackBolt/SlackBoltAPI';

// TODO: Handle trial extended origin
const CancelPaymentPage = () => {
  const navigate = useNavigate();
  const userData = useContext(UserDataContext);
  const stripe = useStripe();
  const { trackEvent } = useIntercom();
  const [origin, setOrigin] = useState<StripeCheckoutSessionOrigin | null>(null);

  useEffect(() => {
    const newOrigin = useGetQueryParam(StripeConstants.ORIGIN_URL_PARAM_PATH);
    ConsoleImproved.log(StripeConstants.ORIGIN_URL_PARAM_PATH, newOrigin);
    if (!StripeUtils.isValidStripeCheckoutOrigin(newOrigin)) return;
    setOrigin(newOrigin);
  }, []);

  const onClickTryAgain = async () => {
    console.log('onClickTryAgain');

    const newOrigin = origin ?? StripeConstants.CHECKOUT_ORIGINS.DuringFreePlan;
    console.log('newOrigin', newOrigin);

    if ((newOrigin as string) === 'trialExtension') {
      console.log('handleClickExtendTrial in cancel page');
      const customerId = userData.billing.stripeCustomerId;
      const priceId = StripeSubscriptionUtils.getTrialExtensionPriceId(StripeConstants.isTest);

      const result = await StripeAPI.createCheckoutSessionForSingleCharge(customerId, priceId);
      await SlackBoltAPI.logClickedTrialExtension(userData, 'atEndedTrial', 'cancelPage');
      await stripe?.redirectToCheckout({ sessionId: result.id });
      return;
    }
    try {
      const checkoutSession = await StripeAPI.createSubscribeCheckoutSession(userData, newOrigin);
      if (!checkoutSession.id) {
        ConsoleImproved.log('CheckoutSession id is empty');
        return;
      }
      await Mixpanel.Billing.logClickedSubscribe(
        userData, trackEvent, 'cancel',
      );
      await stripe?.redirectToCheckout({ sessionId: checkoutSession.id });
    } catch (error: any) {
      ConsoleImproved.log('Error while', error);
      toastDanger('Error', error?.message ?? 'Something went wrong', 20000);
    }
  };

  const handleGoToAllNotes = () => {
    navigate(ROUTES.allNotes);
  };

  const handleGoBackToOnboarding = () => {
    // Since usually we start the trial during the onboarding
    UserAPI.Billing.startTrial(userData, 'onboarding', trackEvent);
    navigate(ROUTES.onboardingFirstMeetingPath);
  };

  const handleClickClose = async () => {
    Mixpanel.Billing.logClickedCloseInCancelPage(userData, trackEvent, origin);
    if (origin === StripeConstants.CHECKOUT_ORIGINS.DuringOnboarding) {
      handleGoBackToOnboarding();
      return;
    }
    handleGoToAllNotes();
  };

  return (
    <JustHeaderContainer showHeader={false}>
      <PaymentFailedModal
        isOpen
        onClickClose={handleClickClose}
        onClickTryAgain={onClickTryAgain}
      />
      {/* <Container>
        <Font font="header500">Payment cancelled!</Font>
        <br />
        <Font font="defaultText">Payment cancelled or failed, please try again</Font>
        <ButtonSmall text="Try Again to Subscribe" onClick={onClickTryAgain} />
        <br />
        {origin === StripeConstants.CHECKOUT_ORIGINS.ExistingUserBeforeTrial && (
          <>
            <br />
            <Font font="defaultText">
              If you have changed your mind, and want to try out
              Shepherd a bit more before making any decision, you can start our free trial.
            </Font>
            <ButtonSmall text="Go back to free trial" onClick={handleGoToAllNotes} />
          </>
        )}
        {origin === StripeConstants.CHECKOUT_ORIGINS.DuringTrial && (
          <>
            <br />
            <Font font="defaultText">
              If you have changed your mind, and want to try out
              Shepherd a bit more before making any decision, you can continue your free trial.
            </Font>
            <ButtonSmall text="Continue free trial" onClick={handleGoToAllNotes} />
          </>
        )}
        {origin === StripeConstants.CHECKOUT_ORIGINS.AtEndedTrial && (
          <>
            <br />
            <Font font="defaultText">
              If you have changed your mind, and rather want to use
              the limited free plan, you can go back to the free plan.
            </Font>
            <ButtonSmall text="Go back to state at ended Trial" onClick={handleGoToAllNotes} />
          </>
        )}
        {origin === StripeConstants.CHECKOUT_ORIGINS.DuringFreePlan && (
          <>
            <br />
            <Font font="defaultText">
              If you have changed your mind, and not anymore want to upgrade to premium,
              you can continue on the free plan.
            </Font>
            <ButtonSmall text="Continue free trial" onClick={handleGoToAllNotes} />
          </>
        )}
        {
          (origin === StripeConstants.CHECKOUT_ORIGINS.DuringFreePlanHitMeetingLimitation
            || origin === StripeConstants.CHECKOUT_ORIGINS.DuringFreePlanHitSearchLimitation) && (
            <>
              <br />
              <Font font="defaultText">
                If you changed your mind, you can continue on the free plan, but then
                you will still be limited by number of meetings or search.
              </Font>
              <ButtonSmall text="Continue free trial" onClick={handleGoToAllNotes} />
            </>
          )
        }
        {origin === StripeConstants.CHECKOUT_ORIGINS.DuringOnboarding && (
          <>
            <br />
            <Font font="defaultText">
              If you have changed your mind, and not anymore want to upgrade to premium,
              you can continue on the onboarding.
            </Font>
            <ButtonSmall text="Start free trial" onClick={handleGoBackToOnboarding} />
          </>
        )}
        {origin && (
          <>
            <br />
            <br />
            <Font font="header300">
              Origin:
              {' '}
              {origin}
            </Font>
          </>
        )}

      </Container> */}
    </JustHeaderContainer>
  );
};

export default CancelPaymentPage;
