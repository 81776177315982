import { dbUpdateMeetingAnalyticsData, dbUpdateUserWithUsedScratchpad } from '../../database/firebaseAnalyticsAPI';
import { stripWhitespaceAndHtmlTag } from '../../utils/strings';
import { NOTES_EVENT_TRIGGER_THRESHOLD } from '../../utils/constants';
import {
  logEventMeetingWithShepherdUsed,
  logEventMeetingHasAgenda,
  logEventMeetingHasPrivateNotes,
  logEventMeetingHasSharedNotes,
  logEvent,
} from '../../utils/analytics/eventLogger';
import {
  MeetingSection, MeetingAnalyticsData, TimestampLog, User,
} from '../../shared/types/types';
import { MEETING_SECTION } from '../../utils/enums';
import { NotesFor } from '../../utils/analytics/enums';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import UserAPI from '../../database/User/UserAPI';
import FirestoreMeetings from '../../database/Meetings/FirestoreMeetings';

export const hasEnoughTextToTriggerLog = (notes: string) => stripWhitespaceAndHtmlTag(notes).length
  > NOTES_EVENT_TRIGGER_THRESHOLD;

export const triggerLogUsedScratchpad = (userData: User, notes: string) => {
  if (userData.meta.analytics.hasUsedScratchpad) return;
  if (hasEnoughTextToTriggerLog(notes)) return;
  const onSuccess = () => {
    logEvent(userData.userId, 'used_scratchpad', {});
    ConsoleImproved.log('Logged used scratchpad');
  };
  dbUpdateUserWithUsedScratchpad(userData.userId, onSuccess);
};

export const ifOnFreePlanAddMeetingToMeetingsUsed = async (
  userData: User, meetingId: string, notes: string,
) => {
  if (userData.resolvedState !== 'resolved') return;
  if (!userData.billing.isOnFreePlan()) return;
  if (meetingId.length === 0) return;
  if (userData.billing.isOnPremiumPlan()) return;
  if (userData.billing.isMeetingInMeetingsUsed(meetingId)) return;
  if (userData.billing.hasUsed10OrMoreMeetingsLast30Days()) return;
  if (!hasEnoughTextToTriggerLog(notes)) return;

  const meetingData = await FirestoreMeetings.getMeetingByMeetingId(meetingId);
  UserAPI.Billing.addNewMeetingUsed(userData, meetingData);
};

export const triggerEventMeetingWithShepherdUsed = (
  analyticsData: MeetingAnalyticsData,
  notes: string, meetingId: string, userId: string,
  timestampObj: TimestampLog, notesFor: NotesFor, intercomTrack: any,
) => {
  if (hasEnoughTextToTriggerLog(notes)
    && (analyticsData?.hasUsedShepherd !== true
      || !userIdExistInUsersArray(analyticsData.users, userId))) {
    dbUpdateMeetingAnalyticsData(
      meetingId,
      () => {
        logEventMeetingWithShepherdUsed(meetingId, userId, timestampObj,
          notesFor, intercomTrack);
      },
      (doc) => doc.data().hasUsedShepherd !== true
        || !userIdExistInUsersArray(doc.data().users, userId),
      (doc) => ({
        hasUsedShepherd: true,
        users: doc.data().users ? [...(doc.data().users), { userId }] : [{ userId }],
      }),
    );
  }
};

export const triggerEventMeetingHasAgenda = (
  userId: string,
  analyticsData: MeetingAnalyticsData,
  notes: string, page: MeetingSection, meetingId: string,
  timestampObj: TimestampLog, notesFor: NotesFor, intercomTrack: any,
) => {
  if (page === MEETING_SECTION.AGENDA && hasEnoughTextToTriggerLog(notes)
    && !analyticsData?.hasAgenda) {
    dbUpdateMeetingAnalyticsData(
      meetingId,
      () => {
        logEventMeetingHasAgenda(userId, meetingId, timestampObj,
          notesFor, intercomTrack);
      },
      (doc) => doc.data().hasAgenda !== true,
      () => ({ hasAgenda: true }),
    );
  }
};

export const triggerEventMeetingHasSharedNotes = (
  userId: string,
  analyticsData: MeetingAnalyticsData,
  notes: string, page: MeetingSection,
  meetingId: string, timestampObj: TimestampLog, notesFor: NotesFor, intercomTrack: any,
) => {
  if (page === MEETING_SECTION.SHARED_NOTES
    && hasEnoughTextToTriggerLog(notes)
    && !analyticsData?.hasSharedNotes) {
    dbUpdateMeetingAnalyticsData(
      meetingId,
      () => {
        logEventMeetingHasSharedNotes(
          userId, meetingId, timestampObj,
          notesFor, intercomTrack,
        );
      },
      (doc) => doc.data().hasSharedNotes !== true,
      () => ({ hasSharedNotes: true }),
    );
  }
};

export const triggerEventMeetingHasPrivateNotes = (
  userId: string,
  analyticsData: MeetingAnalyticsData,
  notes: string, page: MeetingSection,
  meetingId: string, timestampObj: TimestampLog, notesFor: NotesFor, intercomTrack: any,
) => {
  if (page === MEETING_SECTION.PERSONAL_NOTES
    && hasEnoughTextToTriggerLog(notes)
    && !analyticsData?.hasPrivateNotes) {
    dbUpdateMeetingAnalyticsData(
      meetingId,
      () => {
        logEventMeetingHasPrivateNotes(
          userId, meetingId, timestampObj, notesFor, intercomTrack,
        );
      },
      (doc) => doc.get('hasPrivateNotes') !== true,
      () => ({ hasPrivateNotes: true }),
    );
  }
};

const userIdExistInUsersArray = (users: any, userId: string) => users?.some(
  (user: any) => user.userId === userId,
);
