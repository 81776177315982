import React, { useContext } from 'react';
import styled from 'styled-components';
import { useStripe } from '@stripe/react-stripe-js';
import { useIntercom } from 'react-use-intercom';
import { UserDataContext } from '../../App';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { COLORS } from '../../shared/colours';
import Font from '../../shared/typography/Font';
import { toastDanger, toastInfo } from '../../utils/notifications';
import StripeAPI from '../../external/Stripe/StripeAPI';
import { StripeConstants } from '../../external/Stripe/StripeConstants';
import Mixpanel from '../../utils/analytics/Mixpanel';

const Container = styled.div`
  padding: 24px 10px;
  border: 1px solid black;
  border-radius: 8px;
  background-color: ${COLORS.gray2};
  display: flex;

  width: 200px;

  flex-direction: column;
  align-items: center;
`;

const PlansContainer = styled.div`
  margin-top: 40px;
  padding: 24px 10px;
  border: 1px solid black;
  border-radius: 8px;
  background-color: ${COLORS.gray2};
  display: flex;

  width: 400px;

  flex-direction: column;
  align-items: center;
`;

const PlansInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HoverContainer = styled.div`
  background-color: ${COLORS.gray4};
  padding: 8px 16px;
  border-radius: 8px;
  margin-top: 5px;
  
  &:hover {
    cursor: pointer;
    background-color: ${COLORS.gray5};
  }
`;

const ManageSubscriptionContainer = styled.div`
  margin-top: 40px;
  padding: 24px 10px;
  border: 1px solid black;
  border-radius: 16px;
  background-color: ${COLORS.gray2};

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: ${COLORS.gray5};
  }
`;

/**
 * Currently only used in /manage billing page
 */
const SubscribeToPremiumMol = () => {
  const userData = useContext(UserDataContext);
  const { trackEvent } = useIntercom();
  const stripe = useStripe();

  const handleCreateNewCustomer = () => {
    ConsoleImproved.log('Clicked Subscribe');
    toastInfo('Clicked Subscribe', 'Yes you did');
    StripeAPI.createStriperCustomer(userData);
  };

  const handleSubscribeClick = async () => {
    ConsoleImproved.log('Clicked Subscribe', userData);

    try {
      // TODO: When we make proper page, use the proper Checkout Origin
      const checkoutSession = await StripeAPI.createSubscribeCheckoutSession(
        userData,
        StripeConstants.CHECKOUT_ORIGINS.DuringTrial,
      );
      if (!checkoutSession.id) {
        ConsoleImproved.log('Checkoutsession id is empty');

        return;
      }
      await Mixpanel.Billing.logClickedSubscribe(
        userData, trackEvent, StripeConstants.CHECKOUT_ORIGINS.DuringTrial,
      );
      await stripe?.redirectToCheckout({ sessionId: checkoutSession.id });
    } catch (error: any) {
      // Toast.dangerWithSentryAndConsoleLog
      // (error, 'Error while creating checkout session for subscribe')
      ConsoleImproved.log('Error while', error);
      toastDanger('Error', error?.message ?? 'Something went wrong', 20000);
    }
  };

  const handleManageSubsciptions = async () => {
    ConsoleImproved.log('Clicked handleManageSubscriptions');
    const session = await StripeAPI.createPortalSession(userData, StripeConstants.isTest);
    window.open(session.url, '_blank');
  };

  return (
    <>
      <Container>
        <Font font="defaultText">User</Font>
        <Font font="defaultText" isButton onClick={handleCreateNewCustomer}>Create New User</Font>
      </Container>
      {
        userData.billing.stripeCustomerId && (
          <Container>
            <Font font="defaultText">Premium</Font>
            <Font font="defaultText" isButton onClick={handleSubscribeClick}>Subscribe</Font>
          </Container>
        )
      }
      <PlansContainer>
        <Font font="header400">Plans</Font>
        <PlansInnerContainer>
          {userData.billing.activePlans.map((plan: string) => <PlanItem key={plan} plan={plan} />)}
        </PlansInnerContainer>
      </PlansContainer>
      <ManageSubscriptionContainer onClick={handleManageSubsciptions}>
        Manage
      </ManageSubscriptionContainer>
    </>
  );
};

export default SubscribeToPremiumMol;

const PlanItem = ({ plan }: { plan: string }) => {
  const handlePlanClick = (planInner: string) => {
    StripeAPI.getSubscription(planInner);
  };
  return (
    <HoverContainer onClick={() => handlePlanClick(plan)}>
      <Font font="defaultText">{plan}</Font>
    </HoverContainer>
  );
};
