/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import ReactJson from 'react-json-view';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../App';
import FirestoreMeetings from '../../database/Meetings/FirestoreMeetings';
import TagsAPI from '../../database/Tags/TagsAPI';
import UserAPI from '../../database/User/UserAPI';
import IntercomCF from '../../external/IntercomCF/IntercomCF';
import SlackBoltAPI from '../../external/SlackBolt/SlackBoltAPI';
import StripeAPI from '../../external/Stripe/StripeAPI';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import SDate from '../../shared/classes/SDate';
import TestClass from '../../shared/classes/TestClass';
import UserId from '../../shared/classes/UserID';
import { gray4 } from '../../shared/colours';
import ButtonSmall from '../../shared/components/button-small';
import TitleAtom from '../../shared/components/new-feaures-modal/TitleAtom';
import { MeetingsObject } from '../../shared/types/types';
import { RESOLVED } from '../../utils/enums';
import { gapiGetMeeting } from '../../utils/google/GoogleCalendarAPI';
import { toastInfo } from '../../utils/notifications';
// import Stopwatch from '../../utils/Stopwatch/Stopwatch';
import useCounterStore from '../../zustand/useCounterStore';
import { StripeConstants } from '../../external/Stripe/StripeConstants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
  background-color: ${gray4};
  height: 100vh;

  > * {
    margin-bottom: 20px;
  }
`;

const HaraldTestingPage = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const authData = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const [json, setJson] = useState({});
  const [meetings, setMeetings] = useState<MeetingsObject>({});
  const [domain, setDomain] = useState<string>('');

  const count = useCounterStore((state) => state.count);
  const incrementCount = useCounterStore((state) => state.increment);

  useEffect(() => {
    // if (userData.resolvedState !== RESOLVED) return;
    // FirestoreMeetings.listenToMeetingsWhereImInvitedTo(userData.userId,
    //   userData.data.email, setMeetings);
    // FirestoreMeetings.paginateMeetingsByCalendarId('harald@meetshepherd.com');
    // FirestoreMeetings.listenToMeetingsWhereImInvitedTo(
    //   userData.userId, userData.data.email, setMeetings,
    // );
    // FirestoreMeetings.listenToMyTag();
    // FirestoreUserAPI.updateUserWithHaraldTag(authData.userId, 'haraldTag');
    // TagsAPI.addTagToMeeting('0IaYjQpLo1TZokZPAXMD', 'Wufas2u8Olo1DRnGafjm', userData.userId);
    // TagsAPI.listenToMyTags(userData.userId);
    // TagsAPI.addNewTagToMeeting('Some random name',
    // '0IaYjQpLo1TZokZPAXMD', userData.userId, userData.data.email);
  }, [userData.resolvedState]);

  // useEffect(() => {
  //   if (meetings?.keys?.length === 0) return;
  //   console.log('Meetings');
  //   console.log(meetings);
  // }, [meetings]);

  // useEffect(() => {
  //   // const userId = new UserId('haraldlons@gmail.com');
  //   const userId = new UserId('');
  //   // userId.consoleLog();
  //   console.log(userId);
  //   console.log(`UserId: ${userId}`);
  //   if (userId) {
  //     console.log('true in userId');
  //   } else {
  //     console.log('false in userId');
  //   }
  // }, []);

  const handleClick1 = async () => {
    toastInfo('Clicked', 'Button 1 clicked');

    // const result = await UserAPI.updateStripeCustomerIdToSetTransactiontCore(userData);
    // const result2 = await UserAPI.updateStripeCustomerIdToSetTransactiontCore(userData);
    // console.log('Result', { result, result2, result2IsRejected: result2.isRejected });

    // const emails : string[] = ['harald@meetshepherd.com', 'haraldlons@gmail.com'];
    // const subject = 'Halla på deg';
    // const body = 'Hei, har du noe å gjøre?';
    // EmailAPI.sendBasicEmail(emails, subject, body);
    // SlackAPI.sendNotification('Some title', 'This is the message', userData.publicUserData);

    // const blacklistedDomains = ['gmail.com', 'googlemail.com', 'localhost:3000'];
    // CEComs.removeBlacklistedDomains(blacklistedDomains);
    // TestClass.testMethod();

    // const dataEventIds = [
    //   'MWVlNTVuZjl0djJibTdkN2RobGY5M3NzODggaGFyYWxkQG1lZXRz',
    //   'NDZnbXVkM3Nwc3F1bmg2NDJyYnBwYTZuaTIgaGFyYWxkQG1lZXRz',
    //   'cnUzMjM1OGtwM3ZicmtoM2Jmdjlqcm5kMTQgaGFyYWxkQG1lZXRz',
    //   'Z2VoMjZ0YXA4NXZtdG05bXNiZGtrMTVwZ3ZfMjAyMzAzMTNUMDgw',
    //   'anYwczF0ZWVxcXYzbzNnNnFybG5xNDdnaGMgaGFyYWxkQG1lZXRz',
    //   'MWxoNWwzaWpxMzA4NGtoNXZzOGIwOWoxNXEgaGFyYWxkQG1lZXRz',
    //   'MmdnaWM2dGdicmtqaW45bmlmMmM0MzM2dTIgaGFyYWxkQG1lZXRz',
    //   'MXZuaGJzY2syYWVvZ2Q3Z2Z2dTlqcGpxbmwgaGFyYWxkQG1lZXRz',
    //   'MGUyOWMwajI5Zmk2YmRrcThzZDlzMDlndTAgaGFyYWxkQG1lZXRz',
    //   'YnV0MjJhMmJrb2oybWEzYnJpZ3JlMTE4YTAgaGFyYWxkQG1lZXRz',
    //   'a3BvZjZnZTBuZm9zM3JvZGV0aTQzZ2FjNGsgaGFyYWxkQG1lZXRz',
    //   'NzBvbWNjajQ2MG82NmJiMjY0czM0YjlrNzRzajZiYjI2b3FtY2I5',
    //   'bWRxaTlpams2djJmaWEzaGQ4YW84a2hjYjggaGFyYWxkQG1lZXRz',
    //   'M3BhMjVqZmJqaHBybmMxMjR2Zmhhcm1vdnIgaGFyYWxkQG1lZXRz',
    //   'Njk5NHRyYTU5MnY1N2dybmdtYXVuN3JvNnEgaGFyYWxkQG1lZXRz',
    //   'NHNqaWF1cG91ZzcycG9sdXJoOHRsOHU0dDMgaGFyYWxkQG1lZXRz',
    //   'M2RtYjg5cjZyazZoZGF0ZDE4NWlqZmw3ZWEgaGFyYWxkQG1lZXRz',
    //   'MzVuNDQ5MW9ydWJycjQ4bDFpaXU1OHFpZTYgaGFyYWxkQG1lZXRz',
    //   'OXZuY2JzN2RybjA3bnBkMDc5aHVucXRhN2pfMjAyMzAzMTVUMDgwMDA
    // wWiBoYXJhbGRAbWVldHNoZXBoZXJkLmNvbQ',
    // ];

    // const stopwatch = new Stopwatch();
    // const result = await FirestoreMeetings.getMeetingsByDataEventIds(dataEventIds);
    // stopwatch.stop();
    // stopwatch.printDiff();

    // console.log('Result: ');
    // console.log(result);

    const result = await SlackBoltAPI.listConversations();
    console.log(result);
    // SlackBoltAPI.logMessage('test ');
  };

  const handleClick2 = async () => {
    toastInfo('Clicked', 'Button 2 clicked');

    // const customerId = 'cus_NpPsc8TJW3AQJI';
    const customerId = StripeConstants.TEST.HARALD_CUSTOMER_ID;
    // const { userId } = userData;
    const result = await StripeAPI.createCheckoutSessionForAddingPaymentMethod(customerId);
    // const result = await StripeAPI.createTestSubscriptionWithPaidTrial(userId, customerId, true);
    console.log('Result', result);

    // const subscriptionId = 'sub_1Mr0fmAwnRUlcRSmfVy8ZgmA';
    // const quantity = 5;
    // const subscriptionItemId = 'si_NcFAkthuBeIYfi';
    // const result = await StripeAPI.updateSubscriptionQuantity(
    //   subscriptionId, subscriptionItemId, quantity,
    // );

    // Call Intercom CF
    // IntercomCF.updateSubscriptionData(userData.userId, 'notSubscribed');
    // SlackBoltAPI.listConversations();

    // CEComs.sendBlacklistedDomains([domain]);
    // setDomain('');
    // TagsAPI.createNewTag(domain, userData.userId, userData.data.email);
    // GAPI.getMyCalendars();
  };
  const handleClick3 = async () => {
    toastInfo('Clicked', 'Button 3 clicked');

    const customerId = StripeConstants.TEST.HARALD_CUSTOMER_ID;
    const priceId = StripeConstants.TEST.PRICES.ONE_DOLLAR_FOR_TRIAL_EXTENSION;
    const result = await StripeAPI.createCheckoutSessionForSingleCharge(customerId, priceId);
    console.log(result?.url);

    // const data = await FirestoreMeetings
    // .getMeetingByDataEventId('NDZnbXVkM3Nwc3F1bmg2NDJyYnBwYTZuaTIgaGFyYWxkQG1lZXRz');
    // const customerId = 'cus_Mvypu11D06wAc6';
    // const customerId = 'cus_NpPsc8TJW3AQJI'; // Harald test customer with test clock
    // const checkoutSession = await StripeAPI.createTrialExtensionCheckoutSession(customerId);
    // if (!checkoutSession.id) {
    //   console.log('Error creating checkout session');
    //   return;
    // }
    // await stripe?.redirectToCheckout({ sessionId: checkoutSession.id });
    // Redirect to url

    // console.log(checkoutSession);
  };
  const handleClick4 = () => {
    toastInfo('Clicked', 'Button 4 clicked');
  };

  const handleClick5 = async () => {
    toastInfo('Clicked', 'Button 5 clicked');
    const eventId = '74fmcaponf9vtg8pa9ivr3md0n_20220207T130000Z';

    const time1 = new SDate();
    const allCalendards = await gapiGetMeeting(eventId, 'haraldlons@gmail.com', 'haraldlons@gmail.com');
    ConsoleImproved.log(allCalendards);
    SDate.printPassedSinceSDate(time1);
  };

  const handleClick6 = async () => {
    toastInfo('Clicked', 'Button 6 clicked');
    navigate('/');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value);
  };

  return (
    <Container>
      <TitleAtom>Harald Testing Page</TitleAtom>
      <TitleAtom>{count}</TitleAtom>
      <ButtonSmall text="Click 1" onClick={handleClick1} />
      <input onChange={handleInputChange} placeholder="Tag name" value={domain} />
      <ButtonSmall text="Click 2" onClick={handleClick2} />
      <ButtonSmall text="Click 3" onClick={handleClick3} />
      {/* <ButtonSmall text="Click 4" onClick={handleClick4} />
      <ButtonSmall text="Click 5" onClick={handleClick5} />
      <ButtonSmall text="Click 6" onClick={handleClick6} /> */}
      <ReactJson src={json} />
    </Container>
  );
};

export default HaraldTestingPage;
