import {
  ResolvedStateItem, ResolvedState, TaskItem, TrelloData,
} from '../../shared/types/types';
import {
  defaultTrelloData,
  getTrelloBoardsAPIResponse,
  TrelloAPIBoard,
  isTrelloBoardsEnabled, mapTasksToTrelloTask, TrelloBoardMember, TrelloTaskCreate, TrelloUpdateObj,
} from './trelloUtils';
import { functions } from '../firebase';
import { dbTaskUpdateTrelloData } from '../../database/firebaseTasksAPI';
import { toastInfo } from '../notifications';
import { TabDropdownItem } from '../../shared/components/simple-dropdown';

export const getUsersTrelloSetting = (
  emailId: string,
): Promise<ResolvedStateItem<TrelloData>> => functions()
  .httpsCallable('searchUserDataByEmail')({ email: emailId })
  .then((response) => {
    const result: ResolvedStateItem<TrelloData> = {
      item: response.data.integrations.trello,
      resolvedState: 'resolved',
    };
    return result;
  }).catch(() => {
    const result: ResolvedStateItem<TrelloData> = {
      item: defaultTrelloData,
      resolvedState: 'rejected',
    };
    return result;
  });

export const getTrelloWorkspace = (
  accessToken: string,
): Promise<ResolvedStateItem<TabDropdownItem[]>> => functions()
  .httpsCallable('getTrelloWorkspaces')({ accessToken })
  .then((data) => {
    const result: ResolvedStateItem<TabDropdownItem[]> = {
      item: data.data,
      resolvedState: 'resolved',
    };
    return result;
  }).catch(() => {
    const result: ResolvedStateItem<TabDropdownItem[]> = {
      item: [],
      resolvedState: 'rejected',
    };
    return result;
  });

export const getTrelloBoardMembers = (
  accessToken: string, boardId: string,
): Promise<ResolvedStateItem<TrelloBoardMember[]>> => functions()
  .httpsCallable('getTrelloBoardMembers')({ accessToken, boardId }).then((data) => {
    const result: ResolvedStateItem<TrelloBoardMember[]> = {
      item: data.data,
      resolvedState: 'resolved',
    };
    return result;
  }).catch(() => {
    const result: ResolvedStateItem<TrelloBoardMember[]> = {
      item: [],
      resolvedState: 'rejected',
    };
    return result;
  });

export const getTrelloBoards = (
  accessToken: string, workspaceId: string,
): Promise<ResolvedStateItem<getTrelloBoardsAPIResponse[]>> => functions()
  .httpsCallable('getTrelloBoards')({ accessToken, workspaceId }).then((data) => {
    const result: ResolvedStateItem<getTrelloBoardsAPIResponse[]> = {
      item: data.data,
      resolvedState: 'resolved',
    };
    return result;
  }).catch(() => {
    const result: ResolvedStateItem<getTrelloBoardsAPIResponse[]> = {
      item: [],
      resolvedState: 'rejected',
    };
    return result;
  });

export const getTrelloBoardsList = (
  accessToken: string, boardId: string,
): Promise<ResolvedStateItem<TrelloAPIBoard[]>> => functions()
  .httpsCallable('getTrelloBoardList')({ accessToken, boardId }).then((data) => {
    const result: ResolvedStateItem<TrelloAPIBoard[]> = {
      item: data.data,
      resolvedState: 'resolved',
    };
    return result;
  }).catch(() => {
    const result: ResolvedStateItem<TrelloAPIBoard[]> = {
      item: [],
      resolvedState: 'rejected',
    };
    return result;
  });

export const sendTaskToTrelloBoard = async (
  trelloChecked: boolean,
  selectedTasks: TaskItem[],
  trelloUserData: TrelloData,
): Promise<ResolvedState> => {
  if (isTrelloBoardsEnabled(trelloUserData)) {
    const tasks: TrelloTaskCreate[] = await mapTasksToTrelloTask(selectedTasks, trelloUserData);
    return functions().httpsCallable('sendTaskToTrelloBoard')({ accessToken: trelloUserData.accessToken, tasks }).then((data) => {
      const promiseArr = data.data.map(
        (idObj: {
          taskId: string, trelloId: string
        }) => dbTaskUpdateTrelloData(idObj.taskId, idObj.trelloId, trelloChecked),
      );
      return Promise.all(promiseArr).then(() => 'resolved' as ResolvedState).catch((error) => {
        console.log(error);
        return 'rejected' as ResolvedState;
      });
    });
  }
  toastInfo('Trello Integration Error', 'Trello board have not been setup');
  return 'rejected' as ResolvedState;
};

export const updateTrelloTask = (
  updateObj: TrelloUpdateObj,
): Promise<ResolvedState> => functions().httpsCallable('updateTaskInTrelloBoard')({ ...updateObj })
  .then(() => 'resolved' as ResolvedState)
  .catch(() => 'rejected' as ResolvedState);

export const deleteTrelloTask = (
  taskId: string, emailId: string,
): Promise<ResolvedState> => getUsersTrelloSetting(emailId)
  .then((response) => functions().httpsCallable('deleteTrelloCard')(
    { accessToken: response.item.accessToken, taskId },
  )
    .then(() => {
      console.log('taskDeleted in trello', new Date());
      return 'resolved' as ResolvedState;
    })
    .catch((error: any) => {
      console.log(JSON.stringify(error));
      return 'rejected' as ResolvedState;
    }));
