import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../../App';
import Dropdown from '../../../../../shared/components/dropdown';
import { DropdownItem } from '../../../../../shared/components/dropdown/utils';
import { ResolvedState } from '../../../../../shared/types/types';
import { header200 } from '../../../../../shared/typography';
import { PENDING, REJECTED, SLACK } from '../../../../../utils/enums';
import { slackCoreGetChannelNames, slackCoreGetDirectMessages } from '../../../../../utils/slack/SlackCoreAPI';
import { emptySlackData } from '../../../../../utils/slack/slackUtils';
import { ModalLineLocal } from '../utils/commonStyles';
import {
  APPS_VIEW, NotesSwitchData, ShareModalMode, SLACK_SHARE_VIEW,
} from '../utils/types';
import CustomMessage from './CustomMessage';
import NotesSwitch from './NotesSwitch';
import Platform from './Platform';

const SectionText = styled.h2`
  ${header200};
  margin-bottom: 16px;
`;

interface Props {
  channelsToReceive: DropdownItem[],
  setChannelsToReceive: Dispatch<SetStateAction<DropdownItem[]>>,
  notesSwitchData: NotesSwitchData,
  setNotesSwitchData: React.Dispatch<React.SetStateAction<NotesSwitchData>>,
  setMode: React.Dispatch<React.SetStateAction<ShareModalMode>>,
  emailHeadTemplate: string,
  // eslint-disable-next-line no-unused-vars
  setCustomMessage: (message: string) => void,
}

export default function SlackView({
  channelsToReceive, setChannelsToReceive, notesSwitchData, setNotesSwitchData, setMode,
  emailHeadTemplate, setCustomMessage,
}: Props) {
  const userData = useContext(UserDataContext);
  const authState = useContext(AuthContext);

  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);
  const [slackChannels, setSlackChannels] = useState<DropdownItem[]>([]);
  const [slackDms, setSlackDms] = useState<DropdownItem[]>([]);
  const [isResolved, setIsResolved] = useState<ResolvedState>(PENDING);
  const slackUserData = userData?.integrations?.slack[0] ?? emptySlackData()[0];
  const slackUserAccessToken = slackUserData.userAccessToken ?? '';
  const isSlackEnabled = slackUserAccessToken.length > 10;

  useEffect(() => {
    if (!isSlackEnabled) return;
    slackCoreGetChannelNames(authState, slackUserAccessToken, setSlackChannels, setIsResolved);
    slackCoreGetDirectMessages(authState, slackUserAccessToken, setSlackDms, setIsResolved);
  }, [slackUserAccessToken]);

  useEffect(() => {
    setDropdownItems([...slackChannels, ...slackDms]);
  }, [slackChannels, slackDms]);

  const onItemClick = (item: DropdownItem) => {
    setChannelsToReceive([...channelsToReceive, item]);
  };

  return (
    <div>
      <SectionText>Your notes will be sent to</SectionText>
      <Platform platform={SLACK} mode={SLACK_SHARE_VIEW} iconSize="26" onClick={() => { setMode(APPS_VIEW); }} />
      <Dropdown
        isLoading={isResolved === PENDING}
        isError={isResolved === REJECTED}
        dropdownItems={dropdownItems}
        selectedItems={channelsToReceive}
        setSelectedItems={setChannelsToReceive}
        isMultiSelect
        placeholderText="Search Channels or DMs"
        onOptionClick={onItemClick}
      />
      <ModalLineLocal bottomMargin />
      <SectionText>Select to share</SectionText>
      <NotesSwitch notesSwitchData={notesSwitchData} setNotesSwitchData={setNotesSwitchData} />
      <ModalLineLocal />
      <SectionText>Add custom message</SectionText>
      <CustomMessage
        setCustomMessage={setCustomMessage}
        customMessage={emailHeadTemplate}
      />
    </div>
  );
}
