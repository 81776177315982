import algoliasearch from 'algoliasearch/lite';
import moment from 'moment';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { MeetingData, RelevantMeetingsData, User } from '../../shared/types/types';
import { RESOLVED } from '../../utils/enums';
import { MeetingUtils } from '../../utils/meetings/meetingsUtils';
import mapDatabaseMeetingDataToMeetingData from '../utils/mapMeetingData';
import AlgoliaAPICore from './AlgoliaAPICore';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID ?? '', process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY ?? '',
);
const searchIndex = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_MEETING_INDEX ?? '');

/**
 * API to interact with the search functionality by Algolia.
 */
class AlgoliaAPI extends AlgoliaAPICore {
  /**
   * Gets Gapi data for a specific meeting using the meeting eventId and calendarId
   * and attendee information
   */
  static async searchMeetings(
    queryString: string, userEmail: string, userId: string, calendarIds: string[],
  ): Promise<MeetingData[]> {
    if (calendarIds.length === 0) return [];
    if (queryString.length === 0) return [];
    const filter = `data.attendees.email:${userEmail} OR ${this.generateSearchFilter(calendarIds)} OR ${this.generateSearchFilterForContentIdsCalendarId(calendarIds)}`;
    // ConsoleImproved.log('Filter', filter);

    const result = await searchIndex.search(queryString, {
      // filters: `data.attendees.email:${userEmail} OR
      // ${this.generateSearchFilter(calendarIds)} OR
      // ${this.generateSearchFilterForContentIdsCalendarId(calendarIds)}`,
      filters: filter,
    });
    // ConsoleImproved.log('Result from algolia search', result.hits);

    const meetings: MeetingData[] = result.hits.map(
      (item: any) => mapDatabaseMeetingDataToMeetingData(item.objectID, item, userId),
    );
    const sortedMeetings = meetings.sort(MeetingUtils.sortMeetingsByDate);
    const uniqueMeetings = sortedMeetings.filter(MeetingUtils.filterOnlyUniqueMeetingsByEventId);
    ConsoleImproved.log(`Number of results for search query: '${queryString}' - `, uniqueMeetings.length);

    return uniqueMeetings;
  }

  /**
   * Finds relevant meetings with the same title,
   * or the first external attendee domain
   */
  static async findRelevantMeetings(
    currentMeeting: MeetingData,
    user: User,
    // eslint-disable-next-line no-unused-vars
    setRelevantMeetings: (relevantMeetings: RelevantMeetingsData) => void,
  ) {
    // Find first domain that is not the same as users domain
    const domains = currentMeeting.googleData.attendees.map((attendee) => attendee.split('@')[1]);
    const userDomain = user.data.email.split('@')[1];

    const externalRelevantMeetings: MeetingData[] = [];

    const relevantDomain = domains.find((domain) => domain !== userDomain);
    if (relevantDomain && relevantDomain !== 'gmail.com') {
      const newMeetings = await this
        .searchMeetings(
          relevantDomain, user.data.email, user.userId, user.permissions.google.calendars,
        );
      externalRelevantMeetings.push(...newMeetings);
    }

    const searchQuery = currentMeeting.data.title;
    const relevantMeetings = await this.searchMeetings(searchQuery, user.data.email, user.userId,
      user.permissions.google.calendars);

    const allRelevantMeetings = [...relevantMeetings, ...externalRelevantMeetings]
      .filter(MeetingUtils.filterOnlyUniqueMeetingsByEventId)
      .filter((meeting) => meeting.meetingId !== currentMeeting.meetingId)
      .sort(MeetingUtils.sortMeetingsByDate);

    const previousMeetings = allRelevantMeetings
      .filter((meeting) => moment(meeting.date.start.date).isBefore(currentMeeting.date.start.date))
      .slice(0, 6);

    const futureMeetings = allRelevantMeetings
      .filter((meeting) => moment(meeting.date.start.date).isAfter(currentMeeting.date.start.date))
      .reverse()
      .slice(0, 3)
      .reverse();

    setRelevantMeetings({
      previousMeetings,
      futureMeetings,
      resolveState: RESOLVED,
    });
  }
}

export default AlgoliaAPI;
