/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { Navigate } from 'react-router-dom-v5-compat';
import LogoText from '../../shared/icons/logo';
import OnboardingSubmitButton from '../onboarding/personal/components/onboarding-submit-button';
import RetryAuthModal from './RetryAuthModal';
import ROUTES from '../../routes';
import { signInToGoogleAndFirebase } from '../../utils/google/GoogleCalendarSetup';
import {
  Container, ContentWrapper, HeaderText, Subtitle, LogInWithGoogleButtonContainer,
} from './utils/styles';
import { surface } from '../../shared/colours';
import { HEADER_TEXT, SUBHEADER_TEXT } from './utils/constants';
import CalendarPermissions from './CalendarPermissions';
import { AuthContext, UserDataContext } from '../../App';
import { LOGGED_IN_STATE } from '../../utils/enums';
// import { UNRESOLVED_STATE } from '../../utils/enums';

// interface PageProps {
//   authState: AuthState,
//   isAuthenticated: boolean,
//   isOnboarded: boolean
//   isLoading: boolean,
// }

const Signin = () => {
  const userData = useContext(UserDataContext);
  const authData = useContext(AuthContext);

  const isAuthenticated = authData.userState === LOGGED_IN_STATE;
  const isOnboarded = userData.data.hasOnboarded;
  const isLoading = authData.userState === 'unresolved';

  const { trackEvent } = useIntercom();

  const [isRetryAuthModalOpen, setRetryAuthModalOpen] = useState<boolean>(false);
  const [isGettingStarted, setIsGettingStarted] = useState<boolean>(false);

  const closeRetryModal = () => {
    setRetryAuthModalOpen(false);
  };

  const onRetryClick = () => {
    closeRetryModal();
    handleSigninWithGoogleClick();
  };

  const handleSigninWithGoogleClick = () => {
    signInToGoogleAndFirebase(() => { setRetryAuthModalOpen(true); }, trackEvent);
  };

  const handleGetStartedClick = () => {
    setIsGettingStarted(true);
  };

  const handleBackClick = () => {
    setIsGettingStarted(false);
  };

  if (isAuthenticated && isOnboarded) {
    return <Navigate to={ROUTES.dashboardPath} />;
  }

  if (isAuthenticated && !isOnboarded) {
    return <Navigate to={ROUTES.onboardingPersonalPath} />;
  }

  if (isGettingStarted) {
    return (
      <CalendarPermissions onClick={handleSigninWithGoogleClick} onBackClick={handleBackClick} />
    );
  }

  return (
    <Container>
      <LogoText textColour={surface} width="267" />
      <ContentWrapper>
        <HeaderText>{HEADER_TEXT}</HeaderText>
        <Subtitle>{SUBHEADER_TEXT}</Subtitle>
        <LogInWithGoogleButtonContainer>
          <OnboardingSubmitButton
            text="Get Started!"
            loading={isLoading}
            onClick={handleGetStartedClick}
          />
        </LogInWithGoogleButtonContainer>
      </ContentWrapper>
      <RetryAuthModal
        isModalOpen={isRetryAuthModalOpen}
        onCancelClick={closeRetryModal}
        onRetryClick={onRetryClick}
      />
    </Container>
  );
};

export default Signin;
