import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ConsoleImproved from '../../../../../shared/classes/ConsoleImproved';
import Switch from '../../../../../shared/components/switch';
import { NotesSwitchData } from '../utils/types';
import MyNotesSharePromptModal from './MyNotesSharePromptModal';

const SwitchGroup = styled.div`
  & > div:not(:last-of-type) {
    margin-bottom: 16px;
  }
  margin-bottom: 0px;
`;
interface Props {
  notesSwitchData: NotesSwitchData,
  setNotesSwitchData: React.Dispatch<React.SetStateAction<NotesSwitchData>>
  showSharedNotesToggle?: boolean
  showTaskToggle?: boolean
  showMyNotesToggle?: boolean
}

export default function NotesSwitch({
  notesSwitchData, setNotesSwitchData,
  showSharedNotesToggle,
  showMyNotesToggle,
  showTaskToggle,
}: Props) {
  const {
    isSharedNotesChecked, isSharedNotesDisabled, isPersonalNotesChecked,
    isPersonalNotesDisabled, isTasksChecked, isTasksDisabled,
  } = notesSwitchData;

  const [isMyNotesSharePromptOpen, setIsMyNotesSharePromptOpen] = useState(false);

  const setSharedNotesChecked = (value: boolean) => {
    const notesData: NotesSwitchData = {
      ...notesSwitchData,
      isSharedNotesChecked: value,
    };
    ConsoleImproved.log('Clicked setSharedNotesChecked. Value after change: ', notesData);
    setNotesSwitchData(notesData);
  };

  const setPersonalNotesChecked = (value: boolean) => {
    setIsMyNotesSharePromptOpen(value);
    const notesData: NotesSwitchData = {
      ...notesSwitchData,
      isPersonalNotesChecked: value,
    };
    ConsoleImproved.log('Clicked setPersonalNotesChecked. Value after change: ', notesData);
    setNotesSwitchData(notesData);
  };

  const setTasksChecked = (value: boolean) => {
    const notesData: NotesSwitchData = {
      ...notesSwitchData,
      isTasksChecked: value,
    };
    ConsoleImproved.log('Clicked setTasksChecked. Value after change: ', notesData);
    setNotesSwitchData(notesData);
  };

  useEffect(() => {
    console.log('isSwitch', isSharedNotesDisabled);
  }, [isSharedNotesDisabled]);

  return (
    <>
      <SwitchGroup>
        {showSharedNotesToggle && (
          <Switch
            label="Shared notes"
            checked={isSharedNotesChecked}
            setChecked={setSharedNotesChecked as React.Dispatch<React.SetStateAction<boolean>>}
            disabled={isSharedNotesDisabled}
            disabledTooltipText="No shared notes"
          />
        )}
        {showMyNotesToggle && (
          <Switch
            label="My notes"
            checked={isPersonalNotesChecked}
            setChecked={setPersonalNotesChecked as React.Dispatch<React.SetStateAction<boolean>>}
            disabled={isPersonalNotesDisabled}
            disabledTooltipText="No personal notes"
          />
        )}
        {showTaskToggle && (
          <Switch
            label="Tasks"
            checked={isTasksChecked}
            setChecked={setTasksChecked as React.Dispatch<React.SetStateAction<boolean>>}
            disabled={isTasksDisabled}
            disabledTooltipText="No tasks"
          />
        )}
      </SwitchGroup>
      <MyNotesSharePromptModal
        isOpen={isMyNotesSharePromptOpen}
        handleCloseClick={() => { setIsMyNotesSharePromptOpen(false); }}
        notesSwitchData={notesSwitchData}
        setNotesSwitchData={setNotesSwitchData}
      />
    </>
  );
}

NotesSwitch.defaultProps = {
  showSharedNotesToggle: true,
  showMyNotesToggle: true,
  showTaskToggle: true,
};
