import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import styled, { css } from 'styled-components';

import { useIntercom } from 'react-use-intercom';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom-v5-compat';
import { captureMessage } from '@sentry/react';
import { useStripe } from '@stripe/react-stripe-js';
import {
  gray1, gray4, darkBlue1, red6, COLORS,
} from '../../colours';
import {
  messageText, uiTextMedium,
} from '../../typography';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import { signOutOfGoogleAndFirebase } from '../../../utils/google/GoogleCalendarSetup';
import Settings from './settings/Settings';
import {
  logEventButtonClickOpenIntercom,
  logEventButtonClickQuickSettings,
  logIntegrationsEvent,
  logQuickSettingsClick,
} from '../../../utils/analytics/eventLogger';
import ProfilePicture from '../profile-picture';
import FeedbackModal from '../Modal/FeedbackModal';
import IntegrationsModal from './integrations/Modal';
import MessageIcon from '../../icons/MessageIcon';
import SettingsIcon18x18 from '../../icons/SettingsIcon18x18';
import StarIcon from '../../icons/StarIcon';
import ThumbsUp18x18 from '../../icons/ThumbsUp18x18';
import Chat18x18 from '../../icons/Chat18x18';
import HelpCenter from '../../icons/HelpCenter';
import KeyboardIcon from '../../icons/KeyboardIcon';
import ShortcutsModal from '../shortcuts';
import FireworkConfetti from '../../confetti/FireworkConfetti';
import { DetectedOperatingSystemContext } from '../../../utils/context/OperatingSystemContext';
import CameraIcon from '../../icons/CameraIcon';
import ROUTES from '../../../routes';
import { AuthContext, UserDataContext } from '../../../App';
import ProfileIcon from '../profile-icon';
import { calculateIfSlackIsEnabled } from '../../../utils/slack/slackUtils';
import AboutModal from './aboutModal/AboutModal';
import InviteMembersModalOrganism from '../invite-members-modal/InviteMembersModalOrganism';
import { useUI } from '../../../pages/all-pages/ui-context/uiContext';
import useGetQueryParam from '../../../utils/hook/useGetParams';
import PathGenerator from '../../../utils/PathGenerator';
import {
  CLOSE_EVENT, OPEN_EVENT, SETTINGS_MODAL_LOCATION,
} from '../../../utils/analytics/enums';
import InfoCircle from '../../icons/infoCircle';
import { fadeIn } from '../../../pages/onboarding/welcome/utils/animations';
import Font from '../../typography/Font';
import ConsoleImproved from '../../classes/ConsoleImproved';
import { Page } from '../../types/types';
import DollarIcon from './DollarIcon';
import { toastDanger } from '../../../utils/notifications';
import StripeAPI from '../../../external/Stripe/StripeAPI';
import { RESOLVED } from '../../../utils/enums';
import { QueryParameters } from '../../../utils/meetings/QueryUtils';
import KnownUsersUtils from '../../../utils/user/KnownUsers.ts/KnownUsersUtils';
import { StripeConstants } from '../../../external/Stripe/StripeConstants';
import StripeSubscriptionUtils from '../../../external/Stripe/StripeSubscriptionUtils';
import SlackBoltAPI from '../../../external/SlackBolt/SlackBoltAPI';
import IntegrationsIcon from '../../icons/IntegrationsIcon';

const Container = styled.div`
  position: relative;
  margin: 0 12px;
  display: ${(props: ProfileSetting) => (props.photoUrl === '' ? 'auto' : 'flex')};
  align-items: ${(props: ProfileSetting) => (props.photoUrl === '' ? 'auto' : 'center')};
  z-index: 19;
  margin-left: 0px;
`;

const Trigger = styled.div`
  cursor: pointer;
  border-radius: 4px;
`;

interface MenuProps {
  dataActive: boolean,
}

const Menu = styled.div<MenuProps>`
  background: ${gray1};
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 48px;
  right: 0;
  left: 10px;
  opacity: 0;
  visibility: ${({ dataActive }) => (dataActive ? 'visible' : 'hidden')};

  ${({ dataActive }) => (dataActive && css`
    animation: ${fadeIn} ease-in-out forwards 0.2s;
  `)};

  width: 276px;
  z-index: 5;
`;

const TopSection = styled.div`
  display: flex;
  padding: 16px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  & > :first-child {
    margin-bottom: 3px;
  }
`;

const Links = styled.div`
  border-top: 2px solid ${darkBlue1};
  border-bottom: 2px solid ${darkBlue1};
  padding: 8px;
`;

const Setting = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${uiTextMedium};
  border-radius: 6px;
  cursor: pointer;
  padding: 6px 8px;

  & > :first-child {
    margin-right: 8px;
  }

  :hover {
    background: ${gray4};
  }
`;

const IntegrationsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background: ${gray4};
  }
`;

const NewIcon = styled.div`
  ${messageText};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 20px;
  background: ${red6};
  color: ${gray1};
  border-radius: 8px;
  margin-left: 29px;
`;

const Logout = styled.div`
  ${uiTextMedium};
  border-radius: 6px;
  color: ${red6};
  cursor: pointer;
  margin: 8px;
  padding: 8px;

  :hover {
    background: ${gray4};
  }
`;

interface ProfileSetting {
  photoUrl: string,
}

type ToggleIntercomSource = 'chat_with_us' | 'help_us';

interface Props {
  // eslint-disable-next-line no-unused-vars
  setPage: (page: Page) => void,
}

// eslint-disable-next-line no-unused-vars
const QuickSettings = ({ setPage }: Props) => {
  const stripe = useStripe();
  const { openInviteMemberModal, isInviteMemberModalOpen, closeInviteMemberModal } = useUI();
  const { detectedOperatingSystem } = useContext(DetectedOperatingSystemContext);
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const [isShortcutsModalOpen, setIsShortcutsModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);
  const [isChatBubbleOpen, setIsChatBubbleOpen] = useState(false);
  const { show, hide, trackEvent } = useIntercom();
  const navigate = useNavigate();
  const authData = useContext(AuthContext);
  const {
    firstName, lastName, email, photoUrl, userId,
  } = authData;
  const userData = useContext(UserDataContext);

  const isSettingsOpenURLParam = useGetQueryParam(PathGenerator.quickSettingParam) === 'true';

  useEffect(() => {
    if (isSettingsOpenURLParam) openSettingsModal();
  }, [isSettingsOpenURLParam]);

  useEffect(() => {
    if (userData.resolvedState !== RESOLVED) return;
    const openModal = useGetQueryParam(QueryParameters.OPEN_INVITE_USERS_MODAL);
    if (openModal !== 'true') return;
    openMembersModal();
    // eslint-disable-next-line no-restricted-globals
  }, [location, userData.resolvedState]);

  const toggleIntercom = (source: ToggleIntercomSource) => () => {
    if (isChatBubbleOpen) {
      hide();
      setIsChatBubbleOpen(false);
      logEventButtonClickOpenIntercom(authData.userId, trackEvent);
      logQuickSettingsClick(authData.userId, CLOSE_EVENT, source, trackEvent);
    } else {
      show();
      setIsChatBubbleOpen(true);
      logQuickSettingsClick(authData.userId, OPEN_EVENT, source, trackEvent);
    }
  };
  const [integrationsModalOpen, setIntegrationsModalOpen] = useState<boolean>(false);
  const [activateConfetti, setActivateConffetti] = useState<boolean>(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState<boolean>(false);
  const dropdownRef = useRef(null);
  const [isQuickSettingsOpen, setIsQuickSettingsOpen] = useDetectOutsideClick(dropdownRef, false);

  const initial = firstName[0];
  const fullName = `${firstName} ${lastName}`;

  useHotkeys('ctrl+/', () => {
    if (detectedOperatingSystem === 'Windows' || detectedOperatingSystem === 'Linux') {
      setIsQuickSettingsOpen(false);
      setIsShortcutsModalOpen((prev) => !prev);
    }
  }, [isShortcutsModalOpen, detectedOperatingSystem]);

  useHotkeys('Command+/', () => {
    if (detectedOperatingSystem === 'Mac') {
      setIsQuickSettingsOpen(false);
      setIsShortcutsModalOpen((prev) => !prev);
    }
  }, [isShortcutsModalOpen, detectedOperatingSystem]);

  const onToggleQuickSettingsMenu = () => {
    setIsQuickSettingsOpen(!isQuickSettingsOpen);
    logEventButtonClickQuickSettings(authData.userId, trackEvent);
    closeAllOtherModals();
  };

  const closeAllOtherModals = () => {
    setIsShortcutsModalOpen(false);
    setSettingsModalOpen(false);
    setIntegrationsModalOpen(false);
    setIsAboutModalOpen(false);
    setIsFeedbackModalOpen(false);
    closeInviteMemberModal();
  };

  const handleShortcutsModalOpen = () => {
    setIsShortcutsModalOpen(true);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'shortcuts', trackEvent);
  };

  const handleFeedbackModalOpen = () => {
    setIsFeedbackModalOpen(true);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'feedback', trackEvent);
  };

  const handleToggleIntegrationsModal = () => {
    setIntegrationsModalOpen(!integrationsModalOpen);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'integrations', trackEvent);
    logIntegrationsEvent(userId, OPEN_EVENT, '', SETTINGS_MODAL_LOCATION);
  };

  const openMembersModal = () => {
    openInviteMemberModal();
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'invite_members', trackEvent);
  };

  const openSettingsModal = () => {
    setIsQuickSettingsOpen(false);
    setSettingsModalOpen(true);
    logQuickSettingsClick(userId, OPEN_EVENT, 'settings', trackEvent);
  };

  useEffect(() => {
    if (activateConfetti) {
      setTimeout(() => {
        setActivateConffetti(false);
      }, 4000);
    }
  }, [activateConfetti]);

  const handleTutorialVideoClick = () => {
    navigate(ROUTES.tutorialVideoPath);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'tutorial', trackEvent);
  };

  const toggleAboutModal = () => {
    setIsAboutModalOpen((prev) => !prev);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'about', trackEvent);
  };

  const handleClickBilling = async () => {
    ConsoleImproved.log('Clicked billing');
    // We want to redirect them directly to Stripe billing page
    // setPage(ALL_PAGES.USER_CENTER_BILLING);
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'billing', trackEvent);
    try {
      const session = await StripeAPI.createPortalSession(userData, StripeConstants.isTest);
      window.open(session.url, '_blank');
    } catch (error: any) {
      captureMessage('Error creating Stripe portal session', {
        extra: {
          error,
          userBillingData: userData.billing,
        },
      });
      console.log(error);
      toastDanger('Error creating Stripe portal session', error?.message ?? 'Unknown error');
      ConsoleImproved.log('Error creating Stripe portal session', error);
    }
  };

  const handleClickTrialExtension = async () => {
    console.log('handleClickExtendTrial in Quick Settings');
    const customerId = userData.billing.stripeCustomerId;
    const priceId = StripeSubscriptionUtils.getTrialExtensionPriceId(StripeConstants.isTest);
    await SlackBoltAPI.logClickedTrialExtension(userData, 'duringFreePlan', 'quickSettings');

    const result = await StripeAPI.createCheckoutSessionForSingleCharge(customerId, priceId);
    await stripe?.redirectToCheckout({ sessionId: result.id });
    console.log(result?.url);
  };

  const isKnownUserId = KnownUsersUtils.isKnownUser(authData.userId);
  const isSlackEnabled = calculateIfSlackIsEnabled(userData);

  const isOnFreePlan = userData.billing.isOnFreePlan();

  return (
    <Container photoUrl={photoUrl}>
      <Trigger onClick={onToggleQuickSettingsMenu}>
        <ProfileIcon user={userData.publicUserData} />
      </Trigger>
      <Menu
        dataActive={isQuickSettingsOpen}
        ref={dropdownRef}
      >
        <div>
          <TopSection>
            <ProfilePicture photoUrl={photoUrl} initial={initial} />
            <ProfileDetails>
              <Font font="uiTextMedium">{fullName}</Font>
              <Font font="small" color="gray7">{email}</Font>
            </ProfileDetails>
          </TopSection>
          <Links>
            {/* <Setting>
              <UserIcon18x18 />
              My account
            </Setting> */}
            {/* <Setting>
              <BellIcon />
              Notifications
            </Setting> */}
            <Setting onClick={openMembersModal}>
              <MessageIcon />
              Invite members to Shepherd
            </Setting>
            <Setting onClick={openSettingsModal}>
              <SettingsIcon18x18 />
              Settings
            </Setting>
            <Setting onClick={handleClickBilling}>
              <DollarIcon />
              {/* <FontAwesomeIcon icon={solid('money-check-dollar')} /> */}
              {/* <i className="fa-solid fa-dollar-sign" /> */}
              Plan & Billing
            </Setting>
            {isOnFreePlan && (
              <IntegrationsContainer onClick={handleClickTrialExtension}>
                <Setting>
                  <StarIcon />
                  Upgrade to Premium
                  <NewIcon>New</NewIcon>
                </Setting>
              </IntegrationsContainer>
            )}
            <IntegrationsContainer onClick={handleToggleIntegrationsModal}>
              <Setting>
                <IntegrationsIcon />
                Apps
              </Setting>
            </IntegrationsContainer>
            <Setting onClick={handleTutorialVideoClick}>
              <CameraIcon />
              Tutorial video
            </Setting>
            <Setting onClick={handleShortcutsModalOpen}>
              <KeyboardIcon />
              Keyboard shortcuts
            </Setting>
            <Setting onClick={handleFeedbackModalOpen}>
              <ThumbsUp18x18 />
              Give us feedback
            </Setting>
            <FeedbackModal
              isModalOpen={isFeedbackModalOpen}
              setModalClosed={closeFeedbackModal}
            />
            <Setting onClick={toggleIntercom('chat_with_us')}>
              <Chat18x18 />
              Chat with us
            </Setting>
            <Setting onClick={toggleIntercom('help_us')}>
              <HelpCenter />
              Help Center
            </Setting>
            {isKnownUserId && (
              <Setting onClick={toggleAboutModal}>
                <InfoCircle fill={COLORS.surface} width="18" height="18" />
                About
              </Setting>
            )}
          </Links>
          <Logout onClick={() => { signOutOfGoogleAndFirebase(); }}>Log out</Logout>
        </div>
      </Menu>
      <IntegrationsModal
        isOpen={integrationsModalOpen}
        setClosed={handleToggleIntegrationsModal}
        setActivateConffetti={setActivateConffetti}
        activateConfetti={activateConfetti}
      />
      <ShortcutsModal isOpen={isShortcutsModalOpen} setIsOpen={setIsShortcutsModalOpen} />
      <InviteMembersModalOrganism
        isOpen={isInviteMemberModalOpen}
        setClosed={closeInviteMemberModal}
      />
      <Settings
        isOpen={isSettingsModalOpen}
        setIsOpen={setSettingsModalOpen}
        isSlackEnabled={isSlackEnabled}
      />
      <AboutModal isOpen={isAboutModalOpen} setModalClosed={toggleAboutModal} />
      <FireworkConfetti shouldTrigger={activateConfetti} />
    </Container>
  );
};

export default QuickSettings;
