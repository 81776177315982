import {
  AssigneeV2,
  AuthState, DatabaseUser, DatabaseUsers,
  MeetingRole, PublicUserDataV2, SimpleUserData, User, UserPermissions,
} from '../../shared/types/types';
import { UNRESOLVED_STATE, DEFAULT_SECTION } from '../enums';

import { dateISOObject, dateObject } from '../../utils/dateUtils/date';
import { defaultTrelloData } from '../trello/trelloUtils';
import { mapDatabaseSlackDataToSlackData } from '../slack/slackUtils';
import BillingUserData from '../../external/Stripe/BillingUserData';

export const defaultAuthContext: AuthState = {
  userId: '',
  userState: UNRESOLVED_STATE,
  firstName: '',
  lastName: '',
  email: '',
  photoUrl: '',
};

export const mapDatabaseUserDataToPublicUserDataV2 = (databaseUser: any, userId: string) => {
  const data: PublicUserDataV2 = {
    resolvedState: 'resolved',
    userId,
    isShepherdUser: true,
    data: {
      name: databaseUser?.data?.name ?? '',
      email: databaseUser?.data?.email ?? '',
      firstName: databaseUser?.data?.firstName ?? '',
      lastName: databaseUser?.data?.lastName ?? '',
      photoUrl: databaseUser?.data?.photoUrl ?? '',
    },
    external: {
      email: {
        receiveTaskEmail: databaseUser?.settings?.receiveTaskEmail ?? false,
      },
      slack: {
        hasEnabledSlack: databaseUser?.integrations?.slack?.length > 0 ?? false,
        notifications: {
          meetingStartsSoon: databaseUser
            ?.integrations?.slack[0]?.notifications?.meetingStartsSoon ?? false,
          mentionedInNotes: databaseUser
            ?.integrations?.slack[0]?.notifications?.mentionedInNotes ?? false,
          taskOverdue: databaseUser?.integrations?.slack[0]?.notifications?.taskOverdue ?? false,
          taskCreated: databaseUser?.integrations?.slack[0]?.notifications?.taskCreated ?? false,
          taskUpdated: databaseUser?.integrations?.slack[0]?.notifications?.taskUpdated ?? false,
          taskDeleted: databaseUser?.integrations?.slack[0]?.notifications?.taskDeleted ?? false,
        },
      },
      trello: {
        version: databaseUser?.integrations?.trello?.version ?? 1,
        isTrelloEnabled: databaseUser?.integrations?.trello?.settings?.isTrelloEnabled ?? false,
        isAutoSyncEnabled: databaseUser?.integrations?.trello?.settings?.isAutoSyncEnabled ?? false,
        isAllowOtherToSyncEnabled: databaseUser
          ?.integrations?.trello?.settings?.isAllowOtherToSyncEnabled ?? false,
      },
    },
  };
  return data;
};

export const mapAuthStateToDatabaseUser = (user: AuthState) => {
  const databaseUser: DatabaseUser = {
    friendList2: {},
    data: {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      photoUrl: user.photoUrl,
      newFeaturesViewed: [],
      hasDismissedDesktopNotification: false,
      hasOnboarded: false,
      onboarding: {
        personalShepherdUsage: '',
        jobType: '',
        companySize: '',
        productivityTool: '',
        remotePolicy: '',
      },
      receivedWelcomeEmail: false,
    },
    meta: {
      admin: false,
      analytics: {
        hasUsedScratchpad: false,
      },
      privateId: '',
    },
    settings: {
      fanOfCoding: false,
      fanOfPPT: false,
      receiveNewsletter: true,
      defaultUserTab: DEFAULT_SECTION,
      receiveTaskEmail: true,
      isDesktopNotificationEnabled: false,
      desktopNotification: {
        endpoint: '',
        expirationTime: null,
        keys: {
          p256dh: '',
          auth: '',
        },
      },
      clockFormat: '24h',
      disabledDomains: [],
    },
    billing: BillingUserData.createDefaultBillingData().value(),
    googleTokens: {
      accessToken: '',
      refreshToken: '',
      scope: '',
      tokenType: '',
      idToken: '',
      expiryDate: 0,
    },
    date: {
      created: dateISOObject(),
      updated: dateISOObject(),
    },
    integrations: {
      slack: [{
        version: 1,
        userAccessToken: '',
        botAccessToken: '',
        userId: '',
        defaultChannels: [],
        notifications: {
          meetingStartsSoon: false,
          mentionedInNotes: false,
          taskOverdue: false,
          taskCreated: false,
          taskUpdated: false,
          taskDeleted: false,
        },
        date: {
          created: dateISOObject(),
          updated: dateISOObject(),
        },
      }],
      notion: [],
      trello: defaultTrelloData,
      jira: {},
    },
    invitees: {},
    friendList: [],
    permissions: {
      google: {
        calendars: [],
      },
      tags: [],
    },
  };
  return databaseUser;
};

export const mapDatabaseDataToUser = (databaseUser: any, userId: string) => {
  const user: User = {
    resolvedState: 'resolved',
    userId,
    data: {
      name: databaseUser.data?.name ?? databaseUser.name ?? '',
      email: databaseUser.data?.email ?? databaseUser.email ?? '',
      firstName: databaseUser.data?.firstName ?? databaseUser.firstName ?? '',
      lastName: databaseUser.data?.lastName ?? databaseUser.lastName ?? '',
      photoUrl: databaseUser.data?.photoUrl ?? databaseUser.photoUrl ?? '',
      newFeaturesViewed: databaseUser.data?.newFeaturesViewed ?? [],
      hasDismissedDesktopNotification: databaseUser.data?.hasDismissedDesktopNotification ?? false,
      hasOnboarded: databaseUser.data?.hasOnboarded ?? true,
      onboarding: {
        personalShepherdUsage: databaseUser.data?.onboarding?.personalShepherdUsage ?? '',
        jobType: databaseUser.data?.onboarding?.jobType ?? '',
        companySize: databaseUser.data?.onboarding?.companySize ?? '',
        productivityTool: databaseUser.data?.onboarding?.productivityTool ?? '',
        remotePolicy: databaseUser.data?.onboarding?.remotePolicy ?? '',
      },
      receivedWelcomeEmail: databaseUser.data?.receivedWelcomeEmail ?? true,
    },
    meta: {
      admin: databaseUser.meta?.admin ?? false,
      analytics: {
        hasUsedScratchpad: databaseUser.meta?.analytics?.hasUsedScratchpad ?? false,
      },
      privateId: databaseUser.meta?.privateId ?? '',
    },
    settings: {
      fanOfCoding: databaseUser.settings?.fanOfCoding ?? false,
      fanOfPPT: databaseUser.settings?.fanOfPPT ?? false,
      receiveNewsletter: databaseUser.settings?.receiveNewsletter ?? true,
      defaultUserTab: databaseUser.settings?.defaultUserTab ?? DEFAULT_SECTION,
      receiveTaskEmail: databaseUser.settings?.receiveTaskEmail ?? true,
      isDesktopNotificationEnabled: databaseUser.settings?.isDesktopNotificationEnabled ?? false,
      desktopNotification: {
        endpoint: databaseUser.settings?.desktopNotification?.endpoint ?? '',
        expirationTime: databaseUser.settings?.desktopNotification?.expirationTime ?? null,
        keys: {
          p256dh: databaseUser.settings?.desktopNotification?.keys?.p256dh ?? '',
          auth: databaseUser.settings?.desktopNotification?.keys?.auth ?? '',
        },
      },
      clockFormat: databaseUser.settings?.clockFormat ?? '24h',
      disabledDomains: databaseUser.settings?.disabledDomains ?? [],
    },
    // billing: BillingUtils.mapRawDatabaseBillingDataToBillingData(databaseUser?.billing ?? {}),
    billing: new BillingUserData(databaseUser?.billing ?? {}, userId),
    googleTokens: {
      /**
       * For security reason, mapping of google tokens should be avoided
       * Also FE has no requirements of this token so its unnecessary to map them
       * but this doesn't mean `databaseUser` object contains the googleTokens
       *
       * PS: From the feedback from Tom, the process to check if user has google tokens
       * set or not is using a CF called `checkNotificationPermission` but since calling CF
       * to check is taking time and turns out to be a bad UX for now mapping of the
       * refresh token is done on the FE but i mostly dont recommend it.
       */
      accessToken: '',
      refreshToken: databaseUser.googleTokens?.refreshToken ?? '',
      scope: '',
      tokenType: '',
      idToken: '',
      expiryDate: 0,
    },
    date: {
      created: databaseUser.date?.created ?? dateISOObject(),
      updated: databaseUser.date?.updated ?? dateISOObject(),
    },
    integrations: {
      slack: mapDatabaseSlackDataToSlackData(databaseUser),
      notion: databaseUser?.integrations?.notion ?? [],
      trello: databaseUser?.integrations?.trello ?? defaultTrelloData,
      jira: {},
    },
    invitees: databaseUser?.invitees ?? {},
    friendList: databaseUser.friendList ?? [],
    friendListV2: {
      resolvedState: 'pending',
      users: [],
    },
    publicUserData: mapDatabaseUserDataToPublicUserDataV2(databaseUser, userId),
    permissions: {
      google: {
        calendars: databaseUser?.permissions?.google?.calendars ?? [],
      },
      tags: databaseUser?.permissions?.tags ?? [],
    },
  };
  return user;
};

export const pendingUser: User = {
  ...mapDatabaseDataToUser({}, ''),
  resolvedState: 'pending',
};

export const rejectedUser: User = {
  ...mapDatabaseDataToUser({}, ''),
  resolvedState: 'rejected',
};

export const mapDatabaseUsersToUsers = (users: DatabaseUsers): SimpleUserData[] => {
  if (!users) return [];
  let newUsers: SimpleUserData[] = [];
  Object.keys(users).forEach((key) => {
    const value = users[key];
    //   TODO: Rather use Map function in stead of template function
    // eslint-disable-next-line max-len
    const newUser: SimpleUserData = templateSimpleUserData(value.userId, value.name, value.email, value.role, value.access);
    newUsers.push(newUser);
  });
  newUsers = sortUsersByRole(newUsers);
  return newUsers;
};

export const getCandidatesFromUserData = (userData: User): AssigneeV2[] => {
  if (userData.resolvedState !== 'resolved') return [] as AssigneeV2[];
  if (userData.friendListV2.resolvedState !== 'resolved') return [userData.publicUserData] as AssigneeV2[];
  return removeDuplicates([...userData.friendListV2.users, userData.publicUserData]);
};

export const templateSimpleUserData = (userId: string, name = '', email = '', role: MeetingRole = 'editor', access = true) => {
  const user: SimpleUserData = {
    access,
    userId,
    role,
    name,
    email,
    date: {
      added: dateObject(),
      created: dateObject(),
      lastViewed: dateObject(),
    },
  };
  return user;
};
export const templateSimpleUserDataArray = (userId: string, name = '', email = '', role: MeetingRole = 'editor', access = true) => {
  const users: SimpleUserData[] = [{
    access,
    userId,
    role,
    name,
    email,
    date: {
      added: dateObject(),
      created: dateObject(),
      lastViewed: dateObject(),
    },
  }];
  return users;
};

const sortByRole = (one: SimpleUserData, two: SimpleUserData) => {
  if (roleToNumber(one) > roleToNumber(two)) return -1;
  if (roleToNumber(one) < roleToNumber(two)) return 1;
  return 0;
};
const sortUsersByRole = (users: SimpleUserData[]) => users.sort(sortByRole);

const roleToNumber = (user: SimpleUserData) => {
  switch (user.role) {
    case 'admin':
      return 5;
    case 'moderator':
      return 4;
    case 'editor':
      return 3;
    case 'commenter':
      return 2;
    case 'viewer':
      return 1;
    default:
      return 0;
  }
};

export const templateDatabaseUsers = (userId: string, name = '', email = '', role: MeetingRole = 'editor', access = true) => {
  const user = templateSimpleUserData(userId, name, email, role, access);
  return {
    [`${userId}`]: user,
  };
};

export const templateDatabaseUsersBySimpleUserData = (simpleUserData: SimpleUserData) => ({
  [`${simpleUserData.userId}`]: simpleUserData,
});

export const templateUserPermissions: UserPermissions = {
  canEdit: false,
  canEditTitle: false,
  canEditMeetingSeries: false,
  canEditLinkSettings: false,
  canAddUser: false,
  canAddAdmin: false,
  canAddModerator: false,
  canAddEditor: false,
  canAddCommenter: false,
  canAddViewer: false,
  canRemoveUser: false,
  canAddGroup: false,
  canDeleteMeeting: false,
  canComment: false,
  canView: false,
  isLastAdmin: false,
  role: 'noAccess',
};

export const removeDuplicates = (users: PublicUserDataV2[]) => {
  // Most likely there will not be any duplicates
  // but if the emails array is longer than 10
  // and if a particular email is both in the first
  // and second sublist of emails, it will be duplicates
  const uniqueUsers = users.reduce((
    acc: PublicUserDataV2[],
    curr: PublicUserDataV2,
  ) => {
    if (acc.find((user) => user.data.email === curr.data.email)) return acc;
    return [...acc, curr];
  }, [] as PublicUserDataV2[]);
  return uniqueUsers;
};

export class UserUtils {
  static hasReceivedWelcomeEmail = (user: User): boolean => {
    if (user.data.receivedWelcomeEmail) return true;
    if (!user.data.hasOnboarded) return true;

    return false;
  };

  /**
   * The first time the user logs in, we set the initial user data.
   * i.e. we set the hasOnboarded to false
   * @return if the user has set the initial user data
   */
  static isInitialUserDataSet = (userData: User): boolean => userData.data.name.length > 0
    && userData.data.email.length > 0;
}
