import { css } from 'styled-components';
import { blue5, darkBlue7 } from '../colours';

export const interFont = css`
  @import url('https://fonts.googleapis.com/css?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
`;

// HEADINGS
export const header900 = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: 500;
  font-size: 35px;
  line-height: 40px;
`;

export const header800 = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: 500;
  font-size: 29px;
  line-height: 32px;
`;

export const header700 = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;
export const header600 = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;
export const header500 = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;
export const header400 = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;
export const header300 = css` 
  font-family: 'Inter', SF Pro Text;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;
export const header200 = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;
export const header100 = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
`;

export const uiText = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;
export const uiTextMedium = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
export const messageText = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;
export const small = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
`;
export const smallPluss = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;
export const pointer = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
`;

// PARAGRAPH
export const defaultText = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

export const defaultSmall = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.08px;
`;

export const link = css`
  color: ${blue5};
  font-family: 'Inter', SF Pro Text;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: none;
`;
export const linkHover = css`
  ${link};
  text-decoration-line: underline;
`;
export const blockQuote = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;
export const titleSemibold = css`
  font-family: 'Inter', SF Pro Text;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

// New fonts that I wouldn't know how to name, pls help :) p.s I commented it out
//  because it did not look good
// export const unnamedFont = css`
//   font-family: Inter;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 12px;
//   line-height: 20px;
// `;

export const paragraph = css`
  font-family: Inter, SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

export const uiTextS = css`
  font-family: Inter, SF Pro Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
`;

export const uiTextM = css`
  font-family: Inter, SF Pro Text;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
`;

export const uiTextMediumS = css`
  font-family: Inter, SF Pro Text;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${darkBlue7};
`;

export const titleSemiboldForDashboardWelcomeScreen = css`
  font-family: 'Inter', SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;
