import UserAPI from '../../database/User/UserAPI';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import EmailValidator from '../../shared/classes/EmailValidator';
import { COLORS } from '../../shared/colours';
import {
  DatabaseTaskItem, ResolvedState, SendGridEmailRecipient,
  TaskItem, TaskNotificationType, TaskUpdateField, User,
} from '../../shared/types/types';
import { DateToTemplateDateFormat } from '../../utils/dateUtils/date';
import {
  REJECTED, TASK_NOTIFICATION_ASSIGN, TASK_NOTIFICATION_DELETE, TASK_NOTIFICATION_UPDATE,
} from '../../utils/enums';
import { toastDanger } from '../../utils/notifications';
import { isTaskOverdue } from '../../utils/tasks/tasksUtils';
import { UserUtils } from '../../utils/user/UserDataUtils';
import EmailCoreAPI from './EmailCoreAPI';
import { getTaskOperationDescription, getStatusIconUrl } from './emailUtils';

/**
 * API to send email. Emails are typically sent with SendGrid
 */
class EmailAPI extends EmailCoreAPI {
  /**
   * @param taskItem The task that was just created
   * @param reporterName Name of the user which created the task
   * @param reporterEmail Name of the user which created the task
   */
  static async sendNotificationForTaskCreation(
    taskItem: TaskItem,
    reporterName: string,
    reporterEmail: string,
  ): Promise<any> {
    if (!EmailValidator.validate(reporterEmail, 'sendNotificationForTaskCreation')) return;
    EmailAPI.cfSendTaskAssignNotificationEmail(taskItem, reporterName, reporterEmail)
      .catch((error) => toastDanger('Failed to send notification', error));
  }

  /**
   * Very simple interface to send an email
   */
  static async sendBasicEmail(
    emailRecipients: string[],
    subject: string,
    mainContentHTML: string,
  ): Promise<ResolvedState> {
    // const invalidEmails =
    // emailRecipients.filter((email) => !EmailValidator.validate(email, 'sendBasicEmail'));
    const invalidEmails = emailRecipients
      .filter(EmailValidator.filterByNotValidEmails('sendBasicEmail'));

    if (invalidEmails.length > 0) {
      console.error('Invalid emails in sendBasicEmail', invalidEmails);
      return REJECTED;
    }
    ConsoleImproved.log('Send basic email', { emailRecipients, subject, mainContentHTML });
    return EmailCoreAPI.sendBasicEmailCore(emailRecipients, subject, mainContentHTML);
  }

  // Per 13.03.2023 we disabled this welcome email, and rather send
  // out emails from Intercom
  static sendWelcomeEmailIfNotSent = async (userData: User) => {
    if (UserUtils.hasReceivedWelcomeEmail(userData)) return;

    const isUpdated = await UserAPI.Data.setHasReceivedWelcomeEmail(userData);
    if (!isUpdated) return;

    const sendWelcomeEmailResolvedState = await EmailAPI
      .cfSendWelcomeEmailAPI(userData.data.firstName);

    if (sendWelcomeEmailResolvedState === REJECTED) {
      UserAPI.Data.dbUserSetWelcomeEmailSentStatus(userData.userId, false);
      return;
    }
    console.log('Successfully sent welcome email and updated status in database');
  };

  /**
   * Email is provided through the authenticated user
   */
  static cfSendWelcomeEmailAPI = async (
    firstName: string,
  ): Promise<ResolvedState> => EmailCoreAPI.cfSendWelcomeEmailCore(firstName)
    .then((resolveState: ResolvedState) => {
      if (resolveState === REJECTED) {
        console.log('error sending welcome email');
      }
      ConsoleImproved.log('Welcome email sent', { firstName, resolveState });

      return resolveState;
    });

  static cfSendInviteMembersEmails = async (
    emails: string[],
  ): Promise<ResolvedState> => EmailCoreAPI.cfSendInviteMembersEmailsCore(emails)
    .then((resolveState: ResolvedState) => {
      if (resolveState === REJECTED) {
        console.log('error sending invite members emails');
      }
      return resolveState;
    });

  static cfSendMeetingNotesEmail = (
    emails: SendGridEmailRecipient[], meetingTitle: string, html: string, templateId: string,
  ) => EmailCoreAPI.cfSendMeetingNotesEmailCore(emails, meetingTitle, html, templateId);

  static cfSendQuickFeedbackEmail = (
    email: string, feedback: string,
  ) => EmailCoreAPI.cfSendQuickFeedbackEmailCore(email, feedback);

  static cfGetAssigneeDataByEmails = ( // TODO: Shaokun: Should return Promise<AssigneeData[]>
    emails: string[],
  ) => EmailCoreAPI.cfGetAssigneeDataByEmailsCore(emails);

  /**
   * @param reporterName - Name of the person which changed the task
   * @param reporterEmail - Email of the person which changed the task
   */
  static cfSendTaskChangeNotificationEmail = (
    disabled: boolean, task: TaskItem | DatabaseTaskItem,
    reporterName: string, reporterEmail: string,
    notificationType: TaskNotificationType, updateFields?: TaskUpdateField[],
  ) => {
    if (disabled
      || task.data.assignee.email.length === 0
      || task.data.assignee.email === reporterEmail) {
      ConsoleImproved.log("Not sending task change notification email because it's disabled or the assignee's email is the same as the reporter's email", {
        task, reporterName, reporterEmail, notificationType, updateFields,
      });
      return;
    }
    console.log('sending task updated notification email');
    EmailCoreAPI.cfSendTaskChangeNotificationEmailCore(
      task.data.assignee.email,
      reporterName,
      getTaskOperationDescription(notificationType, updateFields),
      task.data.assignee.name,
      task.data.title,
      task.date.dueDate.type === 'noDueDate' ? 'none' : 'unset',
      DateToTemplateDateFormat(task.date.dueDate.date.date),
      isTaskOverdue(task) ? COLORS.red7 : 'unset',
      getStatusIconUrl(task.data.status),
      task.data.description,
    ).then(() => console.log('task updated notification email sent'));
  };

  static cfSendTaskUpdateNotificationEmail = async (
    task: TaskItem | DatabaseTaskItem, userData: User,
    updateFields?: TaskUpdateField[] | undefined,
  ) => {
    UserAPI.OtherUsers.cfSearchUserSettingByEmail(task.data.assignee.email)
      .then((settings) => {
        if (!settings) throw new Error('No assignee settings found');
        EmailAPI.cfSendTaskChangeNotificationEmail(
          !settings.receiveTaskEmail,
          task,
          userData.data.name,
          userData.data.email,
          TASK_NOTIFICATION_UPDATE,
          updateFields,
        );
      })
      .catch((error) => {
        console.log('user setting not found', error);
      });
  };

  static cfSendTaskAssignNotificationEmail = async (
    task: TaskItem | DatabaseTaskItem, name: string, email: string,
  ) => {
    if (!task.data.assignee.email.length) return;
    console.log('Maybe sending task assign notification email');
    const settings = await UserAPI.OtherUsers.cfSearchUserSettingByEmail(task.data.assignee.email);

    if (!settings) throw new Error('No assignee settings found');
    EmailAPI.cfSendTaskChangeNotificationEmail(
      !settings.receiveTaskEmail,
      task,
      name,
      email,
      TASK_NOTIFICATION_ASSIGN,
    );
  };

  static cfSendTaskDeleteNotificationEmail = async (
    task: TaskItem | DatabaseTaskItem, userData: User,
  ) => {
    console.log('Sending task deleted notification email');
    UserAPI.OtherUsers.cfSearchUserSettingByEmail(task.data.assignee.email).then((settings) => {
      if (!settings) throw new Error('No assignee settings found');
      EmailAPI.cfSendTaskChangeNotificationEmail(
        !settings.receiveTaskEmail,
        task,
        userData.data.name,
        userData.data.email,
        TASK_NOTIFICATION_DELETE,
      );
    }).catch((error) => {
      console.log(error);
    });
  };
}

export default EmailAPI;
