/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom-v5-compat';
import ROUTES from '.';
import { AuthContext, UserDataContext } from '../App';

interface Props {
  children: any
}

const PrivateRoute = ({ children }: Props) => {
  const authData = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const location = useLocation();
  if (authData.userState === 'unresolved') {
    return <div />;
  }
  if (authData.userState === 'loggedOut') {
    return <Navigate to={ROUTES.signinPath} />;
  }
  if (!userData.data.hasOnboarded && !location.pathname.includes(ROUTES.onboardingPath)) {
    return <Navigate to={ROUTES.onboardingPersonalPath} />;
  }
  return children;
};

export default PrivateRoute;
