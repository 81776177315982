import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { COLORS } from '../../shared/colours';
import Header from '../../shared/components/header';
import { DEFAULT_HEADER_MEETING_VIEW } from '../../shared/components/header/utils/constants';
import LeftArrowOutlineIcon from '../../shared/icons/leftArrowOutline';
import { header700 } from '../../shared/typography';
import Font from '../../shared/typography/Font';
import OnboardingWelcomeScreensModel from '../onboarding/welcome/utils/onboarding-welcome-screens-model';

const TitleContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;

  ${header700}
`;

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const BackButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px;
  border-radius: 8px;
  background: ${COLORS.gray4};
  width: 100%;
  max-width: 980px;

  &:hover {
    cursor: pointer;
    background: ${COLORS.gray5};
  }

  & > span {
    margin-left: 4px;
  }
`;

const IframeVideoWrapper = styled.div`
  & > iframe {
    width: 100%;
    max-width: 980px;
    aspect-ratio: 1/1;
    border-radius: 15px;
  }
  padding: 10px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  transform: translateY(0px);
`;

const TutorialVideo = () => {
  const navigate = useNavigate();
  // TODO: Test if this works
  const onGoBackNavButtonClick = () => navigate(-1);
  const { videoSrc } = OnboardingWelcomeScreensModel.tutorialVideo;

  return (
    <>
      <Header view={DEFAULT_HEADER_MEETING_VIEW} />
      <TitleContainer>Tutorial Video</TitleContainer>
      <BackButtonContainer onClick={onGoBackNavButtonClick}>
        <BackButtonInnerContainer>
          <LeftArrowOutlineIcon fill="black" />
          <Font font="uiTextMedium">Back</Font>
        </BackButtonInnerContainer>
      </BackButtonContainer>
      <IframeVideoWrapper>
        <iframe
          src={videoSrc}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </IframeVideoWrapper>
    </>
  );
};

export default TutorialVideo;
