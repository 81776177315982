import React, { useState } from 'react';
import styled from 'styled-components';
import { gray7 } from '../../../../../shared/colours';
import { defaultSmall, header200 } from '../../../../../shared/typography';
import AddToCalendarImg from '../../../../../shared/img/AddToGoogleCalendar.png';
import HelpIconAtom from '../../../../all-notes/HelpIconAtom';
import HelpIconInfoModal from '../../HelpIconInfoModal';
import { NotesSwitchData } from '../utils/types';
import NotesSwitch from './NotesSwitch';

const SectionText = styled.h2`
  ${header200};
  margin-bottom: 16px;
`;

const SubText = styled.div`
  display: flex;
  margin: 24px 0px 24px 0px;
  /* Second child div */
  & > span:nth-child(2) {
    svg {
      margin-left: 6px;
    }
  }
`;

const Description = styled.span`
  ${defaultSmall}
  color:${gray7};
  margin-right: 10px;
`;

interface Props {
  notesSwitchData: NotesSwitchData,
  setNotesSwitchData: React.Dispatch<React.SetStateAction<NotesSwitchData>>,
}

export default function CalendarShareView({
  notesSwitchData, setNotesSwitchData,
}: Props) {
  const [isHelpInfoModal, setIsHelpInfoModal] = useState(false);
  return (
    <>
      <SectionText>Select to share</SectionText>
      <NotesSwitch
        notesSwitchData={notesSwitchData}
        setNotesSwitchData={setNotesSwitchData}
        showTaskToggle={false}
      />
      <SubText>
        <Description>
          The notes will be added to the calendar event description.
        </Description>
        <HelpIconAtom onClick={() => { setIsHelpInfoModal(true); }} />
      </SubText>
      {/* <ModalLineLocal />
      <SectionText>Add custom message</SectionText>
      <CustomMessage
        setEmailHeadTemplate={setEmailHeadTemplate}
        emailHeadTemplate={emailHeadTemplate}
      /> */}
      <HelpIconInfoModal
        isOpen={isHelpInfoModal}
        setModalClosed={() => { setIsHelpInfoModal(false); }}
        headText="Add the shared notes from this meeting to the calendar event"
        subText="This allows the shared notes to be available to all attendees from the Google calendar description."
        imgSrc={AddToCalendarImg}
      />
    </>
  );
}
