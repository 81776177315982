import { BasicUser, NotificationSource, NotificationSourceType } from '../../types/types';
import SDate from '../SDate';
import NotificationAPI from './NotificationAPI';
import NotificationCore from './NotificationCore';

/**
 * Responsible for
 * - Generating notification objects
 * - Interacting with FE code
 * - Updating isRead/isDismissed
 * - Performing actions on notifications
 * @extends NotificationAPI
 * @method `generateNotificationSource(source, identifier)` => `NotificationSource`
 * @method `generate(recipient, reporter, notificationSource)` => `Notification`
 * @method `updateIsRead(isRead: boolean)` => `Promise<ResolvedState>`
 * @method `updateIsDismissed(isDismissed: boolean)` => `Promise<ResolvedState>`
 * @method `performAction()` => `void`
 */
class Notification extends NotificationCore {
  /** Used typically before creating a Notification object */
  static generateNotificationSource = (
    source: NotificationSourceType, identifier1: string, identifier2: string = '', identifier3: string = '',
  ) => NotificationCore.mapDbDataToNotificationSource({
    source, identifier1, identifier2, identifier3,
  });

  /** Typically used when creating a new Notification object as a result of user action.
   * Will not add it to the DB. YOu need to call `addNotificationToDb` to save it to DB.
   */
  static generateNotification = (
    recipient: BasicUser, reporter: BasicUser, notificationSource: NotificationSource,
  ) => {
    const newNotificationObject = NotificationCore.generateNewNotification(
      recipient, reporter, notificationSource,
    );
    const classObj = new Notification('', newNotificationObject);
    return classObj;
  }

  addNotificationToDb = () => NotificationAPI.addNotificationToDb(this)

  /** Updates isRead field of a notification based on param passed */
  updateIsRead = async (isRead: boolean) => {
    const date = new SDate().value;
    const read = { isRead, date };
    return NotificationAPI.updateNotificationInDb(this.db.documentId, { read });
  }

  /** Updates isDismissed field of a notification based on param passed */
  updateIsDismissed = async (isDismissed: boolean) => {
    const date = new SDate().value;
    const dismissed = { isDismissed, date };
    return NotificationAPI.updateNotificationInDb(this.db.documentId, { dismissed });
  }

  performAction = async () => {
    // add logic to perform action related to notification
  }
}

export default Notification;
