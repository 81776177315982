import moment from 'moment';
import { dateISOObject, dateObject } from '../../utils/dateUtils/date';
import {
  DatabaseMeetingData,
  GapiMeetingData,
  MeetingData,
  SimpleUserData,
  ConferenceData,
  DatabaseGapiMeetingData,
  GapiMeetingDate,
} from '../../shared/types/types';
import { templateSimpleUserData, templateUserPermissions } from '../../utils/user/UserDataUtils';
import SDate from '../../shared/classes/SDate';

export const emptyMeetingList: MeetingData[] = [];

export const pendingMeetingData: MeetingData = {
  resolvedState: 'pending',
  attendees: {
    attendees: [],
    resolvedState: 'pending',
  },
  userRole: templateUserPermissions,
  meetingId: '',
  version: 5,
  googleData: {
    ids: {
      eventId: '',
      recurringEventId: '',
      dataEventId: '',
      calendarId: '',
      meetId: '',
      htmlLink: '',
    },
    conferenceData: {
      type: 'undefined',
      link: '',
    },
    attendees: [],
    content: {
      summary: '',
      organizer: {
        email: '',
      },
      creator: {
        email: '',
      },
    },
  },
  permissions: {
    users: [templateSimpleUserData('')],
    userGroups: [],
    tags: [],
    linkPermissions: 'private',
  },
  data: {
    agenda: [],
    attachments: [],
    attendees: [],
    description: '',
    title: '',
    postMeetingTasks: [],
    preMeetingTasks: [],
  },
  tags: {
    tags: [],
    meetingSeries: {
      name: '',
      id: '',
    },
  },
  date: {
    created: dateObject(),
    start: dateObject(),
    end: dateObject(),
    lastUpdated: dateObject(),
  },
};

export const emptyDatabaseGapiMeetingData: DatabaseGapiMeetingData = {
  updated: '',
  summary: '',
  description: '',
  status: '',
  start: {
    dateTime: '',
    timeZone: '',
    date: '',
  },
  end: {
    dateTime: '',
    timeZone: '',
    date: '',
  },
  sequence: NaN,
  reminders: {
    useDefault: false,
  },
  organizer: {
    email: '',
    self: false,
  },
  kind: '',
  id: '',
  htmlLink: '',
  iCalUID: '',
  eventType: '',
  recurringEventId: '',
  recurrence: [],
  etag: '',
  creator: {
    email: '',
    self: false,
  },
  created: '',
  conferenceData: {
    conferenceId: '',
    conferenceSolution: {
      iconUri: '',
      key: {
        type: '',
      },
      name: '',
    },
    entryPoints: [],
    signature: '',
  },
  attendees: [],
};

export const rejectedMeetingData: MeetingData = { ...pendingMeetingData, resolvedState: 'rejected' };

export const mapGoogleMeetingToDatabaseMeetingData = (
  googleMeeting: GapiMeetingData,
  calendarId: string,
  user: SimpleUserData,
): DatabaseMeetingData => {
  const newMeetingData: DatabaseMeetingData = {
    version: 5,
    googleData: {
      ids: {
        eventId: googleMeeting?.id ?? '',
        recurringEventId: googleMeeting?.recurringEventId ?? '',
        dataEventId: '',
        calendarId: googleMeeting.organizer.email ?? '',
        meetId: googleMeeting?.conferenceData?.conferenceId ?? '',
        htmlLink: googleMeeting?.htmlLink ?? '',
      },
      content: {
        summary: googleMeeting?.summary ?? '',
        organizer: {
          email: googleMeeting?.organizer?.email ?? '',
        },
        creator: {
          email: googleMeeting?.creator?.email ?? '',
        },
      },
      attendees: googleMeeting?.attendees.map((attendee) => attendee?.email) ?? [],
      conferenceData: mapGoogleMeetingToConferenceData(googleMeeting),
    },
    permissions: {
      users:
      {
        [user.userId]: user,
      },
      userGroups: [],
      tags: ['haraldTag'],
      linkPermissions: 'public_edit',
    },
    data: {
      agenda: [],
      attachments: [],
      attendees: googleMeeting?.attendees ?? [],
      description: googleMeeting?.description ?? '',
      title: googleMeeting?.summary ?? '',
      postMeetingTasks: [],
      preMeetingTasks: [],
      status: googleMeeting?.status ?? 'confirmed',
    },
    tags: {
      tags: [],
      meetingSeries: {
        name: '',
        id: '',
      },
    },
    date: {
      created: dateISOObject(),
      start: getDateObjectFromGoogleDate(googleMeeting.start),
      end: getDateObjectFromGoogleDate(googleMeeting.end),
      lastUpdated: dateISOObject(),
    },
  };
  return newMeetingData;
};

export const getDateObjectFromGoogleDate = (googleDate: GapiMeetingDate) => {
  if (googleDate.dateTime?.length > 0) {
    return {
      date: googleDate.dateTime,
      timestamp: Number(moment(googleDate.dateTime).format('x')), // Ts with millisecond
    };
  }

  if (googleDate.date?.length > 0) {
    const newDate = SDate.convertDateStringToISOString(googleDate.date);
    return {
      date: newDate,
      timestamp: Number(moment(newDate).format('x')), // Ts with millisecond
    };
  }

  return {
    date: '',
    timestamp: 0,
  };
};

export const mapGoogleMeetingToConferenceData = (
  gapiMeetingData: GapiMeetingData,
): ConferenceData => {
  if (isZoomConferenceInGapiMeeting(gapiMeetingData)) {
    return {
      type: 'zoom',
      link: createZoomLinkFromGapiMeeting(gapiMeetingData.description),
    } as ConferenceData;
  }
  if (gapiMeetingData.conferenceData.conferenceSolution.key.type === 'hangoutsMeet') {
    return {
      type: 'googleMeet',
      link: createGoogleMeetLinkFromGapiMeeting(gapiMeetingData),
    } as ConferenceData;
  }
  return {
    type: 'undefined',
    link: '',
  } as ConferenceData;
};

const createGoogleMeetLinkFromGapiMeeting = (gapiMeetingData: GapiMeetingData) => {
  const googleMeetId = gapiMeetingData.conferenceData.conferenceId ?? '';
  if (googleMeetId.length === 0) return '';

  return `https://meet.google.com/${googleMeetId}?authuser=0`;
};

const isZoomConferenceInGapiMeeting = (gapiMeetingData: GapiMeetingData) => {
  const link = createZoomLinkFromGapiMeeting(gapiMeetingData.description);
  if (link.length === 0) return false;
  return true;
};

/**
 * Will search for a zoom link in the description, and return it if found.
 *
 * Will match any sub-domain zoom links. i.e. https://companyA.zoom.us/j/... and https://companyB.zoom.us/j/...
 * Will match any letter between .us/ and id number. i.e. https://zoom.us/j/... and https://zoom.us/w/...
 */
const createZoomLinkFromGapiMeeting = (description: string) => {
  // Use https://regex101.com/ to understand the Regex
  // https://regex101.com/r/3RbPG7/1
  const matches = description?.match(/https:\/\/(.*?)zoom.us\/[a-zA-Z-]\/([^\s"]+)/);
  return matches?.values().next().value ?? '';
};
