import {
  green1, purple1, red1, blue1, cyan1, yellow1, purple6, red6, green6, blue6, cyan6, yellow6,
} from '../../../../../shared/colours';
import {
  BasicUser, AuthState, PublicUserDataV2, SDateT, PrivateNote, PrivateNoteAttendeeV2,
} from '../../../../../shared/types/types';

export const emailTagsColors = [
  purple1, red1, green1, blue1, cyan1, yellow1,
];

export const emailTextColors = [
  purple6, red6, green6, blue6, cyan6, yellow6,
];

export const createSecretChatObject = (
  title: string,
  chatPathInRealtimeDb: string,
  members: PrivateNoteAttendeeV2[],
  meetingId: string,
  userIds: string[],
  created: SDateT,
  updated: SDateT,
  creator: PublicUserDataV2,
) => {
  const secretChat: PrivateNote = {
    title,
    chatPathInRealtimeDb,
    members,
    meetingId,
    userIds,
    created,
    updated,
    creator,
  };
  return secretChat;
};

export const authDataToAssignee = (authData: AuthState) => {
  const assignee: BasicUser = {
    userId: authData.userId,
    photoUrl: authData.photoUrl,
    email: authData.email,
    name: `${authData.firstName} ${authData.lastName}`,
  };
  return assignee;
};
