/* eslint-disable react/static-property-placement */
import React, { RefObject } from 'react';
import styled, { css } from 'styled-components';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { COLORS } from '../../../shared/colours';
import { GapiMeetingData, ScrollState } from '../../../shared/types/types';
import DayLabelAtom, { getDay } from '../DayLableAtom';
import AllNotesBottomButtonToFetchMoreMeetings from './AllNotesBottomButtonToFetchMoreMeetings';
import NoteItemMolecule from './NoteItemMolecule';

/**
 * We need this container to assign the id
 * so that when we scroll we leave a bit of space
 * on the top of the day label
 */
const DayContainerTop = styled.div`
  padding-top: 24px;
`;

interface DayContainerProps {
  day: string,
}

const DayContainerInner = styled.div<DayContainerProps>`

  ${({ day }) => getDay(day) === 'Today' && css` 
    box-shadow: 0px 2px 8px rgb(27 33 36 / 16%);
    margin-left: 5px;
    margin-right: 24px;
    /* We need the padding to see the box shadow */
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 10px;
    /* background-color: ${COLORS.layoutWhite}; */
    background-color: ${COLORS.blue1};
  `}

  border-radius: 18px;
`;

interface Props {
  isFetchMoreMeetingsBtnEnable: boolean;
  groupedNotes: { [date: string]: GapiMeetingData[] };
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onAction: (eventId: string, calendarId: string, startDateTime: string) => void,
  // eslint-disable-next-line no-unused-vars, react/no-unused-prop-types
  onScroll: (status: ScrollState) => void,
  onClickFetchMoreMeetings: () => void;
}

export class NotesListOrganism extends React.Component<Props, any> {
  scrollRef: RefObject<OverlayScrollbarsComponent>;

  props: Props;

  constructor(props: Props) {
    super(props);
    this.props = props;
    this.scrollRef = React.createRef<OverlayScrollbarsComponent>();
  }

  onScrollStop = () => {
    const { onScroll } = this.props;
    if (this?.scrollRef?.current!.osInstance()?.scroll().position.y === 0) {
      onScroll('scrollTop');
    } else if (
      this?.scrollRef?.current!.osInstance()?.scroll().position.y
      === this?.scrollRef?.current!.osInstance()?.scroll().max.y) {
      onScroll('scrollBottom');
    } else {
      onScroll('scrollUpdate');
    }
  }

  render() {
    const {
      groupedNotes, onAction, isLoading, onClickFetchMoreMeetings, isFetchMoreMeetingsBtnEnable,
    } = this.props;
    return (
      <OverlayScrollbarsComponent
        id="AllNotesContainer"
        ref={this.scrollRef}
        options={{
          paddingAbsolute: true,
          callbacks: {
            onScrollStop: this.onScrollStop,
          },
        }}
        style={{
          height: '100%',
        }}
      >
        {Object.keys(groupedNotes).map((eachDay) => (
          <DayContainerTop key={eachDay} id={getDay(eachDay)}>
            <DayContainerInner day={eachDay} key={eachDay}>
              <DayLabelAtom date={eachDay} />
              {groupedNotes[eachDay].map((note) => (
                <NoteItemMolecule
                  key={note.id}
                  note={note}
                  date={eachDay}
                  onAction={onAction}
                />
              ))}
            </DayContainerInner>
          </DayContainerTop>
        ))}
        {isFetchMoreMeetingsBtnEnable && (
        <AllNotesBottomButtonToFetchMoreMeetings
          isLoading={isLoading}
          onClick={onClickFetchMoreMeetings}
        />
        )}

      </OverlayScrollbarsComponent>
    );
  }
}

export default NotesListOrganism;
