import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { makeMeetingUrl } from '../../../../utils/meetings/meetingsUtils';
import Notification from '../../../classes/Notification/Notification';
import Modal from '../../Modal/index';
import MarkAllReadButton from '../atoms/MarkAllReadButton';
import NotificationTabButtons from './NotificationTabButtons';
import NotificationTabContainer from '../atoms/NotificationTabContainer';
import NotificationTabContent from './NotificationTabContent';
import useNotificationStore from '../../../../zustand/useNotificationStore';
import { AuthContext } from '../../../../App';
import {
  ALL_TAB_CLICK_EVENT, MARK_ALL_READ_CLICK_EVENT,
  NOTIFICATION_CLICK_EVENT, READ_TAB_CLICK_EVENT, UNREAD_TAB_CLICK_EVENT,
} from '../../../../utils/analytics/enums';
import { handleUpdateHubLog } from '../../../../utils/analytics/eventLogger';
import { useUI } from '../../../../pages/all-pages/ui-context/uiContext';

export interface InboxModalProps {
  isOpen: boolean,
  setModalClosed: () => void
}

function UpdateHubModal({ isOpen, setModalClosed }: InboxModalProps) {
  const { setPage } = useUI();
  const { userId } = useContext(AuthContext);
  const { unreadNotificationCount, unreadNotifications } = useNotificationStore();
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  const onNotificationClick = async (notification: Notification) => {
    handleUpdateHubLog(userId, NOTIFICATION_CLICK_EVENT);
    setPage('currentMeeting');
    navigate(makeMeetingUrl(notification.source.identifier1));
    notification.updateIsRead(true);
    setModalClosed();
  };

  const onMarkAllReadClick = async () => {
    handleUpdateHubLog(userId, MARK_ALL_READ_CLICK_EVENT);
    const promiseArr = unreadNotifications.map((notification) => notification.updateIsRead(true));
    await Promise.all(promiseArr);
  };

  const handleTabChange = (index: number) => {
    switch (index) {
      case 0:
        setTabIndex(0);
        handleUpdateHubLog(userId, ALL_TAB_CLICK_EVENT);
        break;
      case 1:
        setTabIndex(1);
        handleUpdateHubLog(userId, UNREAD_TAB_CLICK_EVENT);
        break;
      case 2:
        setTabIndex(2);
        handleUpdateHubLog(userId, READ_TAB_CLICK_EVENT);
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Updates"
      setModalClosed={setModalClosed}
      isTitleCenter={false}
      isScrollbarEnabled={false}
      isOverflowHidden
    >
      <NotificationTabContainer tabIndex={tabIndex} setTabIndex={handleTabChange}>
        <NotificationTabButtons tabIndex={tabIndex} setTabIndex={handleTabChange} />
        <MarkAllReadButton
          tabIndex={tabIndex}
          onClick={onMarkAllReadClick}
          unreadCount={unreadNotificationCount}
        />
        <NotificationTabContent tabIndex={tabIndex} onClick={onNotificationClick} />
      </NotificationTabContainer>
    </Modal>
  );
}
export default UpdateHubModal;
