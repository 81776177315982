import { logEvent } from './eventLogger';
import MixpanelBilling from './BillingAnalytics/MixpanelBilling';
import { IntercomTrackEvent } from '../../shared/types/types';

/**
 * For logging to Mixpanel. Made by Shepherd.
 */
class Mixpanel {
  static Billing = MixpanelBilling;

  static async log(
    userId: string,
    eventName: string,
    eventData: Object,
    intercomTrackEvent?: IntercomTrackEvent,
  ) {
    return logEvent(userId, eventName, eventData, intercomTrackEvent);
  }
}

export default Mixpanel;
