/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { COLORS } from '../../shared/colours';
import FONTS from '../../shared/typography/FontTypesMap';

const Container = styled.div`
  width: 100%;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  /* Reset styling for input */

  /* Remove default padding */
  padding: 10px 14px;

  /* Remove default border */
  border: 2px solid ${COLORS.darkBlue2};
  border-radius: 8px;
  /* color: ${COLORS.darkBlue2}; */

  /* Remove default background */

  /* Set height */
  /* height: 2.5rem; */
  ${FONTS.uiTextS}

  background-image: url("../../shared/icons/Search.png");
  background-position: 7px 7px;
  background-repeat: no-repeat;
  background-size: 20px 20px;

  /* focus */

  &:focus {
    border-color: ${COLORS.blue6};
  }
`;

interface SearchbarNotesProps {
  text: string,
  onTextChange: (text: string) => void;
}

const SearchbarNotes = ({ text, onTextChange }: SearchbarNotesProps) => {
  const [searchText, setSearchText] = useState<string>('');

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setSearchText(newText);
    onTextChange(newText);
  };

  useEffect(() => {
    ConsoleImproved.log('New text', searchText);
  }, [searchText]);

  return (
    <Container>
      <Input placeholder="Search your notes" value={text} onChange={handleSearchTextChange} />
    </Container>
  );
};

export default SearchbarNotes;
