/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import Notification from '../../../classes/Notification/Notification';
import {
  layoutGrey, gray9, gray7, COLORS,
} from '../../../colours';
import { uiTextMedium, uiTextS } from '../../../typography';
import ModalLineAtom from '../../quick-settings/notification/desktop-notification-settings/modal/ModalLineAtom';
import ReporterProfileIcon from '../atoms/ReporterProfileIcon';
import NotificationHeading from './NotificationHeading';
import NotificationSubHeading from './NotificationSubHeading';

interface Props{
  notifications: Notification[],
  // eslint-disable-next-line no-unused-vars
  onClick: (event: any, notification: Notification) => void;
  }

interface StyleProps{
  isRead: boolean
}

const NotificationItemContainer = styled.div<StyleProps>`
  display: flex;
  padding: 16px;
  cursor: pointer;
  .readIcon {
    display: none;
  }
  .unreadIcon {
    display: ${({ isRead }) => (isRead ? 'none' : 'flex')};
    border: 1px solid transparent;

    &:hover {
      background-color: ${COLORS.gray5};
    }
  }
  :hover .readIcon{
    display: ${({ isRead }) => (isRead ? 'inline' : 'none')};

    &:hover {
      path {
        fill: ${COLORS.blue6};
      }
    }
  }
  :hover{
    background-color: ${layoutGrey};

    .unreadIcon {
      border: 1px solid ${COLORS.gray5};
      border-radius: 50%;
    }
  }
`;

const NotificationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: -webkit-fill-available;
  & > div:nth-child(1) {
    ${uiTextMedium}
    color: ${gray9};
    max-height: 21px;
  }
  & > div:nth-child(2) {
    ${uiTextS}
    color: ${gray7};
  }
`;

const NotificationContainer = styled.div``;

function NotificationsList({ notifications, onClick }: Props) {
  return (
    <div>
      {notifications.map((notification) => (
        <NotificationContainer key={notification.db.documentId}>
          <NotificationItemContainer
            onClick={(event:any) => { onClick(event, notification); }}
            isRead={notification.read.isRead}
          >
            <ReporterProfileIcon user={notification.reporter} />
            <NotificationTextContainer>
              <NotificationHeading notification={notification} />
              <NotificationSubHeading notification={notification} />
            </NotificationTextContainer>
          </NotificationItemContainer>
          <ModalLineAtom />
        </NotificationContainer>
      ))}
    </div>
  );
}

export default NotificationsList;
