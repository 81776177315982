import React from 'react';
import styled from 'styled-components';
import { MeetingData } from '../../../types/types';
import { uiText } from '../../../typography';
import {
  gray1, gray7, surface, blue6, COLORS,
} from '../../../colours';
import Arrow from '../../../icons/ArrowIcon';
import { getDayAndMonth } from '../../../../utils/dateUtils/date';

interface ButtonContainerProps {
  isDropDownOpen?: boolean
}

const ButtonContainer = styled.div<ButtonContainerProps>`
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  padding: 8px;

  background-color: ${gray1};

  width: 190px;
  height: 40px;

  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.25);

  ${(props) => (props.isDropDownOpen ? `border: 2px ${blue6} solid` : 'border: 2px white solid')};
`;

const ButtonTextContainer = styled.span`
  ${uiText};
  color: ${COLORS.gray8};
  display: flex;
  align-items: center;
  height: 24px;
  -webkit-user-select: none;
  user-select: none;

  display: flex;
  justify-content: center;

  width: 100%;
  border-radius: 4px;
  cursor: 'pointer';

  &:hover {
    background-color: ${COLORS.gray6};
  }
`;

interface ArrowProps {
  disable?: boolean
}

const ArrowContainer = styled.button<ArrowProps>`
  all: unset;

  padding: 8px 12px;
  border-radius: 4px;

  &:hover {
    cursor: ${(props) => (props.disable ? 'default' : 'pointer')};
    background-color: ${(props) => (props.disable ? '' : COLORS.gray6)};
  }
`;

export interface PreviousMeetingDropdownButtonProps {
  previousMeetingList: MeetingData[],
  isDropDownOpen: boolean,
  currentMeetingIndex: number,
  /* eslint-disable-next-line no-unused-vars */
  handleLeftArrowClick: (event: any) => void,
  /* eslint-disable-next-line no-unused-vars */
  handleRightArrowClick: (event: any) => void,
  toggleDropDownView: () => void,
}

const PreviousMeetingDropdownButton = ({
  previousMeetingList,
  isDropDownOpen,
  toggleDropDownView,
  currentMeetingIndex,
  handleLeftArrowClick,
  handleRightArrowClick,
}: PreviousMeetingDropdownButtonProps) => {
  if (previousMeetingList.length === 0) return null;

  return (
    <ButtonContainer isDropDownOpen={isDropDownOpen}>
      <ArrowContainer
        onClick={(event) => { handleLeftArrowClick(event); }}
        disabled={currentMeetingIndex === previousMeetingList.length - 1}
        disable={currentMeetingIndex === previousMeetingList.length - 1}
      >
        <Arrow
          fill={currentMeetingIndex === previousMeetingList.length - 1 ? gray7 : surface}
          direction="left"
        />
      </ArrowContainer>
      <ButtonTextContainer onClick={toggleDropDownView}>
        {getDayAndMonth(previousMeetingList[currentMeetingIndex].date.start.date)}
      </ButtonTextContainer>
      <ArrowContainer
        onClick={(event) => { handleRightArrowClick(event); }}
        disabled={currentMeetingIndex === 0}
        disable={currentMeetingIndex === 0}
      >
        <Arrow
          fill={currentMeetingIndex === 0 ? gray7 : surface}
          direction="right"
        />
      </ArrowContainer>
    </ButtonContainer>
  );
};

export default PreviousMeetingDropdownButton;
