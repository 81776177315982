import { StripeConstants } from './StripeConstants';
import { StripeSubscription, StripeSubscriptionStatus } from './StripeTypes';

class StripeSubscriptionUtils {
  static mapDatabaseToValue = (databaseBillingSubscriptionData: any): StripeSubscription => ({
    status: databaseBillingSubscriptionData?.status ?? 'canceled',
    currentPeriodStart: databaseBillingSubscriptionData?.currentPeriodStart ?? '',
    currentPeriodEnd: databaseBillingSubscriptionData?.currentPeriodEnd ?? '',
    cancelAtPeriodEnd: databaseBillingSubscriptionData?.cancelAtPeriodEnd ?? false,
    canceledAt: databaseBillingSubscriptionData?.canceledAt ?? '',
    created: databaseBillingSubscriptionData?.created ?? '',
    daysUntilDue: databaseBillingSubscriptionData?.daysUntilDue ?? 0,
    defaultPaymentMethod: databaseBillingSubscriptionData?.defaultPaymentMethod ?? '',
    discount: databaseBillingSubscriptionData?.discount ?? '',
    latestInvoice: databaseBillingSubscriptionData?.latestInvoice ?? '',
    onBehalfOf: databaseBillingSubscriptionData?.onBehalfOf ?? '',
    startDate: databaseBillingSubscriptionData?.startDate ?? '',
    testClock: databaseBillingSubscriptionData?.testClock ?? '',
  })

  static isValidSubscriptionStatus = (
    statusCandidate: string,
  ): statusCandidate is StripeSubscriptionStatus => Object
    .values(StripeConstants.SubscriptionStatuses)
    .some((validStatus) => statusCandidate === validStatus);

  static getTrialExtensionPriceId = (isTest: boolean) => (isTest
    ? StripeConstants.TEST.PRICES.ONE_DOLLAR_FOR_TRIAL_EXTENSION
    : StripeConstants.PRICES.ONE_DOLLAR_FOR_TRIAL_EXTENSION);

  static getDefaultMonthlyPriceId = (isTest: boolean) => (isTest
    ? StripeConstants.TEST.PRICES.SEVEN_DOLLAR_PER_MONTH
    : StripeConstants.PRICES.SEVEN_DOLLAR_PER_MONTH);
}

export default StripeSubscriptionUtils;
