import { User } from '../../shared/types/types';
import { StripeCheckoutSessionOrigin } from '../Stripe/StripeTypes';
import SlackBoltCore from './SlackBoltCore';

class SlackBoltAPI extends SlackBoltCore {
  static listConversations = async (): Promise<any> => SlackBoltCore.coreListConversations()

  static logMessage = async (message: string): Promise<any> => SlackBoltCore.coreLogMessage(message)

  static logClickedTrialExtension = async (
    user: User,
    origin: StripeCheckoutSessionOrigin,
    origin2: 'trialEndedModal' | 'cancelPage' | 'quickSettings',
  ): Promise<any> => {
    const originWithCancel = `${origin}-${origin2}`;
    SlackBoltAPI.logMessage(`User ${user.data.name} (${user.data.email}) clicked trial extension - origin: ${originWithCancel} - userId: ${user.userId}`);
  }
}

export default SlackBoltAPI;
