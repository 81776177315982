import { captureException } from '@sentry/react';
import {
  MeetingRole, PublicUserData, SimpleUserData, SlackData,
} from '../../shared/types/types';
import { dateISOObject } from '../../utils/dateUtils/date';
import { firestore, functions } from '../../utils/firebase';
import { defaultTrelloData } from '../../utils/trello/trelloUtils';
import { mapDatabaseDataToUser, rejectedUser } from '../../utils/user/UserDataUtils';
import { DEFAULT_USER_SETTINGS, mapDatabaseUserSettingsToUserSettings } from '../../utils/user/UserSettingsUtils';
import UserAPI from './UserAPI';

/**
 * If we want information about a user that is not the current user, we can use this class.
 */
class UserOtherUsersAPI {
  static dbGetSimpleUserDataByUserId = async (userId: string, role: MeetingRole) => {
    const publicUserData = await UserAPI.OtherUsers.dbGetPublicUserDataByUserId(userId);
    const simpleUserData: SimpleUserData = {
      access: true,
      userId,
      role,
      name: publicUserData.name,
      email: publicUserData.email,
      date: {
        added: dateISOObject(),
        created: dateISOObject(),
        lastViewed: dateISOObject(),
      },
    };
    return simpleUserData;
  };

  // TODO: Remove?
  // Are we using this or should we use a Cloud Function?
  static dbGetPublicUserDataByUserId = async (userId: string) => {
    // TODO HARALD: Refactor + make mapping function
    const publicUserData: PublicUserData = await firestore()
      .collection('users')
      .doc(userId)
      .collection('public_user_data')
      .doc(userId)
      .get()
      .then((docRef) => ({
        name: docRef.data()?.name ?? '',
        email: docRef.data()?.email ?? '',
        userId,
        photoUrl: docRef.data()?.photoUrl ?? '',
        integrations: {
          slack: [{
            version: docRef.data()?.integrations?.slack[0]?.version ?? 1,
            userAccessToken: docRef.data()?.integrations?.slack[0]?.userAccessToken ?? '',
            botAccessToken: docRef.data()?.integrations?.slack[0]?.botAccessToken ?? '',
            userId: docRef.data()?.integrations?.slack[0]?.userId ?? '',
            defaultChannels: docRef.data()?.integrations?.slack[0]?.defaultChannels ?? [],
            notifications: {
              mentionedInNotes:
                docRef.data()?.integrations?.slack?.notifications?.mentionedInNotes ?? false,
              meetingStartsSoon:
                docRef.data()?.integrations?.slack?.notifications?.meetingStartsSoon ?? false,
              taskOverdue: docRef.data()?.integrations?.slack?.notifications?.taskOverdue ?? false,
              taskCreated: docRef.data()?.integrations?.slack?.notifications?.taskCreated ?? false,
              taskUpdated: docRef.data()?.integrations?.slack?.notifications?.taskUpdated ?? false,
              taskDeleted: docRef.data()?.integrations?.slack?.notifications?.taskDeleted ?? false,
            },
            date: {
              created: docRef.data()?.integrations?.slack[0]?.date?.created ?? dateISOObject(),
              updated: docRef.data()?.integrations?.slack[0]?.date?.created ?? dateISOObject(),
            },
          } as SlackData],
          notion: docRef.data()?.integrations?.notion ?? [],
          trello: docRef.data()?.integrations?.trello ?? defaultTrelloData,
          jira: {},
        },
      }))
      .catch((error) => {
        console.error(`Error in UserOtherUsersAPI.dbGetPublicUserDataByUserId ${error.message}`, { error, userId });
        captureException(error, { extra: { userId, functionName: 'UserOtherUsersAPI.dbGetPublicUserDataByUserId' } });
        return {
          version: 1,
          name: '',
          email: '',
          userId,
          photoUrl: '',
          integrations: {
            slack: [{
              version: 1,
              userAccessToken: '',
              botAccessToken: '',
              userId: '',
              defaultChannels: [],
              notifications: {
                meetingStartsSoon: false,
                mentionedInNotes: false,
                taskOverdue: false,
                taskCreated: false,
                taskUpdated: false,
                taskDeleted: false,
              },
              date: {
                created: dateISOObject(),
                updated: dateISOObject(),
              },
            } as SlackData],
            notion: [],
            trello: defaultTrelloData,
            jira: {},
          },
        };
      });
    return publicUserData;
  };

  static cfSearchUserSettingByEmail = async (email: string) => {
    try {
      const response = await functions()
        .httpsCallable('searchUserSettingByEmail')({ email });
      return mapDatabaseUserSettingsToUserSettings(response.data);
    } catch (error: any) {
      console.error(`Error in UserOtherUsersAPI.cfSearchUserSettingByEmail ${error.message}`, { error, email });
      captureException(error, { extra: { email, functionName: 'UserOtherUsersAPI.cfSearchUserSettingByEmail' } });
      return DEFAULT_USER_SETTINGS;
    }
  }

  static dbGetUserByUserId = async (userId: string) => {
    try {
      const docRef = await firestore()
        .collection('users')
        .doc(userId)
        .get();
      const user = mapDatabaseDataToUser(docRef.data(), userId);
      return user;
    } catch (error: any) {
      console.error(`Error in UserOtherUsersAPI.dbGetUserByUserId ${error.message}`, { error, userId });
      captureException(error, { extra: { userId, functionName: 'UserOtherUsersAPI.dbGetUserByUserId' } });
      return rejectedUser;
    }
  };
}

export default UserOtherUsersAPI;
