import { NOTIFICATION, NOT_SYNCED, SYNCED } from '../../../utils/enums';
import {
  BasicUser, NotificationActions, NotificationData,
  NotificationDatabase, NotificationAction,
  NotificationDatabaseStatus, NotificationHistoryEvent,
  NotificationSource, SDateT, NotificationContent,
} from '../../types/types';
import SDate from '../SDate';

/**
 * @constructor `(documentId, data)` => `Notification`
 * @method `getNotificationData()` => `NotificationData`
 * @method `getNotificationDatabase()` => `NotificationDatabase`
 */
class NotificationCore implements NotificationData {
  db: NotificationDatabaseStatus;

  version: 1;

  type: typeof NOTIFICATION;

  recipient: BasicUser;

  reporter: BasicUser;

  read: { isRead: boolean; date: SDateT; };

  dismissed: { isDismissed: boolean; date: SDateT; };

  created: SDateT;

  updated: SDateT;

  source: NotificationSource;

  trigger: { priority: string; date: SDateT; };

  content: { title: string; description1: string; description2: string; description3: string; };

  actions: NotificationActions;

  history: NotificationHistoryEvent[];

  constructor(documentId: string, rawDatabaseNotification: any) {
    const databaseNotification: NotificationDatabase = NotificationCore
      .mapDbDataToDatabaseNotification(
        rawDatabaseNotification,
      );
    const notification: NotificationData = {
      db: NotificationCore.mapDbDataToNotificationDatabaseStatus(documentId),
      ...databaseNotification,
    };

    this.db = notification.db;
    this.version = notification.version;
    this.type = notification.type;
    this.recipient = notification.recipient;
    this.reporter = notification.reporter;
    this.read = notification.read;
    this.dismissed = notification.dismissed;
    this.created = notification.created;
    this.updated = notification.updated;
    this.source = notification.source;
    this.trigger = notification.trigger;
    this.content = notification.content;
    this.actions = notification.actions;
    this.history = notification.history;
  }

  /** Generates a object of type NotificationData */
  get value(): NotificationData {
    const data: NotificationData = {
      db: this.db,
      version: this.version,
      type: this.type,
      recipient: this.recipient,
      reporter: this.reporter,
      read: this.read,
      dismissed: this.dismissed,
      created: this.created,
      updated: this.updated,
      source: this.source,
      trigger: this.trigger,
      content: this.content,
      actions: this.actions,
      history: this.history,
    };
    return data;
  }

  /** Generates a object of type NotificationDatabase */
  get getNotificationDatabase(): NotificationDatabase {
    const data: NotificationDatabase = {
      version: this.version,
      type: this.type,
      recipient: this.recipient,
      reporter: this.reporter,
      read: this.read,
      dismissed: this.dismissed,
      created: this.created,
      updated: this.updated,
      source: this.source,
      trigger: this.trigger,
      content: this.content,
      actions: this.actions,
      history: this.history,
    };
    return data;
  }

  static mapDbDataToDatabaseNotification = (databaseData: any) => {
    const data: NotificationDatabase = {
      version: databaseData.version ?? 1,
      type: databaseData.notification ?? NOTIFICATION,
      recipient: NotificationCore.mapDbDataToBasicUser(databaseData.recipient),
      reporter: NotificationCore.mapDbDataToBasicUser(databaseData.reporter),
      read: {
        isRead: databaseData.read.isRead ?? false,
        date: databaseData.read.date ?? SDate.empty,
      },
      dismissed: {
        isDismissed: databaseData.dismissed.isDismissed ?? false,
        date: databaseData.dismissed.date ?? SDate.empty,
      },
      created: databaseData.created ?? SDate.empty,
      updated: databaseData.updated ?? SDate.empty,
      source: NotificationCore.mapDbDataToNotificationSource(databaseData.source),
      trigger: {
        priority: databaseData.trigger.priority ?? '',
        date: databaseData.trigger.date ?? SDate.empty,
      },
      content: NotificationCore.mapDatabaseNotificationContentToNotificationContent(
        databaseData.content,
      ),
      actions: NotificationCore.mapNotificationActions(databaseData.actions),
      history: NotificationCore.mapNotificationHistory(databaseData.history),
    };
    return data;
  }

  static mapDbDataToNotificationDatabaseStatus = (documentId: string) => {
    const isIdPresent = documentId.length !== 0;
    const dbObj: NotificationDatabaseStatus = {
      state: isIdPresent ? SYNCED : NOT_SYNCED,
      lastUpdated: new SDate().value,
      documentId,
    };
    return dbObj;
  };

  static mapDbDataToBasicUser = (data: any) => {
    const userObj: BasicUser = {
      userId: data?.userId ?? '',
      name: data?.name ?? '',
      email: data?.email ?? '',
      photoUrl: data?.photoUrl ?? '',
    };
    return userObj;
  };

  static mapNotificationActions = (data: any) => {
    const actionsObj: NotificationActions = {
      primary: NotificationCore.mapNotificationAction(data.actions?.primary ?? {}),
      secondary: NotificationCore.mapNotificationAction(data.actions?.secondary ?? {}),
      tertiary: NotificationCore.mapNotificationAction(data.actions?.tertiary ?? {}),
    };
    return actionsObj;
  };

  static mapNotificationAction = (data: any) => {
    const actionObj: NotificationAction = {
      type: data.type ?? '',
      action: data.actions ?? '',
      field: data.field ?? '',
      description1: data.description1 ?? '',
      description2: data.description2 ?? '',
    };
    return actionObj;
  };

  static mapNotificationHistory = (data: any) => {
    const historyEvents = data.map((event: any) => {
      const eventObj: NotificationHistoryEvent = {
        type: {
          type: event.type.type ?? '',
          identifier1: event.type.identifier1 ?? '',
          identifier2: event.type.identifier2 ?? '',
        },
        date: event.date ?? SDate.empty,
        user: NotificationCore.mapDbDataToBasicUser(event.user),
        data: {
          title: event.data.title ?? '',
          description1: event.description1 ?? '',
        },
      };
      return eventObj;
    });
    return historyEvents;
  };

  static mapDbDataToNotificationSource = (data: any) => {
    const sourceObj: NotificationSource = {
      type: data.type ?? 'meeting',
      identifier1: data.identifier1 ?? '',
      identifier2: data.identifier2 ?? '',
      identifier3: data.identifier3 ?? '',
    };
    return sourceObj;
  };

  static mapDatabaseNotificationContentToNotificationContent = (data: any) => {
    const contentObj: NotificationContent = {
      title: data.title ?? '',
      description1: data.description1 ?? '',
      description2: data.description2 ?? '',
      description3: data.description3 ?? '',
    };
    return contentObj;
  };

  static generateNewNotification(
    recipient: BasicUser,
    reporter: BasicUser,
    notificationSource: NotificationSource,
  ) {
    const SDateObj = new SDate().value;
    const notificationObj: NotificationDatabase = {
      version: 1,
      type: 'notification',
      recipient,
      reporter,
      read: {
        isRead: false,
        date: SDate.empty,
      },
      dismissed: {
        isDismissed: false,
        date: SDate.empty,
      },
      created: SDateObj,
      updated: SDateObj,
      source: notificationSource,
      trigger: {
        priority: 'normal',
        date: SDate.empty,
      },
      content: NotificationCore.mapDatabaseNotificationContentToNotificationContent({}),
      actions: NotificationCore.mapNotificationActions({}),
      history: NotificationCore.mapNotificationHistory([]),
    };
    return notificationObj;
  }
}

export default NotificationCore;
