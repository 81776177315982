import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { StripeConstants } from '../../external/Stripe/StripeConstants';
import { StripeCheckoutSessionOrigin } from '../../external/Stripe/StripeTypes';
import StripeUtils from '../../external/Stripe/StripeUtils';
import ROUTES from '../../routes';
import JustHeaderContainer from '../../shared/atomic_design/templates/JustHeaderContainer';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import useGetQueryParam from '../../utils/hook/useGetParams';
import SuccessfulPaymentModalOrg from '../all-pages/Billing/SuccesfulPayment/SuccessfulPaymentModalOrg';
import BillingModalWatchDemoOrganism from '../all-pages/Billing/WatchDemoModal/BillingModalWatchDemoOrg';

const SuccessfullPaymentPageCtrl = () => {
  const navigate = useNavigate();
  const [origin, setOrigin] = useState<StripeCheckoutSessionOrigin | null>(null);
  const [state, setState] = useState<'Initial' | 'WatchDemo'>('Initial');

  useEffect(() => {
    const newOrigin = useGetQueryParam(StripeConstants.ORIGIN_URL_PARAM_PATH);
    ConsoleImproved.log(StripeConstants.ORIGIN_URL_PARAM_PATH, newOrigin);
    if (!StripeUtils.isValidStripeCheckoutOrigin(newOrigin)) return;
    setOrigin(newOrigin);
  }, []);

  const handleContinueClick = () => {
    console.log('handleContinueClick');
    // TODO:
    // 1. If in onboarding, redirect to onboarding
    // 2. If existing user, redirect to all notes
    console.log('origin', origin);
    if (origin === StripeConstants.CHECKOUT_ORIGINS.DuringOnboarding) {
      navigate(ROUTES.onboardingFirstMeetingPath);
      return;
    }
    navigate(ROUTES.allNotes);
  };

  const handleContinueOnboarding = () => {
    console.log('handleContinueOnboarding');
    navigate(ROUTES.onboardingFirstMeetingPath);
  };

  const handleClickClose = async () => {
    if (origin === StripeConstants.CHECKOUT_ORIGINS.DuringOnboarding) {
      handleContinueOnboarding();
      return;
    }
    handleContinueClick();
  };

  const handleClickWatchDemo = async () => {
    setState('WatchDemo');
  };

  return (
    <JustHeaderContainer showHeader={false}>
      <SuccessfulPaymentModalOrg
        isOpen={state === 'Initial'}
        isTrialExtension={origin === StripeConstants.CHECKOUT_ORIGINS.TrialExtension}
        onClickClose={handleClickClose}
        onClickWatchDemo={handleClickWatchDemo}
      />
      <BillingModalWatchDemoOrganism
        isOpen={state === 'WatchDemo'}
        onClickBack={() => setState('Initial')}
      />
      {/* <Container>
        <Font font="header700">Congratulations!</Font>
        <br />
        <Font font="defaultText">You have made the best decision of today
         by subscribing to Shepherd!  We are pumped having you with us :D</Font>
        {origin === StripeConstants.CHECKOUT_ORIGINS.DuringOnboarding ? (
          <ButtonSmall text="Continue using Shepherd" onClick={handleContinueOnboarding} />
        ) : (
          <ButtonSmall text="Continue using Shepherd" onClick={handleContinueClick} />
        )}
        {origin && (
          <>
            <br />
            <br />
            <Font font="header300">
              Origin:
              {' '}
              {origin}
            </Font>
          </>
        )}
      </Container> */}
    </JustHeaderContainer>
  );
};

export default SuccessfullPaymentPageCtrl;
