import { useContext } from 'react';
import PreviousMeetingDataContext from '../../shared/components/previous-meeting-notes/context/PreviousMeetingDataContext';
import { MeetingData } from '../../shared/types/types';
import { REJECTED } from '../enums';

interface Props {
  previousMeetingData: MeetingData,
  isInPreviousMeeting: boolean,
}

const usePreviousMeetingData = (): Props => {
  const meetingData = useContext(PreviousMeetingDataContext);
  if (meetingData.resolvedState === REJECTED) {
    return {
      previousMeetingData: meetingData,
      isInPreviousMeeting: false,
    };
  }

  return {
    previousMeetingData: meetingData,
    isInPreviousMeeting: true,
  };
};

export default usePreviousMeetingData;
