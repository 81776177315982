import uniqid from 'uniqid';
import { AuthState, User } from '../../shared/types/types';
import { firestore } from '../../utils/firebase';
import { COLLECTIONS, USER_PATH } from '../FirebaseConstants';
import SentryAPI from '../../utils/analytics/SentryAPI';

class UserMetaAPI {
  static dbUpdatePrivateIdIfNotUpdated = (user: User, authState: AuthState) => {
    const { privateId } = user.meta;
    if (privateId.length > 5) return;
    console.log('Private id is not set. Setting now');
    UserMetaAPI.dbPopulateUserPrivateId(authState);
  };

  static dbPopulateUserPrivateId = (authState: AuthState) => {
    const privateId = uniqid(); // I think this is 8 character long
    const newPrivateIdField = { [USER_PATH.meta.privateId]: privateId };
    console.log(newPrivateIdField);

    firestore()
      .collection(COLLECTIONS.USERS)
      .doc(authState.userId)
      .update(newPrivateIdField)
      .then(() => {
        console.log('privateId field successfully updated for user');
      })
      .catch((err) => {
        SentryAPI.captureExceptionAndConsoleError('UserMetaAPI.dbPopulateUserPrivateId', err, authState);
      });
  };
}

export default UserMetaAPI;
