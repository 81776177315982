import React, { useContext, useEffect, useState } from 'react';
import Modal from '../../../shared/components/Modal';
import Scrollbar from '../../../shared/components/scrollbar';
import ButtonSmall from '../../../shared/components/button-small';
import ModalBodyWrapper from '../../../shared/components/Modal/ModalBodyWrapper';
import ModalFooterButtonsRightAlignedWrapper from '../../../shared/components/Modal/ModalFooterButtonsRightAlignedWrapper';
import Signature from './EmailSignature';
import { uiText } from '../../../shared/typography';
import MeetingDataContext from '../context/MeetingDataContext';
import { TaskItem } from '../../../shared/types/types';
import { AuthContext } from '../../../App';
import { makeSubstringsBold } from '../../../utils/strings';
import { getUTCString } from '../../../utils/dateUtils/date';
import { NotesData, NotesSwitchData } from './ShareModal/utils/types';
import { assembleEmailHTML } from '../../../external/Email/emailUtils';

export interface PreviewEmailModalProps {
  isModalOpen: boolean,
  loading: boolean,
  onBackClick: () => void,
  onSendClick: () => void,
  notes: NotesData,
  notesSwitchData: NotesSwitchData,
  customMessage: string,
  taskItems: TaskItem[],
}

const PreviewEmailModal = ({
  isModalOpen,
  loading,
  onBackClick,
  onSendClick,
  notes,
  notesSwitchData,
  customMessage,
  taskItems,
}: PreviewEmailModalProps) => {
  const {
    isSharedNotesChecked,
    isPersonalNotesChecked,
    isTasksChecked,
  } = notesSwitchData;
  const [emailHtml, setEmailHtml] = useState<string>('');

  const {
    meetingId, data: { title }, date: { start },
  } = useContext(MeetingDataContext);
  const { firstName, lastName } = useContext(AuthContext);

  const wrapStyle = (html: string) => `<div style="${uiText}">${html}</div>`;

  useEffect(() => {
    if (!meetingId) return;

    const emailHeadHtml = makeSubstringsBold(customMessage, [title, getUTCString(start.date)]);

    const notesContentHtml = assembleEmailHTML(
      isSharedNotesChecked, isPersonalNotesChecked, isTasksChecked,
      emailHeadHtml, notes.shared, notes.my, taskItems,
    );

    setEmailHtml(wrapStyle(notesContentHtml + Signature(firstName, lastName)));
  }, [isModalOpen, customMessage, isSharedNotesChecked, isPersonalNotesChecked, meetingId]);

  return (
    <>
      <Modal isOpen={isModalOpen} setModalClosed={onBackClick} title="Preview before sharing">
        <Scrollbar maxHeight="60vh">
          <ModalBodyWrapper>
            <div dangerouslySetInnerHTML={{ __html: emailHtml }} />
          </ModalBodyWrapper>
        </Scrollbar>
        <ModalFooterButtonsRightAlignedWrapper>
          <ButtonSmall onClick={onBackClick} text="Back" isOutline />
          <ButtonSmall onClick={onSendClick} text="Send" loading={loading} />
        </ModalFooterButtonsRightAlignedWrapper>
      </Modal>
    </>
  );
};

export default PreviewEmailModal;
