import React from 'react';
import styled from 'styled-components';
import { header400, defaultSmall } from '../../../shared/typography';
import Modal from '../../../shared/components/Modal';
import { gray1, gray7, surface } from '../../../shared/colours';
import { ModalLineLocal } from './ShareNotesModal';
import ButtonSmall from '../../../shared/components/button-small';

const Container = styled.div`
  width: 425px;
  background-color:${gray1};
  padding: 16px;
  border-radius: 10px;
  box-sizing: unset !important;

  #helpIconModalLine {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const HeadText = styled.div`
  ${header400}
  color: ${surface};
`;

const SubText = styled.div`
  ${defaultSmall}
  color: ${gray7};
  margin: 8px 0px 16px 0px;
`;

const ImageContainer = styled.img`
  border-radius: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 24px 0 24px;
`;

interface Props {
  isOpen: boolean,
  setModalClosed: () => void,
  headText?: string,
  subText?: string,
  imgSrc?: string,
}

export default function HelpIconInfoModal({
  isOpen, setModalClosed, headText, subText, imgSrc,
}: Props) {
  const handleBack = () => {
    setModalClosed();
  };

  return (
    <Modal isOpen={isOpen} setModalClosed={setModalClosed} isHeadless>
      <Container>
        <HeadText>{headText}</HeadText>
        <SubText>{subText}</SubText>
        <ImageContainer src={imgSrc} height={200} width={400} />
        <ModalLineLocal id="helpIconModalLine" />
        <ButtonRow>
          <ButtonSmall onClick={handleBack} text="Back" isOutline customWidth="50%" />
        </ButtonRow>
      </Container>
    </Modal>
  );
}

HelpIconInfoModal.defaultProps = {
  headText: '',
  subText: '',
  imgSrc: '',
};
