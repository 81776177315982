import { MeetingSection, TaskItem } from '../../shared/types/types';
import { ALL_PAGES, MEETING_SECTION } from '../enums';

export const QueryParameters = {
  PAGE: 'page',
  TASK_TAB: 'taskTab',
  MEETING_SECTION: 'meetingSection',
  REDIRECTO_TO_UPGRADE: 'redirectToUpgrade',
  OPEN_INVITE_USERS_MODAL: 'openInviteUsersModal',
};

class QueryUtils {
  /**
   * @returns `page=currentMeeting`
   */
  static CURRENT_MEETING = `${QueryParameters.PAGE}=${ALL_PAGES.CURRENT_MEETING}`

  static taskTabParameter = (taskItem: TaskItem) => {
    const { status } = taskItem.data;
    return `${QueryParameters.TASK_TAB}=${status}`;
  }

  static isValidMeetingSection = (
    candidate: string,
  ): candidate is MeetingSection => Object
    .values(MEETING_SECTION).some((meetingSection) => meetingSection === candidate);
}

export default QueryUtils;
