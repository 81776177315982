import { mapOnboardingFormValuesToDatabaseUserOnboardingFormat } from '../../pages/onboarding/personal/utils/functions';
import { PersonalOnboardingData } from '../../pages/onboarding/personal/utils/types';
import { AuthState, ResolvedState } from '../../shared/types/types';
import { REJECTED } from '../../utils/enums';
import { firestore } from '../../utils/firebase';
import { mapDatabaseDataToUser, UserUtils } from '../../utils/user/UserDataUtils';
import UserAPICore from './UserAPICore';
import UserSettingsAPI from './UserSettingsAPI';
import UserPermissionsAPI from './UserPermissionsAPI';
import UserDataAPI from './UserDataAPI';
import UserBillingAPI from './UserBillingAPI';
import UserMetaAPI from './UserMetaAPI';
import UserOtherUsersAPI from './UserOtherUsersAPI';
import UserFriendListAPI from './UserFriendListAPI';
import UserGeneralAPI from './UserGeneralAPI';
import UserIntegrationsAPI from './UserIntegrationsAPI';
import { COLLECTIONS } from '../FirebaseConstants';
import KnownUsersUtils from '../../utils/user/KnownUsers.ts/KnownUsersUtils';
import SentryAPI from '../../utils/analytics/SentryAPI';

/**
 * Responsibilities:
 * - Anything to do with the user document in Firestore
 * - Updating the object
 * - Reading the object or checking some property
 *
 * Not responsible for:
 * - Any third party API calls, i.e. Slack, Notion, etc.
 */
class UserAPI extends UserAPICore {
  /**
   * For functions related to the entire User Object
   */
  static General = UserGeneralAPI;

  /** To interact with the settings of a user */
  static Settings = UserSettingsAPI;

  static Billing = UserBillingAPI;

  static Permissions = UserPermissionsAPI;

  static Data = UserDataAPI;

  static OtherUsers = UserOtherUsersAPI;

  static Meta = UserMetaAPI;

  static FriendList = UserFriendListAPI;

  static Integrations = UserIntegrationsAPI;

  // TODO: Try to remove this function as we rather want very specific
  // update functions. And we dont want optional logMessage
  static updateUser = async (
    userId: string, updates: object, logMessage: string = '',
  ) => UserAPICore.updateUser(userId, updates, logMessage);

  // TODO: Check if this function can be misused.
  // Maybe create specific functions for different updates
  static dbUserUpdateInfo = (
    userId: string, updates: any,
  ): Promise<ResolvedState> => firestore()
    .collection(COLLECTIONS.USERS)
    .doc(userId)
    .update(updates)
    .then(() => 'resolved' as ResolvedState)
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('dbUserUpdateInfo', error, userId, updates);
      return REJECTED;
    });

  static dbUpdateUserOnboardingData = async (
    onboardingValues: PersonalOnboardingData,
    authState: AuthState,
    logAnalytics: () => void,
    /* eslint-disable-next-line */
    setUserOnboardingPropertiesInMixpanel: (values: PersonalOnboardingData) => void,
  ) => {
    const dbOnboardingObj = mapOnboardingFormValuesToDatabaseUserOnboardingFormat(onboardingValues);
    const docRef = firestore().collection(COLLECTIONS.USERS).doc(authState.userId);

    return firestore().runTransaction((transaction) => transaction.get(docRef)
      .then(async (doc) => {
        if (!doc.exists) throw new Error('User document does not exist');

        const userData = mapDatabaseDataToUser(doc.data(), doc.id);
        const { hasOnboarded } = userData.data;

        if (!UserUtils.isInitialUserDataSet(userData)) {
          console.log('User has not initial user data set');
          throw new Error('Transaction failed, user has not set initial data');
        }

        // Known users can go through the onboarding multiple times
        if (hasOnboarded && !KnownUsersUtils.isKnownUser(authState.userId)) {
          console.log('User has already onboarded');
          throw new Error('Transaction failed, user has already onboarded');
        }
        transaction.update(docRef, dbOnboardingObj);
      }))
      .then(() => {
        console.log('Successfully saved onboarding data');
        logAnalytics();
        setUserOnboardingPropertiesInMixpanel(onboardingValues);
        return 'resolved' as ResolvedState;
      }).catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('dbUpdateUserOnboardingData', error, onboardingValues, authState);
        return 'rejected' as ResolvedState;
      });
  };
}

export default UserAPI;
