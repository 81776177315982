import React, { useState } from 'react';
import styled from 'styled-components';
import { darkBlue3, blue6 } from '../../shared/colours';
import InfoQuestionIcon from '../../shared/icons/infoQuestion';

const Container = styled.div`
  display: contents;
`;
interface Props {
  onClick?: () => void;
}

const HelpIconAtom = ({ onClick }: Props) => {
  const [color, setColor] = useState(darkBlue3);
  const mouseEnter = () => setColor(blue6);
  const mouseLeave = () => setColor(darkBlue3);

  return (
    <Container onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={onClick}>
      <InfoQuestionIcon size={20} color={color} />
    </Container>
  );
};

HelpIconAtom.defaultProps = {
  onClick: () => { },
};

export default HelpIconAtom;
