import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom-v5-compat';
import { AuthContext } from '../../../../App';
import { MeetingData, AuthState } from '../../../types/types';
import dbListenToMeetingData, { dbGetPreviousMeetingsByRecurringEventId } from '../../../../database/Meetings/firebaseMeetingAPI';
import { pendingMeetingData } from '../../../../database/utils/templateMeetingData';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import PreviousMeetingDropdownButton from './DropDownButton';
import PreviousMeetingDropDownOptions from './DropDownOptions';

const Container = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;

  z-index: 4;
`;
interface Props {
  meetingId: string
  previousMeetingId: string
}

const PreviousMeetingDropDown = ({ meetingId, previousMeetingId }: Props) => {
  const authState = useContext<AuthState>(AuthContext);
  const navigate = useNavigate();

  const [currentMeetingData, setCurrentMeetingData] = useState<MeetingData>(pendingMeetingData);
  const [previousMeetingList, setPreviousMeetingList] = useState<Array<MeetingData>>([]);
  const [currentMeetingIndex, setCurrentMeetingIndex] = useState<number>(0);

  const dropDownRef = useRef(null);
  const [isDropDownOpen, setIsDropDownOpen] = useDetectOutsideClick(dropDownRef, false);

  useEffect(() => {
    if (meetingId) {
      return dbListenToMeetingData(
        meetingId, authState.userId, setCurrentMeetingData, () => { },
      );
    }

    return () => { };
  }, [meetingId]);

  useEffect(() => {
    const { recurringEventId } = currentMeetingData.googleData.ids;
    const currentStartTime = currentMeetingData.date.start.timestamp;
    if (recurringEventId !== '' && currentStartTime) {
      dbGetPreviousMeetingsByRecurringEventId(recurringEventId, currentStartTime, authState.email)
        .then((previousMeetings: MeetingData[]) => {
          if (previousMeetings.length === 0) return;

          setPreviousMeetingList(previousMeetings);
        });
    }
  }, [currentMeetingData.meetingId]);

  useEffect(() => {
    if (previousMeetingList.length > 1) {
      const index = previousMeetingList.findIndex((
        previousMeeting: MeetingData,
      ) => previousMeeting.meetingId === previousMeetingId);

      if (index === -1) {
        setCurrentMeetingIndex(0);
        return;
      }

      setCurrentMeetingIndex(index);
    }
  }, [previousMeetingList]);

  const handleRightArrowClick = (event: any) => {
    event.stopPropagation();
    if (currentMeetingIndex === 0) {
      return;
    }

    const newRouteURL = `/meeting/${currentMeetingData.meetingId}/previousMeeting/${previousMeetingList[currentMeetingIndex - 1].meetingId}`;

    navigate(newRouteURL);

    setCurrentMeetingIndex(currentMeetingIndex - 1);
    setIsDropDownOpen(false);
  };

  const handleLeftArrowClick = (event: any) => {
    event.stopPropagation();
    if (currentMeetingIndex === previousMeetingList.length - 1) {
      return;
    }

    const newRouteURL = `/meeting/${currentMeetingData.meetingId}/previousMeeting/${previousMeetingList[currentMeetingIndex + 1].meetingId}`;

    navigate(newRouteURL);

    setCurrentMeetingIndex(currentMeetingIndex + 1);
    setIsDropDownOpen(false);
  };

  const toggleDropDownView = () => setIsDropDownOpen(!isDropDownOpen);

  if (previousMeetingList.length > 1) {
    return (
      <Container>
        <PreviousMeetingDropDownOptions
          currentMeetingId={meetingId}
          previousMeetingList={previousMeetingList}
          isDropDownOpen={isDropDownOpen}
          setIsDropDownOpen={setIsDropDownOpen}
          dropDownRef={dropDownRef}
          setCurrentMeetingIndex={setCurrentMeetingIndex}
        />
        <PreviousMeetingDropdownButton
          previousMeetingList={previousMeetingList}
          isDropDownOpen={isDropDownOpen}
          currentMeetingIndex={currentMeetingIndex}
          handleLeftArrowClick={handleLeftArrowClick}
          handleRightArrowClick={handleRightArrowClick}
          toggleDropDownView={toggleDropDownView}
        />
      </Container>
    );
  }

  return null;
};

export default PreviousMeetingDropDown;
