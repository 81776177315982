import { Dispatch, SetStateAction } from 'react';
import UserAPI from '../../../../database/User/UserAPI';
import { AuthState, ResolvedState } from '../../../../shared/types/types';
import setUserOnboardingPropertiesInMixpanel from '../../../../utils/mixpanel/functions';
import { DropdownOption, PersonalOnboardingData, UserDatabaseOnboardingData } from './types';

export const sortArrayAlphabetically = (input: DropdownOption[]) => {
  const sorted = input.sort((a: DropdownOption, b: DropdownOption) => {
    if (a.value === 'Other') return 1;
    if (b.value === 'Other') return -1;

    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  });

  return sorted;
};

export const mapOnboardingFormValuesToDatabaseUserOnboardingFormat = (
  formValues: PersonalOnboardingData,
) => {
  const dbOnboardingObj: UserDatabaseOnboardingData = {
    'data.firstName': formValues.firstName ?? '',
    'data.lastName': formValues.lastName ?? '',
    'data.name': `${formValues.firstName} ${formValues.lastName}` ?? '',
    'data.hasOnboarded': true,
    'data.onboarding.personalShepherdUsage': formValues.useShepherd ?? '',
    'data.onboarding.jobType': formValues.jobType ?? '',
    'data.onboarding.companySize': formValues.companySize ?? '',
    'data.onboarding.productivityTool': formValues.productivityTool ?? [],
    'data.onboarding.remotePolicy': formValues.remotePolicy ?? '',
  };

  return dbOnboardingObj;
};

export const dbUpdateUserDataWithOnboardingDataAndSendWelcomeEmail = async (
  authState: AuthState,
  onboardingValues: PersonalOnboardingData,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  logAnalytics: () => void,
): Promise<ResolvedState> => {
  setIsLoading(true);

  const resolvedState = await UserAPI.dbUpdateUserOnboardingData(
    onboardingValues,
    authState,
    logAnalytics,
    setUserOnboardingPropertiesInMixpanel,
  );

  setIsLoading(false);
  return resolvedState;
};
