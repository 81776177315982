/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { captureException, captureMessage } from '@sentry/react';
import { Dispatch, SetStateAction } from 'react';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import EmailValidator from '../../shared/classes/EmailValidator';
import SDate from '../../shared/classes/SDate';
import { MeetingData, MeetingsObject } from '../../shared/types/types';
import SentryAPI from '../../utils/analytics/SentryAPI';
import firebase, { firestore, functions } from '../../utils/firebase';
import { COLLECTIONS, MEETING_PATH } from '../FirebaseConstants';
import mapDatabaseMeetingDataToMeetingData from '../utils/mapMeetingData';
import { rejectedMeetingData } from '../utils/templateMeetingData';

export type DocType = firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>

abstract class FirestoreMeetingsCore {
  protected static getMeetingDataByMeetingIdCore = async (
    meetingId: string,
  ): Promise<MeetingData> => {
    const doc = await firestore()
      .collection(COLLECTIONS.MEETINGS)
      .doc(meetingId)
      .get();
    if (!doc.exists) {
      ConsoleImproved.log('No such document!', { meetingId });
      return rejectedMeetingData;
    }
    const meetingData = mapDatabaseMeetingDataToMeetingData(doc.id, doc.data(), '');
    return meetingData;
  }

  /**
   * Listens to meetings by `calendarId` in period three weeks ago and
   * until two weeks in the future
   *
   * Sets meetings using `setMeetings` with the key being the calendarId and values
   * being an array of meetings
   */
  protected static listenAndSetMeetingsByCalendarIdCore = (
    calendarId: string,
    userId: string,
    setMeetings: Dispatch<SetStateAction<MeetingsObject>>,
  ): (
    ) => void => {
    const sixWeeksAgoDate = SDate.getDateXWeeksAgo(6).toISOString();

    return firestore()
      .collection(COLLECTIONS.MEETINGS)
      .where(MEETING_PATH.googleData.ids.calendarId, '==', calendarId)
      // I only got the .where to work when using an ISO string
      .where(MEETING_PATH.date.start.date, '>=', sixWeeksAgoDate)
      .limit(300) // Just in case there are very many meetings
      .onSnapshot((snapshot: DocType) => {
        const meetings: MeetingData[] = snapshot.docs
          .map((doc) => mapDatabaseMeetingDataToMeetingData(doc.id, doc.data(), userId));
        if (meetings.length === 0) return;

        setMeetings((prevMeetings) => ({
          ...prevMeetings,
          [calendarId]: meetings,
        }));
      });
  }

  /**
   * Listens to meetings where the user is an attendee
   *
   * Sets meetings using `setMeetings` with the key being "attendees" and values
   * being an array of meetings
   */
  protected static listenToMeetingsWhereImInvitedToCore = (
    userId: string,
    userEmail: string,
    setMeetings: Dispatch<SetStateAction<MeetingsObject>>,
  ): (
    ) => void => {
    const sixWeeksAgoDate = SDate.getDateXWeeksAgo(6).toISOString();

    const attendeePath = new firebase.firestore.FieldPath('googleData', 'attendees');

    return firestore()
      .collection(COLLECTIONS.MEETINGS)
      .where(attendeePath, 'array-contains', userEmail)
      .where(MEETING_PATH.date.start.date, '>=', sixWeeksAgoDate)
      .orderBy(MEETING_PATH.date.start.date, 'desc')
      .limit(300) // Just in case there are very many meetings
      .onSnapshot((snapshot: DocType) => {
        const meetings: MeetingData[] = snapshot.docs
          .map((doc) => mapDatabaseMeetingDataToMeetingData(doc.id, doc.data(), userId));
        ConsoleImproved.log('meetings in listenToMeetingsWhereImInvitedToCore', { meetings, userId, userEmail });
        if (meetings.length === 0) return;

        setMeetings((prevMeetings) => ({
          ...prevMeetings,
          attendees: meetings,
        }));
      });
  }

  static updateDocumentsWithNewAttendees = async (
    snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
  ) => {
    const batch = firestore().batch();

    snapshot.forEach((doc) => {
      const meetingData = mapDatabaseMeetingDataToMeetingData(doc.id, doc.data(), '');
      if (meetingData.data.attendees.length === 0) return;

      const attendeeEmails = meetingData.data.attendees
        .map((attendee) => attendee.email ?? '')
        .filter((email) => EmailValidator.validate(email,
          FirestoreMeetingsCore.updateDocumentsWithNewAttendees.name));

      batch.update(doc.ref, { [MEETING_PATH.googleData.attendees]: attendeeEmails });
    });

    // ConsoleImproved.log('Batch status', batch);

    // return await batch.commit().then(() => {
    //   ConsoleImproved.log('Batch commit success');
    // }).catch((error) => {
    //   console.error('Batch commit error', error);
    // });
  };

  static paginateMeetingsByCalendarIdCore = async (
    // eslint-disable-next-line no-unused-vars
    calendarId: string,
  ) => {
    // eslint-disable-next-line no-unused-vars
    const mapFx = (data: any) => ({ title: data?.data?.title, created: data?.date?.created?.date });
    // eslint-disable-next-line no-unused-vars

    const pageSize = 500;

    const hei = 'hei';
    // const first = await firestore()
    // .collection(COLLECTIONS.MEETINGS)
    // .where(MEETING_PATH.googleData.ids.calendarId, '==', calendarId)
    // .orderBy(MEETING_PATH.date.start.date, 'asc')
    // .limit(pageSize)
    // .get();

    const first = await firestore()
      .collection(COLLECTIONS.MEETINGS)
      // .where(MEETING_PATH.googleData.ids.calendarId, '==', calendarId)
      // .orderBy(MEETING_PATH.date.start.date, 'asc')
      .where(MEETING_PATH.data.attendees, '!=', [])
      .limit(pageSize)
      .get();

    // FirestoreMeetingsCore.updateDocumentsWithNewAttendees(first);

    // Loop over all documents by using the last document as the start point
    // for the next query
    let lastVisible = first.docs[first.docs.length - 1];
    let meetings: MeetingData[] = first.docs
      .map((doc) => mapDatabaseMeetingDataToMeetingData(doc.id, doc.data(), hei));
    ConsoleImproved.log('Meetings in paginateMeetingsByCalendarIdCore', meetings);
    let counter = pageSize;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (counter >= 100) {
        break;
      }
      // eslint-disable-next-line no-await-in-loop
      const next = await firestore()
        .collection(COLLECTIONS.MEETINGS)
        // .where(MEETING_PATH.googleData.ids.calendarId, '==', calendarId)
        // .orderBy(MEETING_PATH.date.start.date, 'asc')
        .where(MEETING_PATH.data.attendees, '!=', [])
        .startAfter(lastVisible)
        .limit(pageSize)
        .get();

      // If there are no more documents, we are done
      if (next.empty) {
        break;
      }

      // Update meetings with batch update
      // FirestoreMeetingsCore.updateDocumentsWithNewAttendees(next);

      lastVisible = next.docs[next.docs.length - 1];
      counter += next.docs.length;
      ConsoleImproved.log('Counter', counter);
    }

    // ConsoleImproved.log('All meetings', meetings);
    ConsoleImproved.log('Finished');

    // ConsoleImproved.log('First documents', first.docs.map((doc) => mapFx(doc.data())));
  }

  static updateAttendeesCore = async (
    meetingId: string,
    attendees: string[],
  ) => firestore()
    .collection(COLLECTIONS.MEETINGS)
    .doc(meetingId)
    .update({
      [MEETING_PATH.googleData.attendees]: attendees,
    })
    .then(() => {
      ConsoleImproved.log('Attendees updated');
    })
    .catch((error) => {
      console.error('Error updating meeting with attendees', { error, meetingId, attendees });
      console.error(`Error in updateAttendeesCore ${error.message}`, { error, meetingId, attendees });
      captureException(error, { extra: { meetingId, attendees, functionName: 'updateAttendeesCore' } });
    });

  protected static updateMeeting = async (
    meetingId: string,
    updateObject: object,
  ) => firestore()
    .collection(COLLECTIONS.MEETINGS)
    .doc(meetingId)
    .update(updateObject)
    .then(() => {
      ConsoleImproved.log('Updated Meeting with id ', meetingId);
      console.log(updateObject);
    })
    .catch((error) => {
      console.error('Error updating meeting', { error, meetingId, updateObject });
    });

  static listenToMyTagsCore = async (tag: string) => {
    const sixWeeksAgoDate = SDate.getDateXWeeksAgo(6).toISOString();

    ConsoleImproved.log('ListenToMeetingWhere');

    return firestore()
      .collection(COLLECTIONS.MEETINGS)
      .where('permissions.tags', 'array-contains', tag)
      // .where(MEETING_PATH.date.start.date, '>=', sixWeeksAgoDate)
      .orderBy(MEETING_PATH.date.start.date, 'desc')
      .limit(300) // Just in case there are very many meetings
      .onSnapshot((snapshot: DocType) => {
        const meetings: MeetingData[] = snapshot.docs
          .map((doc) => mapDatabaseMeetingDataToMeetingData(doc.id, doc.data(), ''));
        ConsoleImproved.log('Got new meeting in listenToMyTagsCore', { meetings });
      });
  }

  // eslint-disable-next-line arrow-body-style
  static listenToMeetingsByTagIdCore = async (tagId: string) => {
    // const sixWeeksAgoDate = SDate.getDateXWeeksAgo(6).toISOString();
    return firestore()
      .collection(COLLECTIONS.MEETINGS)
      .where('permissions.tags', 'array-contains', tagId)
      // .where(MEETING_PATH.date.start.date, '>=', sixWeeksAgoDate)
      .orderBy(MEETING_PATH.date.start.date, 'desc')
      .limit(300) // Just in case there are very many meetings
      .onSnapshot((snapshot: DocType) => {
        const meetings: MeetingData[] = snapshot.docs
          .map((doc) => mapDatabaseMeetingDataToMeetingData(doc.id, doc.data(), ''));
        ConsoleImproved.log('Got new meetings in listenToMeetingsByTagIdCore', { meetings });
      });
  }

  protected static getMeetingByDataEventIdCore = async (dataEventId: string) => functions()
    .httpsCallable('getMeetingsByDataEventIds')({ dataEventId })
    .then((response) => response.data)
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('getMeetingByDataEventIdCore', error, dataEventId);
    });

  protected static coreGetMeetingsByDataEventIds = async (dataEventIds: string[]) => {
    try {
      const result = await firebase.app()
        .functions('europe-west1')
        .httpsCallable('getMeetingsByDataEventIdsV2')({ dataEventIds })
        .then((response) => response.data);

      return result;
    } catch (error: any) {
      SentryAPI.captureExceptionAndConsoleError('coreGetMeetingsByDataEventIds', error, dataEventIds);
      return [];
    }
  }
}

export default FirestoreMeetingsCore;
