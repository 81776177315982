import React from 'react';
import styled from 'styled-components';
import Font from '../../../../typography/Font';
import DaysLeftInTrialDropdownTitle from './DaysLeftInTrialDropdownTitle';
import DaysLeftInTrialDropdownCardMolecule from './DaysLeftInTrialDropdownSubscribeCardMolecule';
import LearnMoreButtonAtom from '../../../../../pages/all-pages/Billing/LearnMoreButton';

const Container = styled.div`
  background: white;
  width: 270px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(27, 33, 36, 0.16);
  span {
    user-select: none;
  }

`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;

  & > *:first-child {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    /* or 250% */

    text-align: center;

    color: #9A9A9A;
    margin-bottom: 15px;
  }

  & > *:last-child {
    margin-top: 15px;
    font-size: 14px;

    #LearnMore {
      span {
        font-size: 14px;
      }
    }
  
  }
`;

interface Props {
  isOpen: boolean;
  daysLeft: number;
  onClose: () => void;
  onClickSubscribe: () => void;
  onClickLearnMore: () => void;
}

const DaysLeftInTrialDropdownOrganism = ({
  isOpen,
  daysLeft,
  onClose,
  onClickSubscribe,
  onClickLearnMore,
}: Props) => {
  if (!isOpen) return null;

  return (
    <Container onMouseLeave={onClose}>
      <DaysLeftInTrialDropdownTitle title="You are on the 14-day free trial" />
      <MainContent>
        <Font font="uiTextS" color="gray7" id="announcement">
          You have
          <b>
            {' '}
            {daysLeft}
            {' '}
            days
            {' '}
          </b>
          left on the Premium plan.
          Once the trial ends you will be downgraded to the Free plan.
        </Font>
        <DaysLeftInTrialDropdownCardMolecule onClickSubscribe={onClickSubscribe} />
        <LearnMoreButtonAtom onClick={onClickLearnMore} variant="trial" />
      </MainContent>
    </Container>
  );
};

export default DaysLeftInTrialDropdownOrganism;
