import React from 'react';
import styled from 'styled-components';
import CssSpinner from '.';
import { fadeIn } from '../../../pages/onboarding/welcome/utils/animations';
import { gray10 } from '../../colours';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 100px; 
  animation: ${fadeIn} 1s ease-in-out forwards ;
`;

export interface CssSpinnerProps {
  color?: string,
  size: number
  padding: number
  thickness?: number
}

const SpinnerFlexCenter = ({
  color, size, thickness, padding,
}: CssSpinnerProps) => (
  <Container>
    <CssSpinner color={color} size={size} thickness={thickness} padding={padding} />
  </Container>
);

SpinnerFlexCenter.defaultProps = {
  color: gray10,
  thickness: 3,
};

export default SpinnerFlexCenter;
