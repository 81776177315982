import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { UserDataContext } from '../../../App';
import { gray7 } from '../../../shared/colours';
import { AMPMor24h } from '../../../shared/types/types';
import { small } from '../../../shared/typography';
import { getHourAndMinutes } from '../../../utils/dateUtils/date';

const Container = styled.span<{ amPmOr24h: AMPMor24h }>`
  ${small};
  color: ${gray7};
  ${({ amPmOr24h }) => amPmOr24h === '24h' && css`
    min-width: 30px;
  `}
  ${({ amPmOr24h }) => amPmOr24h === 'AMPM' && css`
    min-width: 50px;
  `}
`;

interface Props {
  date: string
}

const NoteItemDateAtom = ({ date }: Props) => {
  const { settings } = useContext(UserDataContext);
  const dateString = getHourAndMinutes(date, settings.clockFormat);

  return (
    <Container amPmOr24h={settings.clockFormat}>
      {dateString}
    </Container>
  );
};

export default NoteItemDateAtom;
