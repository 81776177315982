import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS, ColorType } from '../colours';
import FONTS, { FontType } from './FontTypesMap';

interface FontProps {
  font: FontType,
  color?: ColorType,
  onClick?: () => void,
  isButton?: boolean,
  id?: string,
}

interface TextProps extends FontProps {
}

const Span = styled.span<FontProps>`
  ${({ font }) => css`
    ${FONTS[font]}
  `}
  ${({ color }) => color && css`
    color: ${COLORS[color]};
  `};
  ${({ isButton }) => isButton && css`
    padding: 2px 8px;
    cursor: pointer;

    &:hover {
      border-radius: 8px;
      background-color: ${COLORS.blue1};
    }
  `};
`;

/**
 * Main component used when displaying text in Shepherd.
 *
 * @param font - Chose from Shepherd Fonts.
 * @param color - Chose from Shepherd Colors, defaults to Black.
 */
const Font: React.FC<TextProps> = ({
  font, color, onClick, isButton, id = '', children,
}) => (
  <Span
    font={font}
    color={color}
    onClick={onClick}
    isButton={isButton}
    id={id || undefined}
  >
    {children}

  </Span>
);

export default Font;
