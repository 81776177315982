import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { gray1, red6, red7 } from '../../../colours';
import useNotificationStore from '../../../../zustand/useNotificationStore';

interface StyleProps {
  isHover: boolean
}

const Bubble = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: ${gray1};
  background-color: ${({ isHover }) => (isHover ? red7 : red6)};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  position: relative;
  top: -8px;
  right: 12px;
  z-index: 2;
  cursor: pointer;
`;

interface Props {
  // unreadNotifications: number,
  onClick: () => void,
  isHover: boolean,
  setIsHover: Dispatch<SetStateAction<boolean>>,
}

function UnreadBubble({ onClick, isHover, setIsHover }: Props) {
  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);
  const { unreadNotificationCount } = useNotificationStore();

  if (unreadNotificationCount === 0) return <></>;
  const text = unreadNotificationCount >= 10 ? '9+' : `${unreadNotificationCount}`;
  return (
    <Bubble
      onClick={onClick}
      isHover={isHover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {text}
    </Bubble>
  );
}

export default UnreadBubble;
