/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import YesExtendButtonAtom from './YesExtendButtonAtom';

const Container = styled.div`
  padding: 10px 14px;

  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(27, 33, 36, 0.16);
`;

interface YesIdLikeToExtendButtonProps {
  onClick: () => void,
}

const YesIdLikeToExtendButton = ({ onClick }: YesIdLikeToExtendButtonProps) => {
  return (
    <Container>
      <YesExtendButtonAtom onClick={onClick} />
    </Container>
  );
};

export default YesIdLikeToExtendButton;
