import { useStripe } from '@stripe/react-stripe-js';
import React, { useContext, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { UserDataContext } from '../../../../App';
import StripeAPI from '../../../../external/Stripe/StripeAPI';
import { StripeConstants } from '../../../../external/Stripe/StripeConstants';
import BillingModalLearnMore from '../../../../pages/all-pages/Billing/LearnMore/BillingModalLearnMore';
import Mixpanel from '../../../../utils/analytics/Mixpanel';
import { toastDanger } from '../../../../utils/notifications';
import ConsoleImproved from '../../../classes/ConsoleImproved';
import DaysLeftInTrialCircleAtom from '../../DaysLeftInTrialCircle/DaysLeftInTrialCircleOrgCtrl';
import DaysLeftInTrialDropdownOrganism from './DaysLeftInTrialDropdown/DaysLeftInTrialDropdownOrganism';

const Container = styled.div`
  position: relative;
  span {
    user-select: none;
  }
`;

const IconContainer = styled.div``;

const DropdownContainer = styled.div`
  position: absolute;

  top: 30px;
  right: -45px;
  z-index: 99999;
`;

const DaysLeftInTrialIconCtrl = () => {
  const userData = useContext(UserDataContext);
  const trialEnd = userData.billing.trialEnd.timestamp;
  const daysLeft = Math.ceil((trialEnd - Date.now()) / (1000 * 60 * 60 * 24));
  const stripe = useStripe();
  const { trackEvent } = useIntercom();
  const [isHovered, setIsHovered] = useState(false);
  const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);

  const toggleHovered = () => {
    setIsHovered(!isHovered);
  };

  const handleClickSubscribe = async () => {
    try {
      const checkoutSession = await StripeAPI.createSubscribeCheckoutSession(userData,
        StripeConstants.CHECKOUT_ORIGINS.DuringTrial);
      if (!checkoutSession.id) {
        ConsoleImproved.log('Checkoutsession id is empty');
        toastDanger('Checkout Session ID is empty', 'Please contact support if you keep seeing this message');
        return;
      }
      await Mixpanel.Billing.logClickedSubscribe(
        userData, trackEvent, StripeConstants.CHECKOUT_ORIGINS.DuringTrial,
      );
      await stripe?.redirectToCheckout({ sessionId: checkoutSession.id });
    } catch (error: any) {
      ConsoleImproved.log('Error while', error);
      toastDanger('Error', error?.message ?? 'Something went wrong', 20000);
    }
  };

  const handleClickBack = () => {
    setIsLearnMoreOpen(false);
    setIsHovered(true);
  };

  const handleOpenLearnMore = () => {
    setIsLearnMoreOpen(true);
  };

  if (!userData.billing.isOnTrial()) return null;

  return (
    <Container>
      <IconContainer onMouseEnter={() => setIsHovered(true)}>
        <DaysLeftInTrialCircleAtom daysLeft={daysLeft} onClick={toggleHovered} />
      </IconContainer>
      <DropdownContainer>
        <DaysLeftInTrialDropdownOrganism
          isOpen={isHovered}
          daysLeft={daysLeft}
          onClickSubscribe={handleClickSubscribe}
          onClose={() => { setIsHovered(false); }}
          onClickLearnMore={handleOpenLearnMore}
        />
      </DropdownContainer>
      <BillingModalLearnMore
        isOpen={isLearnMoreOpen}
        onClickBack={handleClickBack}
        onClickOutside={handleClickBack}
      />
    </Container>
  );
};

export default DaysLeftInTrialIconCtrl;
