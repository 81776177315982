import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useIntercom } from 'react-use-intercom';
import ROUTES from '../../../routes';
import OnboardingSubmitButton from '../personal/components/onboarding-submit-button';
import OnboardingDropdown from '../../../shared/components/dropdown/molecules/OnboardingDropdown';
import Input from '../../../shared/components/input';
import {
  OnboardingForm, OnboardingSubtitle, OnboardingTitle,
} from './utils/styles';
import { logEventPageViewWorkspaceOnboarding } from '../../../utils/analytics/eventLogger';
import { AuthContext } from '../../../App';

const items = [{
  id: 0,
  value: '1-10',
}, { id: 1, value: '11-50' }, { id: 2, value: '51-100' }, { id: 3, value: '100+' }];

const WorkspaceOnboarding = () => {
  const navigate = useNavigate();
  const authState = useContext(AuthContext);
  const { update } = useIntercom();

  const handleRoute = () => {
    navigate(ROUTES.dashboardPath);
  };

  useEffect(() => {
    logEventPageViewWorkspaceOnboarding(authState, update);
  }, []);

  return (
    <div>
      <OnboardingTitle>A little about your workspace</OnboardingTitle>
      <OnboardingSubtitle>
        Second step! Provide us with some info about your workspace.
      </OnboardingSubtitle>

      <OnboardingForm>
        <div>
          <Input id="workspace" label="Workspace name" name="workspace" error="You have an error" touched={false} />
          <OnboardingDropdown id="people" name="people" label="How many people in your workspace?" items={items} />
        </div>

        <OnboardingSubmitButton type="submit" text="Next" onClick={handleRoute} isDisabled />
      </OnboardingForm>
    </div>
  );
};

export default WorkspaceOnboarding;
