import React, { useContext, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { AuthContext } from '../../App';
import ButtonSmall from '../../shared/components/button-small';
import ErrorPage from '../../shared/components/error-page';
import ErrorImage from '../../shared/icons/ErrorImage';
import { header800, uiTextMedium } from '../../shared/typography';
import { GOOGLE_MEET_ERROR_PAGE } from '../../utils/analytics/enums';
import { logPageEvent } from '../../utils/analytics/eventLogger';

const OopsText = styled.h1`
  margin-top: 40px;
  ${header800};
  margin-bottom: 0;
`;

const BoldText = styled.span`
  font-weight: bold !important;
`;

const ExplanationText = styled.p`
  ${uiTextMedium};
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 85px;
`;

const ListContainer = styled.ol`
  margin-top: 0;
  margin-bottom: 0;
`;

const ListItem = styled.li`
  ${uiTextMedium};
  `;

export interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  googleMeetId: string;
  retry: () => void;
}

const GoogleMeetErrorPage = ({ retry }: Props) => {
  // If there doesn't exist any meeting with the googleMeetId,
  // this page will be shown

  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);

  useEffect(() => {
    if (trackEvent) logPageEvent(authState.userId, trackEvent, GOOGLE_MEET_ERROR_PAGE);
  }, [trackEvent]);

  return (
    <>
      <ErrorPage>
        <ErrorImage />
        <OopsText>
          Oops!
        </OopsText>
        <ExplanationText>
          This meeting is not connected to Shepherd yet.
          In order to use Shepherd during this meeting, please follow these easy steps:
        </ExplanationText>
        <ListContainer>
          <ListItem>Return to your Google Calendar</ListItem>
          <ListItem>Click on the event in your calendar</ListItem>
          <ListItem>
            Click
            {' '}
            <b>&#39;Open Shepherd&#39;</b>
          </ListItem>
          <ListItem>Return to your Google Meet</ListItem>
          <ListItem>
            Click
            {' '}
            <BoldText>Retry</BoldText>
          </ListItem>
        </ListContainer>
        <ButtonContainer>
          <ButtonSmall text="Retry" onClick={retry} />
        </ButtonContainer>
      </ErrorPage>
    </>
  );
};

export default GoogleMeetErrorPage;
