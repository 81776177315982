import React from 'react';
import styled from 'styled-components';
import ReactTooltip from '../../shared/components/tooltip/ReactTooltip';
import ShepherdIcon from '../../shared/icons/shepherd-logo/components/shepherd-icon';
import HeaderTextAtom from './HeaderTextAtom';
import HelpIconAtom from './HelpIconAtom';
import GoToTodayButtonMolecule from './notes-list/GoToTodayButtonMolecule';
import RefreshArrowAtom from './RefreshArrow';
import { ScrollBehavior } from '../../shared/types/types';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: 12px;
  }
  & > *:not(:first-child) {
    margin-left: 8px;
  }

`;

const RightContainer = styled.div`
  display: flex;

  & > *:first-child {
    margin-right: 8px;
  }
`;

const TooltipText = () => (
  <>
    {/* This page is home to your meeting notes linked */}
    {/* <br /> */}
    {/* to your  calendar events. */}
    {/* <br /> */}
    {/* For today we also show meetings without notes. */}
    Overview of past meetings with notes, and all future meetings
    {/* <br />
    , as well as your unattached notesmade in your Quick notes. */}
  </>
);

interface Props {
  isLoading: boolean,
  fetchMeetingsAgain: () => void;
  // eslint-disable-next-line no-unused-vars
  onClickToday: (behavior: ScrollBehavior) => void;
}

const AllNotesHeaderMolecule = ({ isLoading, fetchMeetingsAgain, onClickToday }: Props) => (
  <Container>
    <LeftContainer>
      <HeaderTextAtom text="All Notes" />
      <ReactTooltip tip={TooltipText()} place="right" multiline enableOnMobile>
        <HelpIconAtom />
      </ReactTooltip>
      <ReactTooltip tip="Refresh your meetings" multiline>
        {/* <HelpIconAtom onClick={fetchMeetingsAgain} /> */}
        <RefreshArrowAtom onClick={fetchMeetingsAgain} />
      </ReactTooltip>
      {isLoading && <ShepherdIcon width={20} spin />}
    </LeftContainer>
    <RightContainer>
      <GoToTodayButtonMolecule onClick={() => onClickToday('smooth')} />
    </RightContainer>
  </Container>
);

export default AllNotesHeaderMolecule;
