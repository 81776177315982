import { captureException } from '@sentry/react';
import { PublicUserDataV2 } from '../../shared/types/types';
import { startTrackingEvent, stopTrackingEvent } from '../../utils/analytics/eventLogger';
import { functions } from '../../utils/firebase';
import { toastDanger } from '../../utils/notifications';
import { mapPublicUserDataV2ToBeSure, rejectedPublicUserDataV2 } from '../../utils/user/publivUserDataV2/PublicUserDataV2Utils';

const cfSearchPublicUserDataV2ByEmailCore = (email: string) => functions()
  .httpsCallable('searchPublicUserDataV2ByEmail')({ email })
  .then((result) => {
    const publivUserDataV2: PublicUserDataV2 = mapPublicUserDataV2ToBeSure(result.data);
    return publivUserDataV2;
  })
  .catch((error) => {
    console.error(`Error in ${cfSearchPublicUserDataV2ByEmailCore.name}:`, { email, error });
    captureException(error, { extra: { email, functionName: `${cfSearchPublicUserDataV2ByEmailCore.name}` } });
    const rejectedData = rejectedPublicUserDataV2;
    rejectedData.data.email = email;
    return rejectedData;
  });

export default cfSearchPublicUserDataV2ByEmailCore;

export const cfSearchPublicUserDataV2ByEmailsCore = async (emails: string[]) => {
  try {
    if (emails.length === 0) return ([] as PublicUserDataV2[]);
    startTrackingEvent('attendeesLoad');
    const result = await functions().httpsCallable('searchPublicUserDataV2ByEmails')({ emails });
    const users: PublicUserDataV2[] = result.data
      .map((data: any) => mapPublicUserDataV2ToBeSure(data));
    stopTrackingEvent('attendeesLoad');
    return users;
  } catch (error: any) {
    console.error(`Error in ${cfSearchPublicUserDataV2ByEmailsCore.name}:`, { emails, error });
    toastDanger('Error', error?.message ?? 'Error');
    captureException(error, { extra: { emails, functionName: `${cfSearchPublicUserDataV2ByEmailsCore.name}` } });
    return ([]) as PublicUserDataV2[];
  }
};
