import React, {
  Dispatch, SetStateAction, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../../../App';
import { mapAttendeesToEmailRecipients } from '../../../../../utils/meetings/meetingsUtils';
import { getCandidatesFromUserData } from '../../../../../utils/user/UserDataUtils';
import MeetingDataContext from '../../../context/MeetingDataContext';
import AddRemoveEmailsButton from '../../AddRemoveEmailsButton';
import EmailMultiInput from '../../EmailMultiInput';
import { EmailRecipient } from '../../ShareNotesModal';
import { EnterEmailRecipientsAtom, ModalLineLocal, SectionText } from '../utils/commonStyles';
import { NotesSwitchData } from '../utils/types';
import CustomMessage from './CustomMessage';
import NotesSwitch from './NotesSwitch';

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

interface Props {
  notesSwitchData: NotesSwitchData,
  setNotesSwitchData: React.Dispatch<React.SetStateAction<NotesSwitchData>>,
  emailRecipients: EmailRecipient[],
  setEmailRecipients: Dispatch<SetStateAction<EmailRecipient[]>>,
  customMessage: string,
  // eslint-disable-next-line no-unused-vars
  setCustomMessage: (message: string) => void,
}

export default function EmailView({
  notesSwitchData, setNotesSwitchData, emailRecipients, setEmailRecipients,
  setCustomMessage, customMessage,
}: Props) {
  const { data: { attendees } } = useContext(MeetingDataContext);
  const userData = useContext(UserDataContext);
  const assigneeCandidates = getCandidatesFromUserData(userData);

  useEffect(() => {
    if (!attendees.length) return;
    const newEmailList = mapAttendeesToEmailRecipients(attendees);
    setEmailRecipients(newEmailList);
  }, [attendees]);

  const handleAddEmails = () => {
    const newEmailRecipients = mapAttendeesToEmailRecipients(attendees);
    setEmailRecipients(newEmailRecipients);
  };

  const handleRemoveEmails = () => {
    setEmailRecipients([]);
  };

  return (
    <>
      <FlexContainer>
        <EnterEmailRecipientsAtom>Enter email recipients</EnterEmailRecipientsAtom>
        <AddRemoveEmailsButton
          emailRecipients={emailRecipients}
          handleAddAllEmails={handleAddEmails}
          handleRemoveEmails={handleRemoveEmails}
        />
      </FlexContainer>
      <EmailMultiInput
        recipients={emailRecipients}
        handleAddEmails={handleAddEmails}
        handleRemoveEmails={handleRemoveEmails}
        setEmailRecipients={setEmailRecipients}
        isChecked
        candidates={assigneeCandidates}
        showAddorRemoveButton={false}
      />
      <ModalLineLocal bottomMargin />
      <SectionText>Select to share</SectionText>
      <NotesSwitch notesSwitchData={notesSwitchData} setNotesSwitchData={setNotesSwitchData} />
      <ModalLineLocal />
      <SectionText>Add custom message</SectionText>
      <CustomMessage
        setCustomMessage={setCustomMessage}
        customMessage={customMessage}
      />
    </>
  );
}
