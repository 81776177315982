import {
  AttendeeV2, DatabaseFriendListV2, DatabasePublicUserV2, MeetingData, PublicUserDataV2, User,
} from '../../shared/types/types';
import { firestore } from '../../utils/firebase';
import { cfSearchPublicUserDataV2ByEmailsV2 } from '../publicUserData/PublicUserDataAPI';

export const updateFriendListV2 = (meetingData: MeetingData, user: User) => {
  if (meetingData.attendees.resolvedState !== 'resolved') return;
  if (user.friendListV2.resolvedState !== 'resolved') return;

  const attendeesNotAlreadyInFriendList = getAttendeesNotAlreadyInFriendList(
    meetingData.attendees.attendees, user.friendListV2.users,
  );
  attendeesNotAlreadyInFriendList.forEach((attendee) => {
    addAttendeeToFriendListV2(user.userId, attendee);
  });
  const shepherdUsersNotAlreadyInFriendList = attendeesNotAlreadyInFriendList
    .filter((u) => u.userId.length > 0 && u.data.email.length > 0);
  if (shepherdUsersNotAlreadyInFriendList.length > 0) {
    console.log(`Shepherd users not already in friend list: ${shepherdUsersNotAlreadyInFriendList.map((u) => u.data.email)}`);
  }
};

const getAttendeesNotAlreadyInFriendList = (
  attendees: AttendeeV2[], friendList: PublicUserDataV2[],
) => {
  const emailsInFriendList = friendList.map((user) => user.data.email);
  const attendeesNotInFriendList = attendees
    .filter((attendee) => !emailsInFriendList.includes(attendee.data.email));
  return attendeesNotInFriendList;
};

const dbGetUserWithFriendList = async (user: User, databaseUser: any) => {
  const databaseFriendList2 = (databaseUser?.friendListV2 ?? {}) as DatabaseFriendListV2;
  const emails = Object.keys(databaseFriendList2).map((userId) => {
    const friend = databaseFriendList2[userId];
    return friend?.email ?? '';
  });
  const users = await cfSearchPublicUserDataV2ByEmailsV2(emails);
  // eslint-disable-next-line no-param-reassign
  user.friendListV2 = {
    resolvedState: 'resolved',
    users,
  };
  return user;
};

export default dbGetUserWithFriendList;

const addAttendeeToFriendListV2 = (userId: string, attendee: AttendeeV2) => {
  if (attendee.userId.length === 0) return; // This means, that for right now,
  // a friend needs to be registered in shepherd
  if (attendee.data.email.length === 0) return;
  const newPublicUserV2 = { email: attendee.data.email } as DatabasePublicUserV2;
  const dbData: any = {};
  dbData[`friendListV2.${attendee.userId}`] = newPublicUserV2;
  firestore().collection('users').doc(userId).update(dbData)
    .then(() => {
      console.log('Saved new friend to friend list 2');
      console.log(attendee);
    })
    .catch(() => {
      console.log('Something went wrong in addAttendeeToFriendListV2');
    });
};
