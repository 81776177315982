import { Dispatch, SetStateAction } from 'react';
import { MeetingData } from '../../shared/types/types';

const resetTitle = (
  meetingData: MeetingData,
  setMeetingData: Dispatch<SetStateAction<MeetingData>>,
) => {
  const originalTitle = meetingData.data.title;
  setMeetingData((prev) => ({
    ...prev,
    data: {
      ...prev.data,
      title: '',
    },
  }));
  setMeetingData((prev) => ({
    ...prev,
    data: {
      ...prev.data,
      title: originalTitle,
    },
  }));
};

export default resetTitle;
