import { captureException } from '@sentry/react';

class SentryAPI {
  static captureExceptionAndConsoleError = (
    functionName: string, error: Error, ...params: any[]
  ) => {
    const errorMessage = `, with message: ${error?.message ?? ''}`;
    const text = `Error in ${functionName}${errorMessage}`;
    console.error(text, { error, ...params });
    captureException(error, { extra: { functionName, params } });
  };
}

export default SentryAPI;
