import moment from 'moment';
import { currentISOString, currentTimestamp } from '../../utils/currentDateNicelyFormatted';
import { GapiMeetingData, SDateT } from '../types/types';
import ConsoleImproved from './ConsoleImproved';

/**
 * SDate => Shepherd Date
 * A class to represent a date, with many useful methods.
 *
 * We represent date in in the format defined in
 * rfc3339 - https://datatracker.ietf.org/doc/html/rfc3339
 *
 * ISO is the preferred format for dates in JavaScript
 */
class SDate {
  readonly timestamp: number;

  readonly date: string;

  constructor(newDate?: Date | string) {
    if (newDate instanceof Date) {
      this.timestamp = Number(moment(newDate ?? new Date()).format('x'));
      this.date = newDate?.toISOString() ?? new Date().toISOString();
      return;
    }

    if (newDate && newDate.length > 0) {
      this.timestamp = Number(moment(newDate).format('x'));
      this.date = new Date(newDate).toISOString();
      return;
    }

    this.timestamp = Number(moment().format('x'));
    this.date = new Date().toISOString();
  }

  public get value(): SDateT {
    return { timestamp: this.timestamp, date: this.date };
  }

  public static get empty(): SDateT {
    return { timestamp: 0, date: '' };
  }

  getHoursMinutesAndSeconds(): string {
    return moment(this.date).format('HH:mm:ss');
  }

  isToday(): boolean {
    return moment(this.date).isSame(moment(new Date()), 'day');
  }

  /**
   * If the date is today, return `Today`, otherwise return an empty string
   */
  isTodayReturnString(): string {
    if (this.isToday()) {
      return 'Today';
    }
    return '';
  }

  isMoreThanXDaysInFuture(days: number): boolean {
    return moment(this.date).isAfter(moment(new Date()).add(days, 'days'));
  }

  isMoreThanXDaysInPast(days: number): boolean {
    return moment(this.date).isBefore(moment(new Date()).subtract(days, 'days'));
  }

  /**
   * Returns the current date in 'HH:mm:ss' format
   *
   * @Examples
   *
   * `() => 14:12:59` where `14` is hours, `12` is minutes and `59` is seconds
   *
   * `() => 08:12:59` where `08` is hours, `12` is minutes and `59` is seconds
   */
  static getCurrentHoursMinutesAndSeconds(): string {
    return moment(new Date()).format('HH:mm:ss');
  }

  /**
   * Returns the current date in 'mm:ss:SS' format, SS is milliseconds
   *
   * @Examples
   *
   * `() => 00:00:00` where `00` is minutes, `00` is seconds and `00` is milliseconds
   *
   * `() => 59:59:99` where `59` is minutes, `59` is seconds and `99` is milliseconds
   */
  static getMinutesSecondsAndHundredths(): string {
    return moment(new Date()).format('mm:ss:SS');
  }

  static printPassedSinceSDate(sdate: SDate): void {
    const secondsAndHundredths = moment(moment(new Date()).diff(moment(sdate.date))).format('s.SS');
    ConsoleImproved.log(`${secondsAndHundredths} seconds and hundredths passed since ${sdate.getHoursMinutesAndSeconds()}`);
  }

  static isMeetingCurrentlyOngoing(gapiMeeting: GapiMeetingData): boolean {
    const currentTime = moment(new Date());
    const startTime = moment(gapiMeeting.start.dateTime);
    const endTime = moment(gapiMeeting.end.dateTime);
    return currentTime.isBetween(startTime, endTime);
  }

  static calculateNumberOfDaysSinceDate = (datetime: string) => {
    const date = moment(datetime);
    const now = moment();
    return now.diff(date, 'days');
  }

  static getDateXWeeksAgo = (weeksAgo: number) => {
    const daysAgo = weeksAgo * 7;
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    return date;
  }

  static getDateXWeeksInFuture = (weeksInFuture: number) => SDate.getDateXWeeksAgo(-weeksInFuture)

  /**
   * ISO is the preferred format for dates in JavaScript
   *
   * @Examples
   * `('2022-11-10') => '2021-11-10T00:00:00.000Z'`
   */
  static convertDateStringToISOString = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  static currentISOObject = () => ({
    date: currentISOString(),
    timestamp: currentTimestamp(),
  });

  static isDateInPast(dateString: string) {
    let date = new Date();
    const now = new Date();
    try {
      date = new Date(dateString);
    } catch (error) {
      ConsoleImproved.log('Error parsing date', { error, dateString });
    }
    return date < now;
  }
}

export default SDate;
