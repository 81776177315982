import firebase from 'firebase';
import { AuthState, User } from '../../shared/types/types';
import { DATABASE_PHOTO_URL_FIELD, UPDATE_EVENT } from '../../utils/analytics/enums';
import { logDatabaseEvent } from '../../utils/analytics/eventLogger';
import SentryAPI from '../../utils/analytics/SentryAPI';
import { firestore } from '../../utils/firebase';
import { mapDatabaseDataToUser, UserUtils } from '../../utils/user/UserDataUtils';
import { USER_PATH } from '../FirebaseConstants';
import UserAPI from './UserAPI';

class UserDataAPI {
  static dbUpdatePhotoUrlIfNotUpdated = (userData: User, authState: AuthState) => {
    const { photoUrl } = userData.data;
    if (photoUrl !== '') return;
    console.log('photoUrl does not exist for an existing profile');
    UserDataAPI.dbUpdateUserPhotoUrlField(authState, authState.photoUrl);
  };

  static dbUpdateNameIfNotUpdated = (user: User, authState: AuthState) => {
    const { firstName, lastName } = user.data;
    if (firstName.length === 0 && lastName.length === 0) {
      console.log('Name is not updated');
      UserAPI.Data.dbPopulateUserNamesWithAuthData(authState);
    }
  };

  static dbUpdateUserPhotoUrlField = (authState: AuthState, photoUrl: string) => {
    const newPictureUrlField = { [USER_PATH.data.photoUrl]: photoUrl };

    firestore()
      .collection('users')
      .doc(authState.userId)
      .update(newPictureUrlField)
      .then(() => {
        console.log('photoUrl field successfully updated for user');
        logDatabaseEvent(authState.userId, UPDATE_EVENT, DATABASE_PHOTO_URL_FIELD);
      })
      .catch((err) => {
        SentryAPI.captureExceptionAndConsoleError('UserDataAPI.dbUpdateUserPhotoUrlField', err, authState, photoUrl);
      });
  };

  static dbPopulateUserNamesWithAuthData = (authState: AuthState) => {
    // TODO: Use Core function
    UserAPI.dbUserUpdateInfo(authState.userId, {
      [USER_PATH.data.firstName]: authState.firstName,
      [USER_PATH.data.lastName]: authState.lastName,
      [USER_PATH.data.name]: `${authState.firstName} ${authState.lastName}`,
    });
  };

  /**
   * @userId the userId of the user
   * @isWelcomeEmailSent the value we update the database with
   */
  static dbUserSetWelcomeEmailSentStatus = async (
    userId: string,
    isWelcomeEmailSent: boolean,
  ) => {
    // TODO: Use Path
    const updates = { [USER_PATH.data.receivedWelcomeEmail]: isWelcomeEmailSent };
    return UserAPI.dbUserUpdateInfo(userId, updates);
  };

  static dbUserUpdateHasSeenNewFeatures = (userId: string, newFeatureId: string) => {
    const ref = firestore()
      .collection('users')
      .doc(userId);

    return ref.update({
      [USER_PATH.data.newFeaturesViewed]: firebase.firestore.FieldValue.arrayUnion(newFeatureId),
    });
  };

  static dbUserRemoveWhatsNewId = (userId: string, whatsNewId: string) => {
    const ref = firestore()
      .collection('users')
      .doc(userId);

    return ref.update({
      [USER_PATH.data.newFeaturesViewed]: firebase.firestore.FieldValue.arrayRemove(whatsNewId),
    })
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('UserDataAPI.dbUserRemoveWhatsNewId', error, userId, whatsNewId);
      });
  };

  /**
   * @returns true if managed to set it to true, otherwise false.
   * Uses an transaction to make sure only one function call will
   * actually update the DB
   */
  static setHasReceivedWelcomeEmail = async (userData: User): Promise<boolean> => {
    const docRef = firestore().collection('users').doc(userData.userId);

    return firestore().runTransaction((transaction) => transaction.get(docRef)
      .then(async (doc) => {
        if (!doc.exists) return false;

        const userDataTransaction = mapDatabaseDataToUser(doc.data(), doc.id);
        const { hasOnboarded, receivedWelcomeEmail } = userDataTransaction.data;

        if (!UserUtils.isInitialUserDataSet(userDataTransaction)) {
          console.log('User has not initial user data set');
          // throw new Error('Transaction failed, user has not set initial data');
          return false;
        }

        if (!hasOnboarded) {
          console.log('User has not onboarded');
          // throw new Error('Transaction failed, user has not onboarded');
          return false;
        }

        if (receivedWelcomeEmail) {
          console.log('User has already received the welcome email');
          // throw new Error('Transaction failed, user has already received the welcome email');
          return false;
        }

        await transaction.update(docRef, { [USER_PATH.data.receivedWelcomeEmail]: true });
        console.log("Successfully updated 'data.receivedWelcomeEmail: true'");
        return true;
      }));
  };
  // .then(async () => {
  //   const sendWelcomeEmailResolvedState = await cfSendWelcomeEmailAPI(userData.data.firstName);
  //   if (sendWelcomeEmailResolvedState === 'resolved') {
  //     console.log('Successfully sent welcome email and updated status in database');
  //   } else {
  //     console.log('Failed to send welcome email');
  //     UserAPI.Data.dbUserSetWelcomeEmailSentStatus(userData.userId, false);
  //     return 'rejected';
  //   }
  //   return 'resolved' as ResolvedState;
  // }).catch((error: Error) => {
  //   if (error.message === 'Transaction failed,
  // user has already received the welcome email') return 'rejected';
  //   console.log('Error sending welcome email and updating status in database');
  //   Sentry.captureException(error);
  //   return 'rejected' as ResolvedState;
  // });
}

export default UserDataAPI;
