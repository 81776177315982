import firebase from 'firebase';
import * as Sentry from '@sentry/browser';
import { Dispatch, SetStateAction } from 'react';
import { MeetingAnalyticsData } from '../shared/types/types';
import INIT_MEETING_ANALYTICS_DATA from '../utils/analytics/analyticsDataObjects';
import { firestore } from '../utils/firebase';
import { COLLECTIONS } from './FirebaseConstants';
import ConsoleImproved from '../shared/classes/ConsoleImproved';

const meetingAnalyticsCollectionRef = firestore()
  .collection('analytics')
  .doc('meetingsAnalytics')
  .collection('meetingsAnalytics');

const userCollectionRef = firestore()
  .collection(COLLECTIONS.USERS);

export const dbListenForMeetingAnalyticsData = (
  meetingId: string, stateSetter: Dispatch<SetStateAction<MeetingAnalyticsData>>,
) => meetingAnalyticsCollectionRef
  .doc(meetingId)
  .onSnapshot((doc) => {
    if (!doc.exists) {
      console.log('Initialize new meeting analytics doc');
      // TODO: Add eventId info to analyticsData so we can easier track which meetings have
      // notes in them or not when we show them in the all notes dashboard
      meetingAnalyticsCollectionRef.doc(meetingId).set(INIT_MEETING_ANALYTICS_DATA);
    } else {
      stateSetter(mapDatabaseMeetingAnalyticsDataToMeetingAnalyticsData(doc.data()));
      console.log('Got new Analytics data');
      console.log(doc.data());
    }
  }, (error) => {
    console.error('Something went wrong while listening to analytics data');
    console.error(error);
  });

const mapDatabaseMeetingAnalyticsDataToMeetingAnalyticsData = (
  databaseData: any,
): MeetingAnalyticsData => {
  const data: MeetingAnalyticsData = {
    hasUsedShepherd: databaseData?.hasUsedShepherd ?? false,
    hasAgenda: databaseData?.hasAgenda ?? false,
    hasSharedNotes: databaseData?.hasSharedNotes ?? false,
    hasPrivateNotes: databaseData?.hasPrivateNotes ?? false,
    users: databaseData?.users ?? [],
  };
  return data;
};

export const dbUpdateMeetingAnalyticsData = (
  meetingId: string,
  onSuccess: any,
  fieldTestFunction: AnalyticsDataFieldTestFunc,
  fieldUpdateFunc: AnalyticsDataFieldUpdateFunc,
) => {
  const docRef = meetingAnalyticsCollectionRef.doc(meetingId);
  return firestore()
    .runTransaction(
      (transaction: firebase.firestore.Transaction) => transaction.get(
        docRef,
      ).then((doc) => {
        if (fieldTestFunction(doc)) {
          // test if field exists/is true, if not exists/false means no log,
          // therefore okay to log
          transaction.update(
            docRef,
            fieldUpdateFunc(doc),
          );
          return '';
        }
        throw new Error('transaction failed, event already logged');
      }),
    ).then(() => {
      console.log('Transaction successfully committed!');
      console.log(fieldTestFunction);
      onSuccess();
    }).catch((error) => {
      if (error?.message?.includes('transaction failed, event already logged')) {
        console.log('Transaction failed, event already logged, not logging');
        return;
      }
      console.error('Transaction failed: ', error);
      console.error(fieldTestFunction);
      Sentry.captureException(error);
    });
};

const scratchpadTestFunctionHasUsedScratchpad = (
  doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
) => {
  const data = doc.data();
  return data?.meta?.analytics?.hasUsedScratchpad ?? false;
};

const scratchpadUpdateFunction = (
) => ({ 'meta.analytics.hasUsedScratchpad': true });

export const dbUpdateUserWithUsedScratchpad = (
  userId: string,
  onSuccess: () => void,
) => {
  const docRef = userCollectionRef.doc(userId);
  return firestore()
    .runTransaction(
      (transaction: firebase.firestore.Transaction) => transaction.get(
        docRef,
      ).then((doc) => {
        if (scratchpadTestFunctionHasUsedScratchpad(doc)) {
          throw new Error('transaction failed, event already logged');
        }
        // test if field exists/is true, if not exists/false means no log,
        // therefore okay to log
        ConsoleImproved.log('Inside update sc', doc.data());

        transaction.update(
          docRef,
          scratchpadUpdateFunction(),
        );
        return '';
      }),
    ).then(() => {
      console.log('Transaction successfully committed for Scratchpad!');
      onSuccess();
    }).catch((error) => {
      if (error?.message?.includes('transaction failed, event already logged')) {
        console.log('Transaction failed, scratchpad event already logged, not logging');
        return;
      }
      console.error('Transaction failed, in scratchpad: ', error);
      Sentry.captureException(error);
    });
};

export interface AnalyticsDataFieldTestFunc {
  // (docRef: DocumentSnapshot<DocumentData>): boolean
  // eslint-disable-next-line no-unused-vars
  (docRef: any): boolean
}

export interface AnalyticsDataFieldUpdateFunc {
  // eslint-disable-next-line no-unused-vars
  (docRef: any): any
}
