import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { AuthContext } from '../../../App';
import { useUI } from '../../../pages/all-pages/ui-context/uiContext';
import ROUTES from '../../../routes';
import { CLOSE_EVENT, OPEN_EVENT } from '../../../utils/analytics/enums';
import {
  logEventButtonClickOpenIntercom,
  logQuickSettingsClick,
} from '../../../utils/analytics/eventLogger';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import ShepherdLogo from '../../icons/shepherd/ShepherdLogo';
import FlowerImage from '../../img/flower.png';
import AddUserIcon from '../../img/sidebar/AddUser.png';
import FeedbackIcon from '../../img/sidebar/Feedback.png';
import HelpIcon from '../../img/sidebar/Help.png';
import FeedbackModal from '../Modal/FeedbackModal';

const FullScreenSidebarContainer = styled.div`
  width: 100%;
  display: flex;
`;
const SidebarFlowerImage = styled.img`
  position: absolute;
  top: 40vh;
  max-height: 50%;
`;
const LinksContainer = styled.div`
  position: relative;
  top: 127px;
  left: 52px;
`;
const StyledLink = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 22px;
  margin-bottom: 22px;
  margin-left: 40px;
  display: flex;
  img{
    margin-right: 10px;
    height: 20px;
    width: 20px;
  };
  cursor: pointer;
`;

const LogoContainer = styled.div`
  position: relative;
  left: 38px;
  top: -75px;
`;
type ToggleIntercomSource = 'chat_with_us' | 'help_us';
const FullScreenLeftSidebar = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isChatBubbleOpen, setIsChatBubbleOpen] = useState(false);
  const { openInviteMemberModal } = useUI();
  const dropdownRef = useRef(null);
  const [setIsQuickSettingsOpen] = useDetectOutsideClick(dropdownRef, false);
  const navigate = useNavigate();
  // const [isQuickSettingsOpen, setIsQuickSettingsOpen] =
  // useDetectOutsideClick(dropdownRef, false);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);
  const { show, hide, trackEvent } = useIntercom();
  const authData = useContext(AuthContext);
  const { userId } = authData;
  const handleFeedbackModalOpen = () => {
    setIsFeedbackModalOpen(true);
    // setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'feedback', trackEvent);
  };
  const toggleIntercom = (source: ToggleIntercomSource) => () => {
    if (isChatBubbleOpen) {
      hide();
      setIsChatBubbleOpen(false);
      logEventButtonClickOpenIntercom(authData.userId, trackEvent);
      logQuickSettingsClick(authData.userId, CLOSE_EVENT, source, trackEvent);
    } else {
      show();
      setIsChatBubbleOpen(true);
      logQuickSettingsClick(authData.userId, OPEN_EVENT, source, trackEvent);
    }
  };
  const openMembersModal = () => {
    openInviteMemberModal();
    setIsQuickSettingsOpen(false);
    logQuickSettingsClick(userId, OPEN_EVENT, 'invite_members', trackEvent);
  };

  const openAllNotes = () => {
    setIsQuickSettingsOpen(false);
    navigate(ROUTES.allNotes);
  };

  return (
    <FullScreenSidebarContainer>
      <FeedbackModal
        isModalOpen={isFeedbackModalOpen}
        setModalClosed={closeFeedbackModal}
      />

      <LinksContainer>
        <LogoContainer>
          <ShepherdLogo onClick={openAllNotes} />
        </LogoContainer>
        <StyledLink onClick={openMembersModal}>
          <img alt="" src={AddUserIcon} />
          <div>Invite friends to use Shepherd</div>
        </StyledLink>
        <StyledLink onClick={handleFeedbackModalOpen}>
          <img alt="" src={FeedbackIcon} />
          <div> Give us feedback</div>
        </StyledLink>
        <StyledLink onClick={toggleIntercom('help_us')}>
          <img alt="" src={HelpIcon} />
          <div> Help center</div>
        </StyledLink>
      </LinksContainer>
      <SidebarFlowerImage src={FlowerImage} />
    </FullScreenSidebarContainer>
  );
};
export default FullScreenLeftSidebar;
