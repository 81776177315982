import React, {
  Dispatch, SetStateAction, useContext, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../App';
import { gray1 } from '../../../shared/colours';
import HorizontalLoader from '../../../shared/components/css-spinner/HorizontalLoader';
import RelevantMeetingTag from '../../../shared/components/MeetingDetailsHeader/RelevantMeetingComponent/RelevantMeetingTag';
import DeletePrivateNoteConfirmationModal from '../../../shared/components/private-notes/delete-private-note-confirmation-modal';
import PrivateNotesTab from '../../../shared/components/private-notes/private-notes-tab-organism';
import { deletePrivateNote, emptyPrivateNoteData } from '../../../shared/components/private-notes/utils/functions';
import BREAKPOINTS from '../../../shared/CSS/CSS_Constants';
import {
  MeetingData, MeetingSection, MeetingVersion, PrivateNoteData, RelevantMeetingsData,
} from '../../../shared/types/types';
import { MEETING_SECTION } from '../../../utils/enums';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import AgendaTabButtonAtom from './AgendaTabButtonAtom';
import TabDivButton from './TabDivButton';
import { handleSetTabAfterPrivateNoteDeleted } from './TabUtils';

const TabsMenuContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: ${gray1};
  padding: 13px 16px;
  width: 100%;
  @media only screen and (max-width: ${BREAKPOINTS.sm}){
    padding: 7px 10px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LeftButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export interface Props {
  meetingData: MeetingData,
  meetingTab: MeetingSection,
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
  closeTemplatesView?: () => void
  unviewedTasks: number,
  setPrivateNoteOpen?: Dispatch<SetStateAction<boolean>>,
  privateNotes: PrivateNoteData[],
  // eslint-disable-next-line no-unused-vars
  onClickSelectPrivateNote: (chatId: string) => void,
  setPrivateNotes: Dispatch<SetStateAction<PrivateNoteData[]>>,
  meetingDataVersion: MeetingVersion,
  activePrivateNoteId: string,
  relevantMeetings: RelevantMeetingsData,
  // eslint-disable-next-line no-unused-vars
  clickRelevantMeeting: (meeting: MeetingData) => void,
}

const TabsMenu = ({
  meetingData,
  meetingTab,
  setMeetingTab,
  unviewedTasks,
  closeTemplatesView = () => null,
  meetingDataVersion,
  onClickSelectPrivateNote,
  setPrivateNotes,
  setPrivateNoteOpen,
  privateNotes,
  activePrivateNoteId,
  relevantMeetings,
  clickRelevantMeeting,
}: Props) => {
  const userData = useContext(UserDataContext);

  const meetingTabsRef = useRef(document.createElement('div'));
  const deletePrivateNoteModal = useRef(null);

  const [activePrivateNote, setActivePrivateNote] = useState<number>(-1);
  const [isDeletePrivateNoteState, setIsDeletePrivateNoteState] = useState<boolean>(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useDetectOutsideClick(
    deletePrivateNoteModal, false,
  );
  const [chatToDelete, setChatToDelete] = useState<PrivateNoteData>(emptyPrivateNoteData());

  /**
   * I dont think the useEffect below is required, and this is the cause of
   * tab switching back to default tab even after a user has clicked other tab
   * during load, i assume this piece of code is used to set the tab to user
   * default tab when there are no private notes, but i see this is already
   * being handled by handleSetTabAfterPrivateNoteDeleted()
   * H: Fine. We will keep this here for a bit, and see if we see any bugs with private notes
   * before removing it.
   */

  // useEffect(() => {
  //   if (privateNotes.length === 0 && activePrivateNoteId.length === 0) {
  //     setMeetingTab(
  //       userData.settings.defaultUserTab,
  //     );
  //   }
  // }, [privateNotes]);

  useEffect(() => {
    if (activePrivateNoteId.length === 0) {
      return;
    }

    privateNotes
      .filter((privateNote) => privateNote.chatId !== chatToDelete.chatId)
      .forEach((privateNote: PrivateNoteData, index) => {
        if (privateNote.chatId === activePrivateNoteId) {
          setActivePrivateNote(index);
        }
      });
  }, [activePrivateNoteId]);

  useEffect(() => {
    if (!isDeletePrivateNoteState) return;

    deletePrivateNote(userData.userId, chatToDelete, setPrivateNotes, meetingData.meetingId);
    setIsDeletePrivateNoteState(false);
    handleSetTabAfterPrivateNoteDeleted(
      privateNotes,
      setMeetingTab,
      setActivePrivateNote,
      onClickSelectPrivateNote,
      chatToDelete,
    );
  }, [isDeletePrivateNoteState]);

  const onClickSetNotesTab = (pressedNoteTab: MeetingSection) => () => {
    setMeetingTab(pressedNoteTab);
    closeTemplatesView();
  };

  const onClickOpenPrivateNotesModal = () => {
    if (setPrivateNoteOpen) setPrivateNoteOpen(true);
  };

  const onDeletePrivateNote = () => {
    setIsDeletePrivateNoteState(true);
    setIsDeleteConfirmationOpen(false);
  };

  const onCancelDeletePrivateNote = () => setIsDeleteConfirmationOpen(false);

  if (meetingData.resolvedState !== 'resolved') {
    return (
      <TabsMenuContainer ref={meetingTabsRef}>
        <HorizontalLoader />
      </TabsMenuContainer>
    );
  }

  return (
    <TabsMenuContainer ref={meetingTabsRef}>
      <LeftButtonsContainer>
        <AgendaTabButtonAtom
          meetingDataVersion={meetingDataVersion}
          meetingTab={meetingTab}
          onClick={onClickSetNotesTab(MEETING_SECTION.AGENDA)}
        />
        <TabDivButton
          active={meetingTab === MEETING_SECTION.SHARED_NOTES}
          disabled={meetingTab === MEETING_SECTION.SHARED_NOTES}
          onClick={onClickSetNotesTab(MEETING_SECTION.SHARED_NOTES)}
          tooltipText="Visible to you and other attendees"
          meetingTab={MEETING_SECTION.SHARED_NOTES}
        >
          Shared notes
        </TabDivButton>
        <TabDivButton
          active={meetingTab === MEETING_SECTION.PERSONAL_NOTES}
          disabled={meetingTab === MEETING_SECTION.PERSONAL_NOTES}
          onClick={onClickSetNotesTab(MEETING_SECTION.PERSONAL_NOTES)}
          tooltipText="Strictly visible to you only"
          meetingTab={MEETING_SECTION.PERSONAL_NOTES}
        >
          My notes
        </TabDivButton>
        <TabDivButton
          active={meetingTab === MEETING_SECTION.TASK}
          disabled={meetingTab === MEETING_SECTION.TASK}
          onClick={onClickSetNotesTab(MEETING_SECTION.TASK)}
          unviewedTasks={unviewedTasks}
          tooltipText="Create and assign tasks for you and your team"
          meetingTab={MEETING_SECTION.TASK}
        >
          Tasks
        </TabDivButton>
        <PrivateNotesTab
          privateNotes={privateNotes}
          setPrivateNotes={setPrivateNotes}
          meetingTab={meetingTab}
          setMeetingTab={setMeetingTab}
          selectedChat={activePrivateNote}
          setSelectedChat={setActivePrivateNote}
          onClickOpenPrivateNotesModal={onClickOpenPrivateNotesModal}
          handleSecretNotesSelected={onClickSelectPrivateNote}
          setChatToDelete={setChatToDelete}
          setIsDeleted={setIsDeleteConfirmationOpen}
        />

      </LeftButtonsContainer>
      <RightContainer>
        <RelevantMeetingTag
          relevantMeetings={relevantMeetings}
          clickRelevantMeeting={clickRelevantMeeting}
        />
      </RightContainer>
      <DeletePrivateNoteConfirmationModal
        isOpen={isDeleteConfirmationOpen}
        onClickDeletePrivateNote={onDeletePrivateNote}
        onClickCancelDelete={onCancelDeletePrivateNote}
        refOutsideClick={deletePrivateNoteModal}
      />
    </TabsMenuContainer>
  );
};

const checkIfStateAreTheSame = (prevState: Props, presentState: Props) => {
  if (prevState.meetingTab !== presentState.meetingTab
    || prevState.unviewedTasks !== presentState.unviewedTasks
    || prevState.meetingDataVersion !== presentState.meetingDataVersion
    || prevState.privateNotes !== presentState.privateNotes
    || prevState.meetingData.resolvedState !== presentState.meetingData.resolvedState
    || prevState.meetingData !== presentState.meetingData
    || prevState.relevantMeetings !== presentState.relevantMeetings
  ) {
    return false;
  }
  return true;
};

export default React.memo(TabsMenu, checkIfStateAreTheSame);
