import firebase from 'firebase';
import { ResolvedState, User } from '../../shared/types/types';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { firestore } from '../../utils/firebase';
import { StringUtils } from '../../utils/strings';
import UserId from '../../shared/classes/UserID';
import { COLLECTIONS, USER_PATH } from '../FirebaseConstants';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { mapDatabaseDataToUser } from '../../utils/user/UserDataUtils';
import { BillingMeetingUsed } from '../../external/Stripe/StripeTypes';
import ResolvedStateSmart from '../../utils/ResolvedState/ResolvedStateSmart';
import SentryAPI from '../../utils/analytics/SentryAPI';

class UserAPICore {
  /**
   * @param logText is used for `console.log` purposes. Should be short like `"calendarIds"`.
   * Will be inserted into the log message like this: `Successfully updated ${logText} for user`
   * and for errors like this: `Something went wrong when updating ${logText} for user`
   */
  protected static updateUser = async (
    userId: string, updates: Object, logText: string,
  ): Promise<ResolvedState> => {
    const userID = new UserId(userId);

    if (userID.isNotValid()) {
      console.error(`UserId invalid: ${userID}. Cannot update user`, { userId: userID.value, updates });
      return REJECTED;
    }

    return firestore()
      .collection(COLLECTIONS.USERS)
      .doc(userId)
      .update(updates)
      .then(() => {
        ConsoleImproved.log(`Successfully updated ${logText} for user`, { userId, updates });
        return RESOLVED as ResolvedState;
      })
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('UserAPICore.updateUser', error, userId, updates, logText);
        return REJECTED;
      });
  }

  /**
   * Check if newCalendarIds are different from existing ones in userData
   */
  protected static isDifferentCalendarIds = (userData: User, newCalendarIds: string[]) => {
    const existingCalendars = userData.permissions.google.calendars
      .sort(StringUtils.sortStringsAlphabetically);
    const newCalendars = newCalendarIds
      .sort(StringUtils.sortStringsAlphabetically);

    // Check if they have the same values
    if (existingCalendars.length !== newCalendars.length) return true;
    for (let i = 0; i < existingCalendars.length; i += 1) {
      if (existingCalendars[i] !== newCalendars[i]) return true;
    }
    return false;
  }

  protected static addMeetingUsedCore = (userId: string, newMeetingUsed: BillingMeetingUsed) => {
    // Write a firebase transaction to check whether the meeting used is already in the database
    const updates = {
      [USER_PATH.billing.meetingsUsed]: firebase.firestore.FieldValue.arrayUnion(newMeetingUsed),
    };

    const userRef = firestore().collection(COLLECTIONS.USERS).doc(userId);

    return firestore().runTransaction((transaction) => transaction.get(userRef).then((userDoc) => {
      const userData = mapDatabaseDataToUser(userDoc.data(), userId);
      if (userData.billing.isMeetingInMeetingsUsed(newMeetingUsed.meetingId)) {
        ConsoleImproved.log('New Meeting is already is meetings used in addMeetingUsedCore');
        return;
      }
      transaction.update(userRef, updates);
    })).then(() => {
      ConsoleImproved.log('Added meeting used in Firestore');
    });
  };

  /**
   * This is a transaction to make sure that only one function will be able to update the value,
   * and in turns only one function call will call the CF to make the stripe customer id.
   * Previously, we had a bug where two function calls where made to the CF to make the
   *  stripe customer id at the same time and then both would create new stripe customer ids.
   */
  static updateStripeCustomerIdToSetTransactiontCore = async (
    user: User,
  ): Promise<ResolvedStateSmart> => {
    const updates = {
      [USER_PATH.billing.stripeCustomerIdSet]: true,
    };

    const userRef = firestore().collection(COLLECTIONS.USERS).doc(user.userId);

    const result = await firestore()
      .runTransaction(
        (transaction) => transaction.get(userRef).then((userDoc) => {
          const userData = mapDatabaseDataToUser(userDoc.data(), user.userId);
          if (userData.billing.stripeCustomerId.length > 0) {
            throw new Error(`Stripe customer id already set: ${userData.billing.stripeCustomerId}`);
          }
          if (userData.billing.stripeCustomerIdSet) {
            throw new Error('Stripe customer id set already set, so not setting again');
          }
          transaction.update(userRef, updates);
        }),
      ).then(() => ResolvedStateSmart.RESOLVED)
      .catch((error) => {
        console.log(error);
        return ResolvedStateSmart.REJECTED;
      });

    return result;
  }
}

export default UserAPICore;
