import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import TaskTabs, { TabButton, TabButtonsContainer, TabContent } from '../task-tabs';
import {
  Page,
  TaskItems, TasksPage, TaskTab,
} from '../../types/types';
import TaskTabContent from './TaskTabContent';
import {
  mapTaskIndexToTaskTab,
  mapTaskScopeToOrderField, mapTaskTabToTabIndex,
} from '../../../utils/tasks/tasksUtils';
import { TASK_TAB } from '../../../utils/enums';
import { COLORS } from '../../colours';
import BorderRadiusComponent from './BorderRadiusBar';

const Container = styled.div`
  flex: 1;
  background: ${COLORS.gray1};
`;

interface Props {
  taskItems: TaskItems,
  // eslint-disable-next-line no-unused-vars
  setPage: (page: Page) => void,
  taskTab: TaskTab,
  setTaskTab: Dispatch<SetStateAction<TaskTab>>,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  height?: string,
  disableCalendarSelect?: boolean,
  disableAssigneeSelect?: boolean,
  taskPage: TasksPage,
  disableGoToMeeting?: boolean,
}

const Tasks = ({
  taskItems, setPage, taskTab, setTaskTab, setCreateTaskModalOpen, height, taskPage,
  disableAssigneeSelect, disableCalendarSelect, disableGoToMeeting,
}: Props) => {
  const tasksOrderField = mapTaskScopeToOrderField(taskPage);
  const taskTabIndex = mapTaskTabToTabIndex(taskTab);

  return (
    <>
      <BorderRadiusComponent taskPage={taskPage} borderRadiusTop="24px" />
      <Container id="some-some-id">
        <TaskTabs
          tabIndex={taskTabIndex}
          setTabIndex={(index) => setTaskTab(mapTaskIndexToTaskTab(index))}
          height={height as string}
        >
          <TabButtonsContainer>
            <TabButton>To do</TabButton>
            <TabButton>In progress</TabButton>
            <TabButton>Completed</TabButton>
          </TabButtonsContainer>
          <TabContent key={TASK_TAB.TODO}>
            <TaskTabContent
              taskTab={TASK_TAB.TODO}
              taskItems={taskItems}
              setPage={setPage}
              taskOrderField={tasksOrderField}
              setCreateTaskModalOpen={setCreateTaskModalOpen}
              disableAssigneeSelect={disableAssigneeSelect}
              disableCalendarSelect={disableCalendarSelect}
              disableGoToMeeting={disableGoToMeeting}
              taskPage={taskPage}
            />
          </TabContent>
          <TabContent key={TASK_TAB.IN_PROGRESS}>
            <TaskTabContent
              taskTab={TASK_TAB.IN_PROGRESS}
              taskItems={taskItems}
              setPage={setPage}
              taskOrderField={tasksOrderField}
              setCreateTaskModalOpen={setCreateTaskModalOpen}
              disableAssigneeSelect={disableAssigneeSelect}
              disableCalendarSelect={disableCalendarSelect}
              disableGoToMeeting={disableGoToMeeting}
              taskPage={taskPage}
            />
          </TabContent>
          <TabContent key={TASK_TAB.COMPLETED}>
            <TaskTabContent
              taskTab={TASK_TAB.COMPLETED}
              taskItems={taskItems}
              setPage={setPage}
              taskOrderField={tasksOrderField}
              setCreateTaskModalOpen={setCreateTaskModalOpen}
              disableAssigneeSelect={disableAssigneeSelect}
              disableCalendarSelect={disableCalendarSelect}
              disableGoToMeeting={disableGoToMeeting}
              taskPage={taskPage}
            />
          </TabContent>
        </TaskTabs>
      </Container>

    </>
  );
};

Tasks.defaultProps = {
  height: 'calc(100vh - 310px)',
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
  disableGoToMeeting: false,
};

export default Tasks;
