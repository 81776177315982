import { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
import { groupBy } from 'lodash';
import { EmailRecipient } from '../../pages/meeting/modal/ShareNotesModal';
import ROUTES from '../../routes';
import {
  MeetingData, MeetingSection, MeetingVersion,
  AttendeeV2, TimestampLog, RelativeTiming, SDateT,
  DatabaseMeetingData,
  GapiMeetingData, MeetingsByDate,
} from '../../shared/types/types';
import { currentTimestamp } from '../currentDateNicelyFormatted';
import { createHeadlessFirepad } from '../firebase';
import { MEETING_SECTION } from '../enums';
import { pendingMeetingData, rejectedMeetingData } from '../../database/utils/templateMeetingData';
import QueryUtils from './QueryUtils';

export const getClosestMeeting = (meetings: MeetingData[]) => {
  if (!meetings) return rejectedMeetingData;
  if (meetings.length === 0) return rejectedMeetingData;
  let closestMeeting = meetings[0];
  const currentTs = currentTimestamp();
  meetings.forEach((candidateMeeting) => {
    if (timeFromNow(candidateMeeting, currentTs) === timeFromNow(closestMeeting, currentTs)) {
      if (timeFromNowSinceLastUpdate(candidateMeeting, currentTs)
        <= timeFromNowSinceLastUpdate(closestMeeting, currentTs)) {
        closestMeeting = candidateMeeting;
      }
    }
    if (timeFromNow(candidateMeeting, currentTs) < timeFromNow(closestMeeting, currentTs)) {
      closestMeeting = candidateMeeting;
    }
  });
  return closestMeeting;
};

export const makeMeetingUrl = (meetingId: string) => `${ROUTES.meetingPath}/${meetingId}?${QueryUtils.CURRENT_MEETING}`;

export const makeGoogleCalendarUrl = (eventId: string, calendarId: string) => `/google-calendar/${eventId}/${calendarId}`;

// 1630049400    -> 1630049400000
// 1622061799661 -> 1622061799661
export const getTsWithMilliseconds = (ts: number) => {
  if (!ts) return 0; // handle if ts is undefined
  return (ts.toString().length === 10 ? ts * 1000 : ts);
};

const timeFromNow = (meeting: MeetingData, currentTs: number) => {
  if (!meeting?.date?.start?.timestamp) {
    console.log('Meeting doesnt have start timestamp');
    console.log(meeting);
    return currentTs;
  }
  const meetingStartTimestamp = getTsWithMilliseconds(meeting.date.start.timestamp);
  return Math.abs(meetingStartTimestamp - currentTs);
};

const timeFromNowSinceLastUpdate = (meeting: MeetingData, currentTs: number) => {
  if (!meeting?.date?.lastUpdated?.timestamp) {
    console.log('Meeting doesnt have lastUpdated timestamp');
    console.log(meeting);
    return currentTs;
  }
  const meetingUpdateTimestamp = getTsWithMilliseconds(meeting.date.lastUpdated.timestamp);
  return Math.abs(meetingUpdateTimestamp - currentTs);
};

/**
 * Takes input of start and end time of meeting and tells the
 * time when the actual meetings notes were shared for that meeting
 * this is done for logging purpose
 */
export const getTimeDifference = (startTime: string, endTime: string, currentTime: string = '') => {
  const now = currentTime ? moment(currentTime) : moment();
  const startTimeMoment = moment(startTime);
  const endTimeMoment = moment(endTime);
  if (now.isBetween(startTimeMoment, endTimeMoment)) {
    return { when: 'during' as RelativeTiming, timeDiff: 0 };
  }
  if (now.isBefore(startTime)) {
    const mins = now.diff(startTimeMoment, 'minutes');
    return {
      when: 'before' as RelativeTiming,
      timeDiff: mins,
    };
  }
  const mins = now.diff(endTimeMoment, 'minutes');
  return {
    when: 'after' as RelativeTiming,
    timeDiff: mins,
  };
};

export const setNoteContent = (
  // eslint-disable-next-line no-unused-vars
  setNote: (newText: string) => void,
  noteTab: MeetingSection,
  meetingId: string,
  userId: string,
) => {
  const headlessFirepad = createHeadlessFirepad(noteTab, meetingId, userId);
  console.log(headlessFirepad, noteTab, 'headleass pad');
  headlessFirepad.getHtml((html: string) => setNote(html));
};

export const mapAttendeesToEmailRecipients = (attendees: any[]) => {
  if (attendees.length === 0) return [];
  const list = attendees?.map((attendee, index) => {
    const val: EmailRecipient = {
      index,
      displayValue: attendee.email,
    };
    return val;
  }) ?? [];
  return list;
};

export const mapAttendeesToEmailRecipientsV2 = (attendees: AttendeeV2[]): EmailRecipient[] => {
  if (attendees.length === 0) return [];
  return attendees.map((attendee, index): EmailRecipient => ({
    index,
    displayValue: attendee.data.email,
  }));
};

export const mapMeetingSectionToText = (meetingSection: MeetingSection) => {
  if (meetingSection === MEETING_SECTION.AGENDA) {
    return 'Agenda';
  }
  if (meetingSection === MEETING_SECTION.SHARED_NOTES) {
    return 'Shared Notes';
  }
  if (meetingSection === MEETING_SECTION.PERSONAL_NOTES) {
    return 'My notes';
  }
  if (meetingSection === MEETING_SECTION.TASK) {
    return 'Tasks';
  }
  return '';
};

export const setMeetingSectionCarefully = (
  meetingSection: MeetingSection,
  version: MeetingVersion,
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
) => {
  if (version >= 4 && meetingSection === MEETING_SECTION.AGENDA) {
    return setMeetingTab(MEETING_SECTION.SHARED_NOTES);
  }

  return setMeetingTab(meetingSection);
};

export const mapTimestampForLog = (
  start: SDateT,
  end: SDateT,
) => {
  const { when, timeDiff } = getTimeDifference(start.date, end.date);
  const timeStampLog: TimestampLog = {
    when,
    timeDiff,
    timestamp: moment().format(),
  };
  return timeStampLog;
};

/**
 * Group meetings by per day, and sort them by start time
 */
export const groupMeetingsByDate = (meetings: GapiMeetingData[]): MeetingsByDate => {
  const groupedNotesByDate = groupBy(meetings, (meeting) => moment(meeting.start.dateTime).format('YYYY-MM-DD'));

  Object.keys(groupedNotesByDate).forEach((eachDay) => {
    const sortedMeetings = groupedNotesByDate[eachDay]
      .sort(sortGAPIMeetingByStartDate);
    groupedNotesByDate[eachDay] = sortedMeetings;
  });

  return groupedNotesByDate;
};

export const sortGAPIMeetingByStartDate = (
  a: GapiMeetingData, b: GapiMeetingData,
) => MeetingUtils.sortByStartDate(a.start.dateTime, b.start.dateTime);

export const sortDatabaseMeetingDataByStartDate = (
  a: DatabaseMeetingData, b: DatabaseMeetingData,
) => MeetingUtils.sortByStartDate(a.date.start.date, b.date.start.date);

export class MeetingUtils {
  /**
   * This is a default calendarId that many users share.
   * Therefore we need to filter it out, if not we will
   * fetch events from other users calendars. Specifically
   * this is typically used for birthdays.
   */
  static filterOutAddressBook = (calendarId: string) => calendarId !== 'addressbook#contacts@group.v.calendar.google.com'

  static filterOnlyUniqueMeetingsByEventId = (
    meeting: MeetingData, index: number, self: MeetingData[],
  ) => index === self.findIndex(
    (selfMeeting) => selfMeeting.googleData.ids.eventId === meeting.googleData.ids.eventId,
  );

  static sortMeetingsByDate = (
    a: MeetingData, b: MeetingData,
  ) => MeetingUtils.sortByStartDate(b.date.start.date, a.date.start.date);

  static sortByStartDate = (a: string, b: string) => {
    const aMoment = moment(a);
    const bMoment = moment(b);
    if (aMoment.isBefore(bMoment)) return -1;
    if (aMoment.isAfter(bMoment)) return 1;
    return 0;
  };

  static isFullDayMeeting = (meeting: MeetingData) => {
    const start = moment(meeting.date.start.date);
    const end = moment(meeting.date.end.date);
    const difference = end.diff(start, 'minutes');

    if (difference % (24 * 60) === 0) {
      return true;
    }
    return false;
  }

  static createDummyMeeting = (title: string) => {
    const meeting = JSON.parse(JSON.stringify(pendingMeetingData));
    meeting.data.title = title;
    const randomDate = moment()
      .subtract(Math.floor(Math.random() * 100) + 1, 'days')
      .add(Math.floor(Math.random() * 100000) + 1, 'minutes')
      .subtract(Math.floor(Math.random() * 365) + 1, 'days');
    meeting.date.start.date = randomDate.format();
    meeting.googleData.ids.eventId = Math.random().toString(36).substring(7);
    meeting.meetingId = Math.random().toString(36).substring(7);

    return meeting;
  }
}
