import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../colours';
import { TasksPage } from '../../types/types';

const TopContainer = styled.div<Props>`
  flex-shrink: 0;
  background: ${COLORS.surface};
  height: 24px;
`;

const InnerContainer = styled.div<Props>`
  width: 100%;
  height: 100%;
  background: white;
  ${({ taskPage, borderRadiusTop }) => taskPage === 'allTasks' && css`
      border-radius: ${borderRadiusTop} ${borderRadiusTop} 0 0;
  `};
`;

interface Props {
  taskPage: TasksPage,
  borderRadiusTop: string;
}

/**
 * We use this component since when we added the border radius inside the `TaskTabs`,
 *  the scrollbar was messed up
 */
const BorderRadiusComponent = ({ taskPage, borderRadiusTop }: Props) => (
  <TopContainer taskPage={taskPage} borderRadiusTop={borderRadiusTop}>
    <InnerContainer taskPage={taskPage} borderRadiusTop={borderRadiusTop} />
  </TopContainer>
);

export default BorderRadiusComponent;
