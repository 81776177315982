import styled from 'styled-components';
import { gray10, gray8 } from '../../../../../../shared/colours';
import { header900, uiTextM } from '../../../../../../shared/typography';
import image from './3d-casual-life-woman-in-online-meetings.svg';

export const VideoIntroductionScreenContainer = styled.div`
  width: 100%;
  height: calc(100% - 125px - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 500px;
`;

export const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 250px;
  height: 210px;
  padding: 68px 0 32px 0;
  background-image: ${`url(${image})`};
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-height: 830px) {
    padding: 0;
  };
  @media (max-height: 720px) {
    display: none;
  };
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;

  width: 100%;
  margin-bottom: 50px;

  @media (max-height: 920px) {
    padding-top: 40px;
  };
`;

export const Title = styled.div`
  ${header900}
  color: ${gray10};
`;

export const Subtitle = styled.div`
  ${uiTextM}
  color: ${gray8};

  margin-top: 15px;
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 250px;

  border-radius: 10px;
  box-shadow: 0px 18.620689392089844px 49.65517044067383px -4.137930870056152px #1B21243D;
  overflow: hidden;
`;
