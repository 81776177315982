import { captureMessage } from '@sentry/react';
import firebase from 'firebase';

class SlackBoltCore {
  static coreListConversations = async (): Promise<any> => {
    try {
      const result = await firebase.app()
        .functions('europe-west1')
        .httpsCallable('slackBoltListConversations')();
      return result;
    } catch (error) {
      console.error(`Error in ${SlackBoltCore.coreListConversations}`, { error });
      captureMessage(`Error in ${SlackBoltCore.coreListConversations}`, { extra: { error } });
      return {};
    }
  }

  static coreLogMessage = async (message: string): Promise<any> => {
    try {
      const result = await firebase.app()
        .functions('europe-west1')
        .httpsCallable('slackBoltLogMessage')({ text: message });
      console.log('Result in ', result);
      return result;
    } catch (error) {
      console.error(`Error in ${SlackBoltCore.coreLogMessage}`, { error });
      captureMessage(`Error in ${SlackBoltCore.coreLogMessage}`, { extra: { error } });
      return {};
    }
  }
}

export default SlackBoltCore;
