import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { AuthContext } from '../../App';
import CssSpinner from '../../shared/components/css-spinner';
import { surface } from '../../shared/colours';
import { ResolvedState } from '../../shared/types/types';
import { header400 } from '../../shared/typography';
import { saveNotionData } from '../../utils/notion/notionAPIs';
import ButtonSmall from '../../shared/components/button-small';
import { logPageEvent } from '../../utils/analytics/eventLogger';
import { INTERCOM_REDIRECT_PAGE } from '../../utils/analytics/enums';
import { notionAuthUrl } from '../../utils/notion/notionUtils';

const TextContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  ${header400}

  margin-top: 50px;
`;

const ButtonContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
`;

// TODO Asish: Should we have testing in the name?
export default function NotionTestingPage() {
  const [successfullyIntegratedNotion, setSuccessfullyIntegratedNotion] = useState<ResolvedState>('pending');
  const location = useLocation();
  const tempAuthCode = new URLSearchParams(location.search).get('code');
  const { trackEvent } = useIntercom();

  const authState = useContext(AuthContext);

  const isValidCode = tempAuthCode && tempAuthCode.length > 10;

  useEffect(() => {
    if (authState.userState !== 'loggedIn') return;
    logPageEvent(authState.userId, trackEvent, INTERCOM_REDIRECT_PAGE);
  }, [authState.userId]);

  useEffect(() => {
    if (isValidCode) {
      initiateSavingNotionData();
    }
  }, [tempAuthCode]);

  useEffect(() => {
    if (successfullyIntegratedNotion === 'resolved') {
      setTimeout(() => {
        window.close();
      }, 2000);
    }
  }, [successfullyIntegratedNotion]);

  const initiateSavingNotionData = async () => {
    if (tempAuthCode !== null) {
      const result = await saveNotionData(tempAuthCode, authState.userId);
      if (result.state === 'resolved') {
        setSuccessfullyIntegratedNotion('resolved');
      } else {
        console.log(result.data);
        setSuccessfullyIntegratedNotion('rejected');
      }
    }
  };

  if (!isValidCode) {
    return (
      <>
        <TextContainer>
          The code provided is not valid
        </TextContainer>

      </>
    );
  }

  if (successfullyIntegratedNotion === 'pending') {
    return (
      <TextContainer>
        Loading...
        {' '}
        <CssSpinner color={surface} size={30} thickness={4} padding={4} />
      </TextContainer>
    );
  }

  if (successfullyIntegratedNotion === 'resolved') {
    return (
      <TextContainer>
        Notion Integrated Successfully! 🎉.
      </TextContainer>
    );
  }

  return (
    <>
      <TextContainer>
        Something went wrong
      </TextContainer>
      <ButtonContainer>
        <ButtonSmall text="Retry" onClick={() => { window.location.replace(notionAuthUrl); }} />
      </ButtonContainer>
    </>
  );
}
