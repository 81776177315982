import React from 'react';
import {
  APPS_VIEW, CALENDAR_SHARE_VIEW, EMAIL_SHARE_VIEW, NotesSwitchData, NOTION_SHARE_VIEW,
  PlatformData, PlatformsMultiInputsData, ShareModalMode,
  SHARE_VIEW, SLACK_SHARE_VIEW, TRELLO_SHARE_VIEW,
} from './types';
import { defaultTrelloData } from '../../../../../utils/trello/trelloUtils';
import {
  AuthState,
  DatabasePublicUserV2,
  IntegrationData, SDateT, TaskItem,
} from '../../../../../shared/types/types';
import { isEmptyHTML } from '../../../../../utils/strings';
import { SEND_HEADLESS_NOTES_TEMPLATE_ID } from '../../../../../utils/constants';
import { DateToTemplateDateFormat, getUTCString } from '../../../../../utils/dateUtils/date';
import { ShareNotesDataType } from '../../../../../utils/meetings/ShareNotesUtils';

const emailPlatformData: PlatformData = {
  platform: 'email',
  isActiveIntegration: true,
  isEnabled: true,
  onClick: () => { },
};
const slackPlatformData: PlatformData = {
  platform: 'slack',
  isActiveIntegration: true,
  isEnabled: false,
  onClick: () => { },
};
const notionPlatformData: PlatformData = {
  platform: 'notion',
  isActiveIntegration: true,
  isEnabled: false,
  onClick: () => { },
};
const TrelloPlatformData: PlatformData = {
  platform: 'trello',
  isActiveIntegration: true,
  isEnabled: false,
  onClick: () => { },
};
const AsanaPlatformData: PlatformData = {
  platform: 'asana',
  isActiveIntegration: false,
  isEnabled: false,
  onClick: () => { },
};
const GoogleDocPlatformData: PlatformData = {
  platform: 'googleDocs',
  isActiveIntegration: false,
  isEnabled: false,
  onClick: () => { },
};
const JiraPlatformData: PlatformData = {
  platform: 'jira',
  isActiveIntegration: false,
  isEnabled: false,
  onClick: () => { },
};
const GoogleCalendarPlatformData: PlatformData = {
  platform: 'googleCalendar',
  isActiveIntegration: true,
  isEnabled: true,
  onClick: () => { },
};
export const getPlatformData = (
  integrations: IntegrationData,
  // eslint-disable-next-line no-unused-vars
  setMode: (value: React.SetStateAction<ShareModalMode>) => void,
) => {
  const {
    isSlackEnabled,
    isNotionEnabled,
    isTrelloEnabled,
  } = checkIntegrationEnable(integrations);

  const onEmailClick = () => {
    setMode(EMAIL_SHARE_VIEW);
  };

  const onSlackClick = () => {
    if (isSlackEnabled) {
      setMode(SLACK_SHARE_VIEW);
      return;
    }
    setMode(APPS_VIEW);
  };

  const onNotionClick = () => {
    if (isNotionEnabled) {
      setMode(NOTION_SHARE_VIEW);
      return;
    }
    setMode(APPS_VIEW);
  };

  const onTrelloClick = () => {
    if (isTrelloEnabled) {
      setMode(TRELLO_SHARE_VIEW);
      return;
    }
    setMode(APPS_VIEW);
  };

  const onCalendarExportClick = () => {
    setMode(CALENDAR_SHARE_VIEW);
  };

  const platformData: PlatformData[] = [
    {
      ...emailPlatformData,
      onClick: onEmailClick,
    },
    {
      ...slackPlatformData,
      isEnabled: isSlackEnabled,
      onClick: onSlackClick,
    },
    {
      ...notionPlatformData,
      isEnabled: isNotionEnabled,
      onClick: onNotionClick,
    },
    {
      ...TrelloPlatformData,
      isEnabled: isTrelloEnabled,
      onClick: onTrelloClick,
    },
    {
      ...GoogleCalendarPlatformData,
      onClick: onCalendarExportClick,
    },
    {
      ...AsanaPlatformData,
    },
    {
      ...GoogleDocPlatformData,
    },
    {
      ...JiraPlatformData,
    },
  ];
  return platformData;
};

export const getModalTitle = (mode: ShareModalMode) => {
  switch (mode) {
    case SHARE_VIEW:
    case NOTION_SHARE_VIEW:
    case EMAIL_SHARE_VIEW:
    case SLACK_SHARE_VIEW:
      return 'Share your meeting notes & tasks';
    case CALENDAR_SHARE_VIEW:
      return 'Add notes to your calendar event';
    case APPS_VIEW:
      return 'Apps';
    default:
      return '';
  }
};

export const defaultNotesSwitchData: NotesSwitchData = {
  isSharedNotesChecked: false,
  isSharedNotesDisabled: false,
  isPersonalNotesChecked: false,
  isPersonalNotesDisabled: false,
  isTasksChecked: false,
  isTasksDisabled: false,
};

export const getNotesSwitchData = (
  version: number,
  sharedNotes: string,
  myNotes: string,
  taskItems: TaskItem[],
) => {
  const isSharedNotesDisabled = isEmptyHTML(sharedNotes);
  const isPersonalNotesDisabled = isEmptyHTML(myNotes);

  const isTasksDisabled = taskItems.length === 0;

  const newNotesSwitchData: NotesSwitchData = {
    isSharedNotesDisabled,
    isPersonalNotesDisabled,
    isTasksDisabled,
    isSharedNotesChecked: false,
    isPersonalNotesChecked: false,
    isTasksChecked: false,
  };
  return newNotesSwitchData;
};

const checkIntegrationEnable = (integrations: IntegrationData) => {
  const slackUserAccessToken = integrations?.slack[0]?.userAccessToken ?? '';
  const trelloUserData = integrations?.trello ?? defaultTrelloData;
  const notionUserData = integrations?.notion ?? [];
  const isSlackEnabled = slackUserAccessToken.length > 10;
  const isNotionEnabled = notionUserData.length > 0;
  const { isTrelloEnabled } = trelloUserData.settings;
  return { isSlackEnabled, isNotionEnabled, isTrelloEnabled };
};

export const generateShareNotesData = (
  authData: AuthState,
  notesAndTasksHtml: string,
  title: string,
  start: SDateT,
  notesSwitchData: NotesSwitchData,
  emailHeadHtml: string,
  emailHeadTemplate: string,
  sharedNotesHtml: string,
  myNotes: string,
  taskItems: TaskItem[],
  emails: DatabasePublicUserV2[] = [],
) => {
  const shareNotesData: ShareNotesDataType = {
    authState: authData,
    sendHeadlessNotesTemplateId: SEND_HEADLESS_NOTES_TEMPLATE_ID,
    emails,
    html: notesAndTasksHtml,
    title,
    startTime: getUTCString(start.date),
    startDate: DateToTemplateDateFormat(start.date),
    sharedNotesChecked: notesSwitchData.isSharedNotesChecked,
    personalNotesChecked: notesSwitchData.isPersonalNotesChecked,
    taskChecked: notesSwitchData.isTasksChecked,
    emailHeadHtml,
    emailHeadTemplate,
    sharedNotesHtml,
    personalNotesHtml: myNotes,
    taskItems,
  };
  return shareNotesData;
};

export const isSendButtonEnabled = (
  mode: ShareModalMode,
  platformsMultiInputsData: PlatformsMultiInputsData,
  notesSwitchData: NotesSwitchData,
) => {
  const {
    isSharedNotesChecked, isPersonalNotesChecked, isTasksChecked,
  } = notesSwitchData;
  const { setSlackChannelsToReceive, emailRecipients, taskToSend } = platformsMultiInputsData;
  const atLeastOneNoteOrTaskEnabled = isSharedNotesChecked
    || isPersonalNotesChecked || isTasksChecked;

  switch (mode) {
    case SLACK_SHARE_VIEW:
      return setSlackChannelsToReceive.length > 0 && atLeastOneNoteOrTaskEnabled;
    case EMAIL_SHARE_VIEW:
      return emailRecipients.length > 0 && atLeastOneNoteOrTaskEnabled;
    case TRELLO_SHARE_VIEW:
      return taskToSend.length > 0;
    case NOTION_SHARE_VIEW:
    case CALENDAR_SHARE_VIEW:
      return atLeastOneNoteOrTaskEnabled;
    default:
      return false;
  }
};

export const mapModeToPlatformName = (mode: ShareModalMode) => {
  switch (mode) {
    case SLACK_SHARE_VIEW:
      return 'Slack';
    case EMAIL_SHARE_VIEW:
      return 'emails';
    case TRELLO_SHARE_VIEW:
      return 'Trello';
    case NOTION_SHARE_VIEW:
      return 'Notion';
    case CALENDAR_SHARE_VIEW:
      return 'Google Calendar';
    default:
      return false;
  }
};

export const shareNotesToGoogleCalendarEventPrefix = "<p>These notes are brought to you by Shepherd. <b>Try Shepherd today for <a href='https://chrome.google.com/webstore/detail/shepherd/mfdklpflbmbhldnjohkpdfaclaklphak' title='Free'>free</a>.</b></p><p>---</p>";
