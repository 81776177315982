import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import { AuthContext } from '../../../../App';
import { dbGetPreviousMeetingsByRecurringEventId } from '../../../../database/Meetings/firebaseMeetingAPI';
import SquareButtonMolecule from '../../../../pages/all-pages/bottom-bar/SquareButtonMolecule';
import { logEventButtonClickPrevMeeting } from '../../../../utils/analytics/eventLogger';
import sendPreviousMeetingWindowMessage from '../../../../utils/previousMeetingInjection';
import PreviousMeetingIcon from '../../../icons/PreviousMeetingIcon';
import { MeetingData } from '../../../types/types';
import updateMeetings from '../utils';

interface Props {
  meetingData: MeetingData,
  setIsRecurringMeeting: Dispatch<SetStateAction<boolean>>
}

const PreviousMeetingButtonMol = ({ meetingData, setIsRecurringMeeting }: Props) => {
  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);
  const [isPreviousMeetingExists, setIsPreviousMeetingExists] = useState<boolean>(false);
  const [previousMeetingId, setPreviousMeetingId] = useState<string>('');

  useEffect(() => {
    const { recurringEventId, eventId } = meetingData.googleData.ids;
    const currentStartTime = meetingData.date.start.timestamp;
    if (recurringEventId === '' || currentStartTime === 0) return;
    handleGetPrevMeetings(recurringEventId, currentStartTime, eventId);
  }, [meetingData.meetingId]);

  const handleGetPrevMeetings = async (
    recurringEventId: string, currentStartTime: number, eventId: string,
  ) => {
    await updateMeetings(authState.userId, authState.email, eventId);
    dbGetPreviousMeetingsByRecurringEventId(recurringEventId, currentStartTime, authState.email)
      .then((previousMeetings: MeetingData[]) => {
        if (previousMeetings.length === 0) return;

        setIsPreviousMeetingExists(true);
        setIsRecurringMeeting(true);

        setPreviousMeetingId(previousMeetings[0].meetingId);
      });
  };

  const handleClick = () => {
    logEventButtonClickPrevMeeting(authState.userId, trackEvent);
    if (isPreviousMeetingExists) {
      sendPreviousMeetingWindowMessage({
        meetingId: meetingData.meetingId,
        previousMeetingId,
      });
    }
  };

  if (isPreviousMeetingExists) {
    return (
      <SquareButtonMolecule
        onClick={handleClick}
        icon={<PreviousMeetingIcon />}
        tooltipText="View previous meeting in recurring series"
      />
    );
  }

  return null;
};

export default PreviousMeetingButtonMol;
