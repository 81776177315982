import React from 'react';
import styled from 'styled-components';
import { darkBlue1 } from '../../../../../shared/colours';
import ButtonSmall from '../../../../../shared/components/button-small';
import Modal from '../../../../../shared/components/Modal';
import { header600, uiTextS } from '../../../../../shared/typography';
import LockImg from '../../../../../shared/img/LockImg.png';
import { NotesSwitchData } from '../utils/types';

const Container = styled.div`
 padding: 0px 24px 0px 24px ;
`;

const UnlockImg = styled.img`
  margin-top: 26px;
`;

const MainText = styled.p`
  ${header600}
  text-align: center;
  margin:17px 0px 6px 0px;
`;

const SubText = styled.p`
  ${uiTextS}
  text-align: center;
  margin-top: 0px;
`;

const ModalLineLocal = styled.div`
  background: ${darkBlue1};
  height: 2px;
  width: 100%;
  margin-top: 24px;
`;

const ButtonRow = styled.div`
  display: flex;
  padding: 10px 24px;

  & > button {
    margin: 0;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`;

const ImageContainer = styled.div`
 display: flex;
 justify-content: center;
`;

interface Props {
  isOpen: boolean,
  handleCloseClick: () => void,
  notesSwitchData: NotesSwitchData,
  setNotesSwitchData: React.Dispatch<React.SetStateAction<NotesSwitchData>>
}

export default function MyNotesSharePromptModal(
  {
    isOpen, handleCloseClick, notesSwitchData, setNotesSwitchData,
  }: Props,
) {
  const handleBack = () => {
    const notesData: NotesSwitchData = {
      ...notesSwitchData,
      isPersonalNotesChecked: false,
    };
    setNotesSwitchData(notesData);
    handleCloseClick();
  };

  return (
    <Modal isOpen={isOpen} setModalClosed={handleCloseClick} isHeadless>
      <Container>
        <ImageContainer>
          <UnlockImg src={LockImg} alt="" />
        </ImageContainer>
        <MainText>You’re about to share My notes</MainText>
        <SubText>
          Are you sure you want to share these?
        </SubText>
      </Container>
      <ModalLineLocal />
      <ButtonRow>
        <ButtonSmall onClick={() => handleBack()} text="Back" isOutline customWidth="50%" />
        <ButtonSmall onClick={() => handleCloseClick()} text="Yes" customWidth="50%" />
      </ButtonRow>
    </Modal>
  );
}
