import styled, { css } from 'styled-components';
import { layoutWhite } from '../../../../shared/colours';
import { SIDEBAR_WIDTH } from '../../../../utils/globalStyle';
import {
  fadeIn, fadeInLeft, fadeInRight, fadeInRightTask, fadeInLeftTask,
} from './animations';

export const WelcomeContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  padding: 0px 48px;

  background-color: ${layoutWhite};
  position: absolute;
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 125px - 60px);
`;

export const ImagesContainer = styled.div`
  max-height: 500px;
`;

interface ImageContainerProps {
  left: string,
  top: string,
  animation?: string,
  delay?: string
  order: number
  task?: boolean
}

export const ImageContainer = styled.div<ImageContainerProps>`
  position: absolute;
  left: ${({ left }) => left};
  top: ${({ top }) => top};

  z-index: ${({ order }) => order};
  opacity: 0;

  ${({ animation, delay, left }) => {
    if (animation === 'fadeInRight') return css`animation: ${fadeInRight} 1s ease-out forwards ${delay && delay};`;
    if (animation === 'fadeIn') return css`animation: ${fadeIn} 1s ease-in-out forwards ${delay && delay};`;
    if (animation === 'fadeInLeft') return css`animation: ${fadeInLeft(left)} 1s ease-out forwards ${delay && delay};`;
    if (animation === 'fadeInRightTask') return css`animation: ${fadeInRightTask} 1s ease-out forwards ${delay && delay};`;
    if (animation === 'fadeInLeftTask') return css`animation: ${fadeInLeftTask} 1s ease-out forwards ${delay && delay};`;
    return '';
  }};

  ${({ order }) => (order === 2 && css`& > svg { border-radius: 12px; overflow: hidden;}`)};
  ${({ order }) => (order === 2 && css`& > svg { box-shadow: -16px 24px 40px -8px #1B21243D; }`)};
  ${({ task }) => (task && css`& > svg { box-shadow: 0px 4px 6px -2px #1B21240F; box-shadow: 0px 8px 16px 0px #1B212429;}`)};

  @media (max-height: 1090px) {
    transform: scale(0.90);
    transform-origin: top left;
    ${({ task }) => (task && css`transform-origin: top center; `)};
    ${({ top, task }) => {
    if (!task) return null;

    if (top === '275px') return css`top: 245px;`;
    if (top === '325px') return css`top: 290px;`;
    if (top === '375px') return css`top: 335px;`;
    if (top === '425px') return css`top: 380px;`;
    if (top === '475px') return css`top: 425px;`;

    return null;
  }};

    ${({ animation, delay }) => (animation === 'fadeInLeft' && css`animation: ${fadeInLeft('165px')} 1s ease-out forwards ${delay && delay};`)};
  }

  /* TODO: Make 980px a constant */
  @media (max-height: 1040px) {
    transform: scale(0.80);
    transform-origin: top left;
    ${({ task }) => (task && css`transform-origin: top center;`)};

    ${({ top, task }) => {
    if (!task) return null;

    if (top === '275px') return css`top: 225px;`;
    if (top === '325px') return css`top: 265px;`;
    if (top === '375px') return css`top: 305px;`;
    if (top === '425px') return css`top: 345px;`;
    if (top === '475px') return css`top: 385px;`;

    return null;
  }};

    ${({ animation, delay }) => (animation === 'fadeInLeft' && css`animation: ${fadeInLeft('210px')} 1s ease-out forwards ${delay && delay};`)};
  }

  @media (max-height: 980px) {
    transform: scale(0.70);
    transform-origin: top left;
    ${({ task }) => (task && css`transform-origin: top center;`)};

    ${({ top }) => {
    // icon for calendar and meet screen
    if (top === '280px') return css`top: 240px;`;

    // tasks in tasks screen
    if (top === '275px') return css`top: 200px;`;
    if (top === '325px') return css`top: 235px;`;
    if (top === '375px') return css`top: 270px;`;
    if (top === '425px') return css`top: 305px;`;
    if (top === '475px') return css`top: 340px;`;

    return null;
  }};

    ${({ animation, delay }) => (animation === 'fadeInLeft' && css`animation: ${fadeInLeft('180px')} 1s ease-out forwards ${delay && delay};`)};
  }

  @media (max-height: 860px) {
    transform: scale(0.60);
    transform-origin: top left;
    ${({ task }) => (task && css`transform-origin: top center;`)};

    ${({ top }) => {
    // icon for calendar and meet screen
    if (top === '280px') return css`top: 240px;`;

    // tasks in tasks screen
    if (top === '275px') return css`top: 180px;`;
    if (top === '325px') return css`top: 210px;`;
    if (top === '375px') return css`top: 240px;`;
    if (top === '425px') return css`top: 270px;`;
    if (top === '475px') return css`top: 300px;`;

    return null;
  }};

    ${({ animation, delay }) => (animation === 'fadeInLeft' && css`animation: ${fadeInLeft('128px')} 1s ease-out forwards ${delay && delay};`)};
  }

  @media (max-height: 775px) {
    transform: scale(0.50);
    transform-origin: top left;
    ${({ task }) => (task && css`transform-origin: top center;`)};

    ${({ top }) => {
    // icon for calendar and meet screen
    if (top === '280px') return css`top: 210px;`;
    if (top === '160px') return css`top: 140px;`;

    return null;
  }};

    ${({ animation, delay }) => (animation === 'fadeInLeft' && css`animation: ${fadeInLeft('116px')} 1s ease-out forwards ${delay && delay};`)};
  }

  @media (max-height: 700px) {
    transform: scale(0.40);
    transform-origin: top left;
    ${({ task }) => (task && css`transform-origin: top center;`)};

    ${({ top }) => {
    // icon for calendar and meet screen
    if (top === '280px') return css`top: 210px;`;
    if (top === '160px') return css`top: 140px;`;

    return null;
  }};

    ${({ animation, delay }) => (animation === 'fadeInLeft' && css`animation: ${fadeInLeft('116px')} 1s ease-out forwards ${delay && delay};`)};
  }
  @media (max-height: 650px) {
    transform: scale(0.30);
    transform-origin: top left;
    ${({ task }) => (task && css`transform-origin: top center;`)};

    ${({ top }) => {
    // icon for calendar and meet screen
    if (top === '280px') return css`top: 210px;`;
    if (top === '160px') return css`top: 140px;`;

    return null;
  }};

    ${({ animation, delay }) => (animation === 'fadeInLeft' && css`animation: ${fadeInLeft('116px')} 1s ease-out forwards ${delay && delay};`)};
  }
  @media (max-height: 630px) {
    display: none;
  }
`;

export const TasksImageContainer = styled.div`
  position: absolute;
  left: calc(${SIDEBAR_WIDTH} / 2);
  top: 60px;

  transform: translateX(-50%);
  z-index: 1;

  & > svg {
    box-shadow: -16px 24px 40px -8px #1B21243D;
    border-radius: 12px; 
    overflow: hidden;
  }

  @media (max-height: 1050px) {
    transform: scale(0.95) translateX(-50%);
    transform-origin: top left;  
  }

  @media (max-height: 980px) {
    transform: scale(0.8) translateX(-50%);
    transform-origin: top left; 
  }

  @media (max-height: 915px) {
    transform: scale(0.7) translateX(-50%);
    transform-origin: top left; 
  }

  @media (max-height: 860px) {
    transform: scale(0.6) translateX(-50%);
    transform-origin: top left; 
  }

  @media (max-height: 775px) {
    transform: scale(0.55) translateX(-50%);
    transform-origin: top left; 
  }
  @media (max-height: 670px) {
    transform: scale(0.45) translateX(-50%) translateY(13%);
    transform-origin: top left; 
  }
  @media (max-height: 630px) {
    display: none;
  }
`;
