import React from 'react';
import {
  AuthState, MeetingData, SDateT, DatabaseTemplateData, TemplateData, TemplateVersion,
} from '../../../types/types';
import { dateISOObject } from '../../../../utils/dateUtils/date';
import { dbCreateUserTemplate, dbUpdateUserTemplate } from '../../../../database/firebaseTemplatesAPI';

export const mapDatabaseTemplateDataToTemplateData = (
  templateId: string,
  databaseTemplateData: any,
): TemplateData => {
  const template = {
    templateId,
    version: databaseTemplateData.version ?? 1,
    data: {
      title: databaseTemplateData.data?.title ?? '',
      description: databaseTemplateData.data?.description ?? '',
    },
    template: databaseTemplateData.template ?? '',
    date: {
      created: {
        date: databaseTemplateData.date?.created?.date ?? '',
        timestamp: databaseTemplateData.date?.created?.timestamp ?? 0,
      },
      updated: {
        date: databaseTemplateData.date?.updated?.date ?? '',
        timestamp: databaseTemplateData.date?.updated?.timestamp ?? 0,
      },
    },
    meeting: {
      meetingId: databaseTemplateData.meeting?.meetingId ?? '',
      startDate: {
        date: databaseTemplateData.meeting?.startDate?.date ?? '',
        timestamp: databaseTemplateData.meeting?.startDate?.timestamp ?? 0,
      },
      title: databaseTemplateData.meeting?.title ?? '',
    },
    creator: {
      userId: databaseTemplateData.creator?.userId ?? '',
      name: databaseTemplateData.creator?.name ?? '',
      email: databaseTemplateData.creator?.email ?? '',
    },
    usage: {
      numberOfTimesUsed: databaseTemplateData.usage?.numberOfTimesUsed ?? 0,
    },
    share: {
      status: databaseTemplateData.share?.status ?? 'private',
    },
    ...databaseTemplateData,
  };

  return template;
};

export const prepareDatabaseTemplateData = (
  title: string,
  template: string,
  userData: AuthState,
  meetingData: MeetingData,
  version?: TemplateVersion,
): DatabaseTemplateData => {
  const currentDate: SDateT = dateISOObject();
  const defaultDescription = '';
  const initialUsage = 0;
  const initialShareStatus = 'private';
  const name = `${userData.firstName} ${userData.lastName}`;

  const newTemplate: DatabaseTemplateData = {
    version: version ?? 1,
    data: {
      title,
      description: defaultDescription,
    },
    template,
    date: {
      created: currentDate,
      updated: currentDate,
    },
    meeting: {
      meetingId: meetingData.meetingId,
      startDate: meetingData.date.start,
      title: meetingData.data.title,
    },
    creator: {
      userId: userData.userId,
      name,
      email: userData.email,
    },
    usage: {
      numberOfTimesUsed: initialUsage,
    },
    share: {
      status: initialShareStatus,
    },
  };

  return newTemplate;
};

export const editTemplate = (userId: string, templateId: string,
  newTemplateData: DatabaseTemplateData,
  handleReset: () => void,
  setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  dbUpdateUserTemplate(userId, templateId, newTemplateData, handleReset, setIsSubmitLoading);
};

export const createTemplate = (userId: string,
  newTemplateData: DatabaseTemplateData,
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>,
  setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  dbCreateUserTemplate(userId, newTemplateData, setIsSaved, setIsSubmitLoading);
};
