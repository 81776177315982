import firebase from 'firebase';
import { random } from 'lodash';
import moment from 'moment';
import IntercomCF from '../../external/IntercomCF/IntercomCF';
import StripeAPI from '../../external/Stripe/StripeAPI';
import { StripeConstants } from '../../external/Stripe/StripeConstants';
import { BillingStartTrialOrigin, BillingMeetingUsed } from '../../external/Stripe/StripeTypes';
import SDate from '../../shared/classes/SDate';
import {
  IntercomTrackEvent,
  MeetingData, ResolvedState, User,
} from '../../shared/types/types';
import Mixpanel from '../../utils/analytics/Mixpanel';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { USER_PATH } from '../FirebaseConstants';
import UserAPI from './UserAPI';
import UserAPICore from './UserAPICore';

/**
 * Responsible for updating the user billing data in Firestore
 *
 * Not responsible for interacting with the Stripe API
 */
class UserBillingAPI extends UserAPICore {
  static createStripeCustomerIdIfNotCreated = async (user: User): Promise<ResolvedState> => {
    if (user.userId.length === 0) return REJECTED;
    if (user.billing.stripeCustomerId.length > 0) return RESOLVED;

    const result = await UserAPI.updateStripeCustomerIdToSetTransactiontCore(user);
    if (result.isRejected) return REJECTED;
    StripeAPI.createStriperCustomer(user);

    return RESOLVED;
  }

  static setStatusToNotInitialized = async (user: User): Promise<ResolvedState> => {
    const updateObject = {
      [USER_PATH.billing.status]: 'not_initialized',
      [USER_PATH.billing.activePlans]: [],
      [USER_PATH.billing.trialStart.timestamp]: 0,
      [USER_PATH.billing.trialStart.date]: '',
      [USER_PATH.billing.trialEnd.timestamp]: 0,
      [USER_PATH.billing.trialEnd.date]: '',
      [USER_PATH.billing.meetingsUsed]: [],
      [USER_PATH.billing.subscription_PATH]: {},
    };
    IntercomCF.updateSubscriptionData(user.userId, 'not_initialized');

    const result = await UserAPI.updateUser(user.userId, updateObject);
    return result;
  };

  static startTrial = async (
    user: User,
    /** Where the trial was started from */
    origin: BillingStartTrialOrigin,
    intercomTrackEvent?: IntercomTrackEvent,
  ): Promise<ResolvedState> => {
    const updateObject = {
      [USER_PATH.billing.status]: 'trialing',
      [USER_PATH.billing.meetingsUsed]: [],
      [USER_PATH.billing.trialStartPath]: new SDate(new Date()).value,
      [USER_PATH.billing.trialEndPath]: new SDate(moment().add(StripeConstants.TRIAL_LENGTH, 'days').toISOString()).value,
      [USER_PATH.billing.subscription_PATH]: {},
    };

    const result = await UserAPI.updateUser(user.userId, updateObject);
    Mixpanel.Billing.logStartTrial(user, origin, intercomTrackEvent);
    return result;
  };

  static endTrial = async (user: User): Promise<ResolvedState> => {
    const updateObject = {
      [USER_PATH.billing.status]: 'trialing',
      [USER_PATH.billing.trialEndPath]: new SDate(moment().subtract(1, 'days').toISOString()).value,
      [USER_PATH.billing.subscription_PATH]: {},
    };

    return UserAPI.updateUser(user.userId, updateObject);
  };

  static decrementTrialDays = async (user: User): Promise<ResolvedState> => {
    const updateObject = {
      [USER_PATH.billing.trialEndPath]: new SDate(moment(user.billing.trialEnd.date).subtract(1, 'days').toISOString()).value,
    };

    return UserAPI.updateUser(user.userId, updateObject);
  };

  static incrementTrialDays = async (user: User): Promise<ResolvedState> => {
    const updateObject = {
      [USER_PATH.billing.trialEndPath]: new SDate(moment(user.billing.trialEnd.date).add(1, 'days').toISOString()).value,
    };

    return UserAPI.updateUser(user.userId, updateObject);
  };

  static setUserToPremium = async (user: User): Promise<ResolvedState> => {
    const updateObject = {
      [USER_PATH.billing.status]: 'premium',
      [USER_PATH.billing.meetingsUsed]: [],
      [USER_PATH.billing.subscription.status]: 'active',
    };

    return UserAPI.updateUser(user.userId, updateObject);
  };

  static setUserToFreePlan = async (user: User): Promise<ResolvedState> => {
    const updateObject = {
      [USER_PATH.billing.status]: 'free',
      [USER_PATH.billing.subscription_PATH]: {},
    };

    return UserAPI.updateUser(user.userId, updateObject);
  };

  static addDummyMeetingUsed = async (user: User): Promise<ResolvedState> => {
    const randomNumber = random(10000000, 999999999).toString();
    const newMeeting: BillingMeetingUsed = {
      meetingId: randomNumber,
      title: `Dummy Meeting ${randomNumber}`,
      start: new SDate(new Date()).value.date,
      usedDate: new SDate(new Date()).value.date,
    };

    const updateObject = {
      [USER_PATH.billing.meetingsUsed]: firebase.firestore.FieldValue.arrayUnion(newMeeting),
    };

    return UserAPI.updateUser(user.userId, updateObject);
  };

  // Not in use?
  /**
   * If the user is on the free plan, it will add the meeting to the meetings used array
   * if it is not already in the array. Also, if the user has used more
   * than 10 meetings, it will open the modal to upgrade to premium.
   */
  static addMeetingUsedAndMaybeOpenModal = async (
    // eslint-disable-next-line no-unused-vars
    user: User, meetingData: MeetingData, openModal: (open: boolean) => void,
  ) => {
    if (meetingData.resolvedState !== 'resolved') return false;
    if (meetingData.meetingId.length === 0) return false;
    if (user.resolvedState !== 'resolved') return false;
    if (!user.billing.isOnFreePlan()) return false;
    if (user.billing.isMeetingInMeetingsUsed(meetingData.meetingId)) return false;
    if (user.billing.hasUsed10OrMoreMeetingsLast30Days()) {
      openModal(true);
      return true;
    }
    return UserAPI.Billing.addNewMeetingUsed(user, meetingData);
  };

  static openPayWallIfUserIsOnFreePlanAndExceededMeetings = async (
    // eslint-disable-next-line no-unused-vars
    user: User, meetingData: MeetingData, openModal: (open: boolean) => void,
  ) => {
    if (meetingData.resolvedState !== 'resolved') return false;
    if (meetingData.meetingId.length === 0) return false;
    if (user.resolvedState !== 'resolved') return false;
    if (!user.billing.isOnFreePlan()) return false;
    if (user.billing.isMeetingInMeetingsUsed(meetingData.meetingId)) return false;
    if (user.billing.hasUsed10OrMoreMeetingsLast30Days()) {
      openModal(true);
      return true;
    }
    return false;
  };

  static addNewMeetingUsed = async (
    user: User, meetingData: MeetingData,
  ) => {
    const newMeeting: BillingMeetingUsed = {
      meetingId: meetingData.meetingId,
      title: meetingData.data.title,
      start: meetingData.date.start.date,
      usedDate: new SDate(new Date()).value.date,
    };

    return UserAPICore.addMeetingUsedCore(user.userId, newMeeting);
  };

  static subtractMeetingUsed = async (user: User): Promise<ResolvedState> => {
    if (user.billing.meetingsUsed.length === 0) return RESOLVED;

    const updateObject = {
      [USER_PATH.billing.meetingsUsed]:
        firebase.firestore.FieldValue.arrayRemove(user.billing.meetingsUsed[0]),
    };

    return UserAPI.updateUser(user.userId, updateObject);
  };
}

export default UserBillingAPI;
