import date from 'date-and-time';
import { captureMessage } from '@sentry/react';
import {
  getEndOfMonth, getEndOfWeek, getStartOfMonth, getStartOfToday, getStartOfWeek,
} from '../dateUtils/date';
import {
  gapiCoreGetMeeting, gapiCoreGetMeetingsInPeriod,
  gapiCoreUpdateMeetingDescription, gapiInsertMeetingCore, getRecurringEventData,
} from './GoogleCalendarCore';
import { GapiMeetingData, MeetingData } from '../../shared/types/types';
import { rejectedGapiMeetingData } from '../../database/utils/gapiMeetingDataUtils';
import { startTrackingEvent, stopTrackingEvent } from '../analytics/eventLogger';
import {
  // eslint-disable-next-line max-len
  appendTextToDescriptionString, gapiInsertFirstMeetingSummary, gapiFormatTimeForFirstMeeting, gapiFormatTimeForInstantMeeting, GapiUtils,
} from './GoogleCalendarUtils';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import GAPI from '../../external/GoogleAPI/GAPI';

/**
 * @param daysFromToday - The number of days from today that we want to get the meetings for.
 * Can be negative.
 * @param additionalDays - The number of days from daysFromToday that we want to get.
 * @returns Meetings in time period
 *
 * @example (0,1) => meetings for today
 * @example (-2,2) => meetings for the last two days
 */
export const getMeetingsInPeriod = async (
  daysFromToday: number,
  additionalDays: number,
): Promise<GapiMeetingData[]> => {
  const today = getStartOfToday();
  const startDate = date.addDays(today, daysFromToday);
  const endDate = date.addDays(startDate, additionalDays);
  const result = await gapiCoreGetMeetingsInPeriod(
    startDate.toISOString(),
    endDate.toISOString(),
  );
  return result.filter(GapiUtils.filterMustHaveTitle);
};

export const getTodaysMeetings = async (): Promise<GapiMeetingData[]> => getMeetingsInPeriod(0, 1);

export const getThisWeeksUpcomingMeetings = async () => {
  const startOfWeek = getStartOfWeek();
  const endOfWeek = getEndOfWeek();
  console.log(`StartOfweek: ${startOfWeek}`); // By default a week is sunday - saturday week
  console.log(`End of week: ${endOfWeek}`);
  const result = await gapiCoreGetMeetingsInPeriod(startOfWeek, endOfWeek);
  console.log(result);
  return result;
};

export const getMontlyMeetings = async () => {
  const startOfMonth = getStartOfMonth();
  const endOfMonth = getEndOfMonth();
  console.log(`Start of month ${startOfMonth}`);
  console.log(`End of month ${endOfMonth}`);
  const result = await gapiCoreGetMeetingsInPeriod(startOfMonth, endOfMonth);
  console.log(result);
  return result;
};

/**
 * Gets a Gapi Meeting
 *
 * Often we can't know for sure which calendarId the meeting is in.
 * 1. We first try provided `calendarId`
 * 2. Then by using `userEmail` as calendarId
 * 3. Then by using all the calendars that the user has access to that, and trying those
 * calendars the user has access to that are also in attendees list first
 */
export const gapiGetMeeting = async (
  eventId: string,
  calendarId: string,
  userEmail: string,
  meetingData?: MeetingData,
): Promise<GapiMeetingData> => {
  if (!validateEventIdAndCalendarId(eventId, calendarId)) {
    makeErrorMessage(eventId, calendarId);
    return rejectedGapiMeetingData;
  }

  startTrackingEvent('meetingDataLoad');
  const meeting1 = await gapiCoreGetMeeting(eventId, calendarId, 1, false);
  if (meeting1.resolvedState === 'resolved') {
    stopTrackingEvent('meetingDataLoad');
    return meeting1;
  }

  const meeting2 = await gapiCoreGetMeeting(eventId, userEmail, 2, false);
  if (meeting2.resolvedState === 'resolved') {
    stopTrackingEvent('meetingDataLoad');
    return meeting2;
  }

  let calendarsToTryFirst: string[] = [];
  if (meetingData?.attendees.resolvedState === 'resolved') {
    calendarsToTryFirst = meetingData.attendees.attendees.map((attendee) => attendee.data.email);
  }

  const meeting3 = await gapiGetMeetingByAllMyCalendars(
    eventId, [calendarId, userEmail], calendarsToTryFirst,
  );
  if (meeting3.resolvedState !== 'resolved') {
    console.error('Could not find gapiMeeting in gapiGetMeeting', {
      eventId, calendarId, userEmail, meetingData,
    });
    captureMessage('Could not find gapiMeeting in gapiGetMeeting', {
      extra: {
        eventId, calendarId, userEmail, meetingData, functionName: 'gapiGetMeeting',
      },
    });
  }
  stopTrackingEvent('meetingDataLoad');
  return meeting3;
};

/**
 * Will fetch all the users calendars, and check if the user has access to the event
 * by any of those calendars. We first try to fetch the meetings by the calendars that
 * is in the attendees list
 * @param alreadyFetchedCalendars - The calendars that we already have tried to fetch the meeting by
 * @returns if found, the resolved meeting, if not found, then rejected meeting
 */
const gapiGetMeetingByAllMyCalendars = async (
  eventId: string, alreadyAttemptedCalendars: string[], attendeesCalendars: string[],
): Promise<GapiMeetingData> => {
  ConsoleImproved.log('attendeesCalendars', attendeesCalendars);
  const allMyCalendars = await GAPI.getMyCalendarIds();

  const myCalendarsInAttendees = allMyCalendars.filter(
    (myCalendar) => attendeesCalendars.includes(myCalendar),
  );

  ConsoleImproved.log(`My calendars in attendees: ${myCalendarsInAttendees}`);

  // We have the best change to fetch the meeting by the calendars that is mine
  // and also in the attendees list
  const meeting = await gapiGetMeetingByCalendars(eventId, myCalendarsInAttendees, 3);
  if (meeting.resolvedState === 'resolved') return meeting;

  const alreadyAttemptedCalendars2 = [...alreadyAttemptedCalendars, ...myCalendarsInAttendees];

  const filteredCalendars = allMyCalendars.filter(
    (calendar) => !alreadyAttemptedCalendars2.includes(calendar),
  );

  ConsoleImproved.log(`Filtered calendars: ${filteredCalendars}`);

  const meeting2 = await gapiGetMeetingByCalendars(eventId, filteredCalendars,
    3 + myCalendarsInAttendees.length);
  return meeting2;
};

const gapiGetMeetingByCalendars = async (
  eventId: string, calendars: string[], startIndex?: number,
): Promise<GapiMeetingData> => {
  const promises = await calendars.map((
    calendar: string, index: number,
  ) => gapiCoreGetMeeting(eventId, calendar, (startIndex ?? 0) + index, false));
  const meetings = await Promise.all(promises);
  const resolvedMeetings = meetings.filter((meeting) => meeting.resolvedState === 'resolved');
  return resolvedMeetings.length ? resolvedMeetings[0] : rejectedGapiMeetingData;
};

export const appendTextToGoogleEventDescription = async (
  eventId: string,
  calendarId: string,
  currentDescription: string,
  newText: string,
) => {
  if (!validateEventIdAndCalendarId(eventId, calendarId)) {
    makeErrorMessage(eventId, calendarId);
  }

  return gapiCoreUpdateMeetingDescription(
    eventId,
    calendarId,
    appendTextToDescriptionString(currentDescription, newText),
  );
};

/** using google instaces api to get recurring events */
export const gapiGetRecurringEventMeetings = (
  calendarId: string,
  eventId: string,
) => getRecurringEventData(calendarId, eventId)
  .then((result: any) => result).catch((error: any) => error);

export const consoleLogCouldntFindMeeting = (
  eventId: string, calendarId: string, number: number, error: any,
) => {
  console.error(`GAPI - Couldn't find meeting with eventId '${eventId}', and calendarId: '${calendarId}', number: ${number}`, error);
};

const validateEventIdAndCalendarId = (eventId: string, calendarId: string) => {
  if (eventId.length < 10 || calendarId.length < 10) {
    return false;
  }
  return true;
};

const makeErrorMessage = (eventId: string, calendarId: string) => {
  if (eventId.length < 10) {
    return console.log(`EventId is not valid: ${eventId}`);
  }
  if (calendarId.length === 0) {
    return console.log(`CalendarId is not valid: ${calendarId}`);
  }
  return '';
};

export const gapiInsertFirstMeeting = async () => {
  const summary = gapiInsertFirstMeetingSummary();
  const { startTime, endTime, timeZone } = gapiFormatTimeForFirstMeeting();

  const event = {
    summary,
    description: 'Welcome to the Shepherd Family!',
    start: {
      dateTime: startTime,
      timeZone,
    },
    end: {
      dateTime: endTime,
      timeZone,
    },
  };

  return gapiInsertMeetingCore(event);
};

export const gapiInsertInstantMeeting = async () => {
  const { startTime, endTime, timeZone } = gapiFormatTimeForInstantMeeting();

  const event = {
    summary: 'Instant Meeting',
    description: `Instant Meeting! created by Shepherd at ${new Date().toLocaleString()}`,
    start: {
      dateTime: startTime,
      timeZone,
    },
    end: {
      dateTime: endTime,
      timeZone,
    },
  };

  return gapiInsertMeetingCore(event);
};
