/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../colours';
import Header from '../../components/header';
import { DEFAULT_HEADER_MEETING_VIEW } from '../../components/header/utils/constants';
import BREAKPOINTS from '../../CSS/CSS_Constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: ${BREAKPOINTS.md};
  /* background-color: ${COLORS.darkBlue7}; */
  /* border: 1px solid ${COLORS.darkBlue4}; */
  height: 100%;
  margin: 10px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  #title {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

interface JustHeaderContainerProps {
  children?: React.ReactNode,
  /**
   * To show the header or not
   */
  showHeader?: boolean,
}

const JustHeaderContainer = ({ children, showHeader = true }: JustHeaderContainerProps) => {
  return (
    <Container>
      {showHeader && <Header setPage={() => { }} view={DEFAULT_HEADER_MEETING_VIEW} />}
      <MainContent>
        {children}
      </MainContent>
    </Container>
  );
};

export default JustHeaderContainer;
