import { User } from '../../shared/types/types';
import SDate from '../../shared/classes/SDate';
import { StripeConstants } from './StripeConstants';
import { BillingMeetingUsed } from './StripeTypes';

// TODO: Move class methods into BillingUserData and remove this class

class BillingUtils {
  /**
   * If the user is on the free plan,
   * they should only be able to access
   * meetings that are within the past 5 days
   * and the future 5 days
   */
  static blockGoToMeeting = (user: User, startDate: string) => {
    if (user.resolvedState !== 'resolved') return false;
    if (!user.billing.isOnFreePlan()) return false;
    const startDateObject = new SDate(startDate);
    if (startDateObject.isMoreThanXDaysInFuture(
      StripeConstants.FREE_PLAN.MAX_FUTURE_SEARCH_DAYS,
    )) return true;
    if (startDateObject.isMoreThanXDaysInPast(
      StripeConstants.FREE_PLAN.MAX_PAST_SEARCH_DAYS,
    )) return true;
    return false;
  };

  static dummyMeetingUsed = (startTime: string): BillingMeetingUsed => ({
    meetingId: 'dummyMeetingId',
    title: 'dummyTitle',
    start: startTime,
    usedDate: new SDate(new Date()).value.date,
  });
}

export default BillingUtils;
