import {
  Callback, GetPropsCommonOptions, GetRootPropsOptions, GetInputPropsOptions,
} from 'downshift';
import React, { SetStateAction, Dispatch } from 'react';
import styled from 'styled-components';
import { Input } from '.';
import { gray1 } from '../../colours';
import SearchableAssignClearButton from './SearchableAssignClearButton';

const Container = styled.div`
  position: relative;
  /* Need to reset styles for input component */
  input {
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 20px;
  } 
`;

const MiniInput = styled(Input)`
  background-color: ${gray1};
`;

export interface Props {
  inputRef: any,
  // eslint-disable-next-line no-unused-vars
  clearSelection: (cb?: Callback | undefined) => void
  focusInput : () => void
  setOpenOptions: Dispatch<SetStateAction<boolean>>
  getRootProps: (
    // eslint-disable-next-line no-unused-vars
    options?: GetRootPropsOptions | undefined,
    // eslint-disable-next-line no-unused-vars
    otherOptions?: GetPropsCommonOptions | undefined
    ) => void
  // eslint-disable-next-line no-unused-vars
  getInputProps: <T>(options?: T | undefined) => T & GetInputPropsOptions
}

const InputMolecule = ({
  inputRef, clearSelection, focusInput, setOpenOptions, getRootProps, getInputProps,
}:Props) => (
  <Container
    style={{ width: '100%', display: 'flex', alignItems: 'center' }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...(typeof getRootProps({} as any,
      { suppressRefError: true }) === 'object' ? getRootProps({} as any,
        { suppressRefError: true }) : {})}
  >
    <MiniInput
      placeholder="Type email"
      onClick={() => { setOpenOptions(true); }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getInputProps()}
      ref={inputRef}
    />
    <SearchableAssignClearButton
      onClick={() => clearSelection(focusInput)}
    />
  </Container>
);

export default InputMolecule;
