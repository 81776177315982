import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../../shared/colours';

const Line = styled.div`
  /* height: 1px; */
  /* background-color: ${COLORS.darkBlue1}; */
  width: 200px;
  height: 0px;

  border: 2px solid rgba(214, 145, 229, 0.3);
`;

const LearnMoreLineAtom = () => (
  <Line />
);

export default LearnMoreLineAtom;
