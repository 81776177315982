import { Dispatch, SetStateAction } from 'react';
import ConsoleImproved from '../../../shared/classes/ConsoleImproved';
import { IntercomTrackEvent, ResolvedState } from '../../../shared/types/types';
import { REJECTED } from '../../enums';
import { logEvent } from '../eventLogger';
import LoggerCore from './LoggerCore';

class Logger extends LoggerCore {
  static logEvent = async (
    userId: string, eventType: string, eventData: Object,
    intercomTrackEvent?: IntercomTrackEvent,
  ): Promise<ResolvedState> => logEvent(userId, eventType, eventData, intercomTrackEvent)

  /**
   * Only log if more than 30 seconds as passed since last log
   */
  static logSearchedInAllNotes = (searchTerm: string, userId: string,
    lastTimeSearchedMeetings: number,
    setLastTimeSearchedMeetings: Dispatch<SetStateAction<number>>) => {
    const minimumTimeFromLastSearch = 1000 * 30; // 30 seconds
    if (Date.now() - lastTimeSearchedMeetings < minimumTimeFromLastSearch) return REJECTED;
    setLastTimeSearchedMeetings(Date.now());

    const eventType = 'searched_in_all_notes';
    const eventData = { length: searchTerm.length };
    ConsoleImproved.log('Logging searchedInAllNotes');

    return Logger.logEvent(userId, eventType, eventData);
  }
}

export default Logger;
