import React from 'react';
import Font from '../../../../shared/typography/Font';

const OrHeadStraightToPremiumPlan = () => (
  <Font font="uiTextS" color="grayText" id="announcement">
    Or head straight to our
    {' '}
    <b>Premium plan</b>
  </Font>
);

export default OrHeadStraightToPremiumPlan;
